import React from 'react'
import BoatOnComponent from '../../../common/BoatOnComponent'
import BoatOnAppBar from '../../../common/BoatOnAppBar'
import { connect } from 'react-redux'
import dictionary from './Dictionary/LogBookPageDico'

import LogBookForecast from './LogBookForecast'
import LogBookList from './LogBookList'

import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/LogBookPageCss'

class LogBookPage extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {
            idLogbookTab: 0,
        }

        this.changeTab = this.changeTab.bind(this)
    }

    changeTab(idLogbookTab) {
        this.setState({ idLogbookTab })
    }

    render() {
        const { idLogbookTab } = this.state
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <div>
                    <BoatOnAppBar
                        tabIndex={idLogbookTab}
                        displayBodyOut={this.changeTab}
                        labels={[
                            this.displayText('navigationList'),
                            this.displayText('weatherForecast'),
                        ]}
                    />
                </div>

                {idLogbookTab === 0 && <LogBookList />}
                {idLogbookTab === 1 && <LogBookForecast />}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps)(withStyles(styles)(LogBookPage))
