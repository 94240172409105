import { BorderBottom } from '@material-ui/icons'

export default theme => ({
    amountContainer: {
        margin: '5px 0px',
        display: 'flex',
        flexDirection: 'row',
        gap: 15,
    },
    textField: {
        width: '100% ',
    },
    helpIcon: {
        marginTop: `-10px`,
        width: `20px`,
        height: `20px`,
        marginRight: `3px`,
        marginLeft: `3px`,
    },
    taxField: {
        maxWidth: 80,
    },
    stepper: {
        '& .MuiStepIcon-active': {
            color: '#F4BA5C',
        },
        '& .MuiStepLabel-active': {
            color: '#F4BA5C',
        },
        '& .MuiStepIcon-completed': {
            color: '#6BC070',
        },
        '& .MuiStepLabel-completed': {
            color: '#6BC070',
        },
    },
    familyRow: {
        backgroundColor: '#F4F5F7',
        '& .MuiTableCell-body': {
            color: '#848EA4 !important',
            fontWeight: 'bold !important',
        },
        '& .MuiTableCell-root': {
            borderBottom: 'none',
        },
        '& .MuiTableCell-root:first-child': {
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
        },
        '& .MuiTableCell-root:last-child': {
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
        },
    },
    row: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
        },
    },
    header: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
        },
    },
    activityContainer: {
        paddingTop: 10,
    },
})
