export default theme => ({
    header: {
        height: 105,
        display: `flex`,
        alignItems: `center`,
        justifyContent: `space-between`,
        paddingTop: 25,
        paddingLeft: 24,
        paddingBottom: 5,
        paddingRight: 32,
        borderBottom: `1px solid #E9EFF2`,
    },
    disableEntryContainer: {
        marginBottom: 17,
    },
    disableEntry: {
        color: `rgba(0,0,0,0.4)`,
    },
    disableEntry2: {
        fontSize: `17px`,
        marginTop: 3,
    },
    waiting: {
        background: `#E19716`,
    },
    radioButton: {
        display: `flex`,
        flexDirection: `row`,
        '& > label': {
            marginRight: 50,
        },
    },
    openPerms: {
        opacity: 0.65,
        marginTop: 5,
        marginBottom: 30,
        '&:hover': {
            cursor: `pointer`,
        },
    },
    columnsTitles: {
        borderBottom: `1px solid #E9EFF2`,
        fontWeight: `400`,
        background: `#F6F8FA`,
        '& th': {
            fontWeight: `400`,
        },
    },
    titlePerms: {
        width: `100%`,
        background: `#F6F8FA`,
        margin: 0,
        height: 34,
        display: `flex`,
        alignItems: `center`,
        fontWeight: `bold`,
        fontSize: `15px`,
        paddingBottom: 10,
        paddingLeft: 10,
        boxSizing: `border-box`,
    },
    table: {
        width: `100%`,
        borderCollapse: `collapse`,
        fontSize: `14px`,
    },
    row: {
        textAlign: `center`,
        verticalAlign: `middle`,
    },
    fieldPhone: {
        margin: `12px 0`,
        width: `100%`,
        display: `flex`,
        alignItems: `center`,
        '& img': {
            height: 16.625,
        },
        '& .MuiOutlinedInput-root': {
            height: 55,
        },
        '& .MuiSelect-selectMenu': {
            height: 53,
            boxSizing: `border-box`,
        },
        '& .MuiFormControl-marginNormal': {
            marginTop: 0,
            marginBottom: 0,
        },
    },
    profilePic: {
        height: 48,
        width: 48,
        borderRadius: `100%`,
        display: `flex`,
        justifyContent: `center`,
        alignItems: `center`,
        color: `#303F9F`,
        background: `#E5E7F0`,
        marginRight: 10,
        fontWeight: `bold`,
        textAlign: `center`,
    },
    miniDecription: {
        display: `flex`,
        justifyContent: `center`,
        flexDirection: `column`,
    },
    miniDescriptionRole: {
        color: `#858F99`,
    },
    activity: {
        background: `#309F84`,
    },
    inactivity: {
        background: `#CD2318`,
    },
    status: {
        borderRadius: 4,
        padding: `4px 13px`,
        color: `white`,
    },
    headerLeft: {
        display: `flex`,
        alignItems: `center`,
    },
    saveBar: {
        zIndex: `3`,
        position: `fixed`,
        boxSizing: `border-box`,
        width: `min(600px,80vw)`,
        boxShadow: `0px 10px 5px #888, 0px 0px 3px #888`,
        bottom: 0,
        padding: `15px 17px`,
        background: `white`,
        display: `flex`,
        justifyContent: `flex-end`,
        [theme.breakpoints.down('sm')]: {
            boxShadow: `none`,
        },
    },
    coreContainer: {
        position: `relative`,
        paddingTop: 32,
        paddingLeft: 24,
        paddingRight: 17,
        width: `100%`,
        boxSizing: `border-box`,
    },
    companyLine: {
        width: `50%`,
        marginTop: 0,
        [theme.breakpoints.down('sm')]: {
            width: `100%`,
        },
    },
    menuItemRole: {
        display: `flex`,
        alignItems: `center`,
        justifyContent: `space-between`,
    },
    label: {
        fontSize: '14px',
        fontWeight: '500',
        paddingBottom: 8,
    },
    field: {
        margin: `12px 0`,
        width: `100%`,
        color: `black`,
    },
    fieldRole: {
        width: `35%`,
        marginBottom: 0,
        [theme.breakpoints.down('sm')]: {
            width: `100%`,
        },
        '& .MuiSelect-selectMenu': {
            display: `flex`,
            alignItems: `center`,
            justifyContent: `space-between`,
        },
    },
    subtitle: {
        fontWeight: `bold`,
        fontSize: `16px`,
        marginTop: 24,
        marginBottom: 16,
    },
    twoField: {
        width: `100%`,
        display: `flex`,
        '& > div': {
            marginRight: 24,
        },
        '& > div ~ div': {
            marginRight: 0,
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            '& > div': {
                marginRight: 0,
            },
        },
    },
    activeCheck: {
        border: `1px solid #303F9F`,
    },
    notActiveCheck: {
        border: `1px solid #D9E3E8`,
    },
    check: {
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
        width: 19,
        height: 19,
        margin: `auto`,
        borderRadius: 2,
        '&:hover': {
            cursor: `pointer`,
        },
    },
    subTwoField: {
        flexGrow: 1,
    },
    subTwoFieldPhone: {
        width: `50%`,
        [theme.breakpoints.down('sm')]: {
            width: `100%`,
        },
    },
    rows: {
        borderBottom: `1px solid #E9EFF2`,
        height: 47,
    },
})
