import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/ButtonFilterPopOverDateRangeCss'
import BoatOnComponent from '../common/BoatOnComponent'
import dictionary from './Dictionary/ButtonFilterPopOverDico'

class ButtonFilterPopOverDateRange extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
    }

    render() {
        const { body, classes } = this.props
        return <div className={classes.root}>{body}</div>
    }
}

export default withStyles(styles)(ButtonFilterPopOverDateRange)
