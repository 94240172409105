import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { addressActions } from '../../../actions/address.actions'
import styles from './Styles/SearchBarCss'
import CircularProgress from '@material-ui/core/CircularProgress'
import { fixIosHeaderBug } from '../../../utils/usefullFunctions'

class SearchBar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
        }

        this.handleChange = this.handleChange.bind(this)

        this.cache = {}
    }

    autoCompletion(value) {
        const { dispatch, type } = this.props

        if (!value) {
            this.setState({
                loading: false,
            })
        } else {
            switch (type) {
                case 'city':
                    dispatch(addressActions.requestCityData(value))
                    break
                default:
                    dispatch(addressActions.requestAddressData(value))
                    break
            }
        }
    }

    handleChange(value) {
        this.autoCompletion(value)
        this.props.onChange(value)
    }

    renderAutoCompletion() {
        const { classes } = this.props
        const autoCompletion = this.props.autoCompletion
            ? this.props.autoCompletion
            : null

        return this.props.renderAutocomplete(
            autoCompletion?.length > 0 ? autoCompletion : [],
            classes,
        )
    }

    render() {
        const {
            classes,
            notOutlined,
            value,
            labelHide,
            margin,
            InputProps,
            required,
            helperText,
            error,
            multiline,
            disabled,
        } = this.props
        const InputPropsFinal = Object.assign(
            {
                endAdornment: (
                    <React.Fragment>
                        {this.state.loading ? (
                            <CircularProgress color="inherit" size={20} />
                        ) : null}
                    </React.Fragment>
                ),
            },
            InputProps,
        )

        return (
            <div className={classes.root}>
                <div className={classes.search}>
                    <TextField
                        disabled={disabled}
                        multiline={multiline}
                        helperText={helperText}
                        error={error}
                        id="searchBar"
                        margin={margin ? margin : 'dense'}
                        required={required}
                        variant={notOutlined ? 'standard' : 'outlined'}
                        label={labelHide ? null : this.props.label}
                        placeholder={
                            labelHide
                                ? this.props.label
                                : this.props.placeholder
                        }
                        value={
                            value
                                ? value.fullText
                                    ? value.fullText
                                    : value.display_name
                                : undefined
                        }
                        onChange={e => {
                            this.handleChange(e.target.value)
                        }}
                        className={classes.bar}
                        InputProps={InputPropsFinal}
                        onBlur={() => fixIosHeaderBug()}
                    />
                </div>

                {this.renderAutoCompletion()}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        autoCompletion: state.address.autoComplet,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(SearchBar))
