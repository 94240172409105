import { userService } from '../services/user.service'
import { API_URL } from './config.service'

export const requestApi = (
    url,
    type,
    body,
    newTry = false,
    json = true,
    lang = 'fr',
) => {
    const authHeader = userService.getAuthHeader(lang.toLowerCase())
    if (type === 'DELETE') {
        delete authHeader['Content-Type']
    }

    const option = {
        PUT: {
            method: 'PUT',
            headers: authHeader,
            body: JSON.stringify(body),
        },
        GET: {
            method: 'GET',
            headers: authHeader,
        },
        POST: {
            method: 'POST',
            headers: authHeader,
            body: JSON.stringify(body),
        },
        FILES: {
            method: 'POST',
            headers: {
                Authorization: authHeader.Authorization,
            },
            body: body,
        },
        DELETE: {
            method: 'DELETE',
            headers: authHeader,
        },
    }

    if (!type) {
        return Promise.reject('no type set')
    }

    return fetch(API_URL + url, option[type])
        .then(async result => {
            if (json !== false) {
                const json = await result.json()
                if (json.statusCode === 401) {
                    if (!newTry) {
                        return userService.refreshToken().then(() => {
                            return requestApi(url, type, body, true)
                        })
                    }
                    return Promise.reject(json.message)
                }
                if (json.statusCode === 422 || json.statusCode === 409) {
                    return json.statusCode
                }
                if (json.statusCode) {
                    console.error(json.message)
                    return Promise.reject(json.message)
                }
                return json
            } else {
                return result
            }
        })
        .catch(error => {
            return Promise.reject(error)
        })
}
