export default {
    newNavigation: {
        FR: 'Nouvelle navigation',
        EN: 'New navigation',
    },
    boat: {
        FR: 'Mon bateau',
        EN: 'My boat',
    },
    distance: {
        FR: 'Distance',
        EN: 'Distance',
    },
    duration: {
        FR: 'Durée navigation',
        EN: 'Navigation time',
    },
    passengers: {
        FR: 'Passagers',
        EN: 'Passengers',
    },
    AISUsed: {
        FR: 'Utilisation AIS',
        EN: 'AIS used',
    },
    manualSetup: {
        FR: 'Navigation manuelle sans AIS',
        EN: 'Manual navigation without AIS',
    },
    loading: {
        FR: 'Chargement',
        EN: 'Loading',
    },
    NoNavigationWithFilters: {
        FR: `Oups, il n'y a rien ici 🧐. Essayez avec d'autres filtres`,
        EN: `Oups, there's nothing here 🧐. Try with other filters`,
    },
    NoNavigation: {
        FR: `Oups, il n'y a rien ici 🧐. Enregistrez votre première navigation en cliquant sur "Nouvelle navigation".`,
        EN: `Oups, there's nothing here 🧐. To create you first navigation, click on "New navigation".`,
    },
    seeMore: {
        FR: 'En voir plus',
        EN: 'See more',
    },
}
