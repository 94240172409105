const pluralize = require('pluralize')

// for now it's used globally because there is no conflict but probably one
// day we will have to split depending on the use case
const toTestArray = [
    'title',
    'checkup.checkupType.translation',
    'checkup.userCheckup.name',
    'checkup.equipments.equipmentType.translation',
    'checkup.equipments.userEquipment.name',
    'checkup.parts.partType.translation',
    'checkup.parts.userPart.name',
    'checkup.parts.equipment.equipmentType.translation',
    'checkup.parts.equipment.userEquipment.name',
    'detail.part.partType.translation',
    'detail.part.userPart.name',
    'detail.equipment.equipmentType.translation',
    'detail.equipment.userEquipment.name',
    'checkupType.translation',
    'userCheckup.name',
    'equipmentType.translation',
    'userEquipment.name',
    'partType.translation',
    'userPart.name',
    'userEquipmentFamily.name',
    'type.translation',
    'category',
    'infos.partEquipment',
]

const getName = (element, context) => {
    if (typeof element === 'string') return element

    let title = ''

    toTestArray.forEach(path => {
        const pathArray = path.split('.')
        let currentElements = [element] // Start with an array containing the root element

        for (let i = 0; i < pathArray.length; i++) {
            let nextElements = []

            currentElements.forEach(currentElement => {
                if (Array.isArray(currentElement[pathArray[i]])) {
                    // If the current element is an array, add each item to nextElements
                    nextElements.push(...currentElement[pathArray[i]])
                } else if (currentElement[pathArray[i]]) {
                    // If the current element is an object, add it to nextElements
                    nextElements.push(currentElement[pathArray[i]])
                }
            })

            if (nextElements.length === 0) {
                break // No more elements to process
            }

            currentElements = nextElements // Move to the next level
        }

        // Process the final elements at the end of the path
        currentElements.forEach(currentElement => {
            if (typeof currentElement === 'string') {
                title = `${title} ${currentElement}`
            } else if (
                pathArray[pathArray.length - 1] === 'translation' &&
                currentElement[context] &&
                typeof currentElement[context] === 'string'
            ) {
                title = `${title} ${currentElement[context]}`
            }
        })
    })

    return [...new Set(title.trim().split(' '))].toString().replace(/,/g, ' ')
}

export const fncDeepFilterOptions = (option, input, context) => {
    const words = input.inputValue
        ? input.inputValue
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .split(' ')
        : []

    return words.every(word => {
        return getName(option, context)
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(pluralize.singular(word))
    })
}
