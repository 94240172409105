import { LS_USER_KEY } from '../services/config.service'
import { requestApi } from './api.service'
import { API_URL } from './config.service'
export const userService = {
    login,
    logout,
    forget,
    signin,
    getFromLS,
    getAuthHeader,
    refreshToken,
    requestSubscriptions,
    getWithoutAuthHeader,
    updatePassword,
}

let userToken

function getAuthHeader(lang) {
    return {
        Authorization: 'Bearer ' + userToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Content-Location': 'BOATON_WEB',
        'Content-Language': lang || 'fr',
    }
}

function getAuthHeaderRefresh() {
    const localStorageData = JSON.parse(localStorage.getItem(LS_USER_KEY))
    if (localStorageData && localStorageData.refreshToken) {
        return {
            Authorization: 'Bearer ' + localStorageData.refreshToken,
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Content-Location': 'BOATON_WEB',
            'Content-Language': 'fr',
        }
    }
    return getWithoutAuthHeader()
}

function getWithoutAuthHeader(lang) {
    return {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Content-Location': 'BOATON_WEB',
        'Content-Language': lang || 'fr',
    }
}

function getFromLS() {
    const localStorageData = JSON.parse(localStorage.getItem(LS_USER_KEY))

    if (localStorageData && localStorageData.accessToken) {
        userToken = localStorageData.accessToken
    }

    return localStorageData
}

function login(email, password) {
    const body = { email: email, password: password }
    return fetch(API_URL + '/auth/login', {
        method: 'POST',
        headers: getWithoutAuthHeader(),
        body: JSON.stringify(body),
    })
        .then(async result => {
            const json = await result.json()
            if (json.statusCode === 401) {
                return Promise.reject(json.message)
            }
            if (json.accessToken) {
                userToken = json.accessToken
            }
            // we save auth data to local storage
            localStorage.setItem(
                LS_USER_KEY,
                JSON.stringify({
                    accessToken: json.accessToken || null,
                    refreshToken: json.refreshToken || null,
                    id: json.id || null,
                }),
            )
            return json
        })
        .catch(error => {
            console.error('Cannot login')
            return Promise.reject(error)
        })
}

function updatePassword(lastPassword, newPassword, language) {
    const body = { newPassword: newPassword, lastPassword: lastPassword }
    return requestApi(
        '/users/update-password',
        'POST',
        body,
        false,
        true,
        language,
    ).then(
        result => {
            return result
        },
        error => {
            console.error('Cannot update password')
            return null
        },
    )
}

function refreshToken() {
    const localStorageData = JSON.parse(localStorage.getItem(LS_USER_KEY))
    const body =
        localStorageData && localStorageData.refreshToken
            ? { refreshToken: localStorageData.refreshToken }
            : {}
    return fetch(API_URL + '/auth/refresh', {
        method: 'POST',
        headers: getAuthHeaderRefresh(),
        body: JSON.stringify(body),
    })
        .then(async result => {
            const json = await result.json()
            if (json.statusCode === 401) {
                logout()
                return Promise.reject(json.message)
            }
            if (json.accessToken) {
                userToken = json.accessToken
            }
            // we save auth data to local storage
            localStorage.setItem(
                LS_USER_KEY,
                JSON.stringify({
                    accessToken: json.accessToken || null,
                    refreshToken: json.refreshToken || null,
                    id: json.id || null,
                }),
            )
            return json
        })
        .catch(error => {
            logout()
            console.error('Refresh token out dated')
            return Promise.reject(error)
        })
}

function forget(email, language) {
    const body = {
        email: email,
    }
    return requestApi(
        '/users/reset-password/' + email,
        'POST',
        body,
        false,
        true,
        language,
    ).then(
        async result => {
            return result
        },
        error => {
            console.error('Cannot reset password')
            return Promise.reject(error)
        },
    )
}

function signin(body, language) {
    return fetch(API_URL + '/users', {
        method: 'POST',
        headers: getWithoutAuthHeader(language.toLowerCase()),
        body: JSON.stringify(body),
    })
        .then(async result => {
            const json = await result.json()
            if (json.statusCode === 401) {
                return Promise.reject(json.message)
            }
            if (json.statusCode === 409) {
                return Promise.reject(json.message)
            }
            if (json.accessToken) {
                userToken = json.accessToken
            }
            localStorage.setItem(
                LS_USER_KEY,
                JSON.stringify({
                    accessToken: json.accessToken || null,
                    refreshToken: json.refreshToken || null,
                    id: json.id || null,
                }),
            )
            return json
        })
        .catch(error => {
            console.error('Cannot signin')
            return Promise.reject(error)
        })
}

function logout() {
    localStorage.removeItem(LS_USER_KEY)
    localStorage.removeItem('company')
    localStorage.removeItem('bookingInfo')
    localStorage.removeItem('groupIdSelect')
    localStorage.removeItem('boatIdSelect')
    localStorage.removeItem('lastTimePopupSeen')
}

function requestSubscriptions() {
    return fetch(API_URL + '/users/subscriptions', {
        method: 'GET',
        headers: userService.getAuthHeader(),
    })
        .then(async result => {
            const json = await result.json()
            if (json.statusCode === 401) {
                return Promise.reject(json.message)
            }
            return json
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

//TODO: a refacto
// function subscribe() {
//     return fetch(API_URL + '/logbook/subscribe.php', {
//         method: 'POST',
//         headers: userService.getAuthHeader(),
//     })
//         .then(response => {
//             if (!response.ok) {
//                 return Promise.reject(response.statusText)
//             }
//             return response.json()
//         })
//         .then(result => {
//             const cachedUser = JSON.parse(localStorage.getItem(LS_USER_KEY))
//             cachedUser.User = Object.assign(cachedUser.User, result.User)
//             console.info(
//                 'user is now subscribed, updating cache',
//                 result.User,
//                 cachedUser.User,
//             )
//             localStorage.setItem(LS_USER_KEY, JSON.stringify(cachedUser))
//             return cachedUser
//         })
// }
