export default {
    navigationList: {
        FR: 'Liste de navigations',
        EN: 'Navigation List',
    },
    weatherForecast: {
        FR: 'Prévisions météo',
        EN: 'Weather Forecast',
    },
}
