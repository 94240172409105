import React from 'react'
import BoatOnModalCore from '../../../../common/BoatOnModalCore'
import { connect } from 'react-redux'
import dictionary from './Dictionary/RepairModalDico'
import { withStyles } from '@material-ui/core/styles'
import {
    TextField,
    MenuItem,
    InputAdornment,
    Tooltip,
    Typography,
    IconButton,
} from '@material-ui/core'
import styles from '../Styles/RepairListCss'
import {
    postBOBEvents,
    updateStateEvent,
    setBOBStateEvent,
    putBOBEvents,
    deleteFileFromBobEvent,
    addFileToBobEvent,
    unSetNewEvent,
    getCycle,
    getImageToDisplay,
} from '../../../../../actions/bob.actions'
import { typesActions } from '../../../../../actions/types.actions'
import BoatOnModal from '../../../../common/BoatOnModal'
import InventoryModal from '../../Inventory/InventoryModal/InventoryModal'
import InputLabel from '../../../../common/UsefullComponents/InputLabel'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import dateUtils from '@date-io/date-fns'
import LocaleFr from 'date-fns/locale/fr'
import LocaleEn from 'date-fns/locale/en-US'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import HelpIcon from '@material-ui/icons/Help'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto'
import DisplayerImgModal from '../../../DisplayerImgModal'
import ModalAmountItCheck from '../../../../common/UsefullComponents/ModalAmountItCheck'
import BoatOnAutoComplete from '../../../../common/BoatOnAutoComplete'
import NewUserModal from '../../MyUsersPage/NewUserModal'
import { groupActions } from '../../../../../actions/group.actions'
import { userActions } from '../../../../../actions'
import BoatOnDateSelector from '../../../../common/UsefullComponents/BoatOnDateSelector'
import ValidModal from './ValidModal'
import UnvalidModal from './UnvalidModal'
import BoatOnBlock from '../../../../common/Blocks/BoatOnBlock'
import { CheckCircle } from '@material-ui/icons'
import { activityActions } from '../../../../../actions/activity.actions'
import { commentsActions } from '../../../../../actions/bob/comments.actions'
import BoatOnNumberField from '../../../../common/UsefullComponents/BoatOnNumberField'

class RepairModal extends BoatOnModalCore {
    constructor(props) {
        super(props)
        this.dictionary = {
            ...this.dictionary,
            ...dictionary,
        }

        this.state = {
            event: props.event || null,
            fileNoUrl: { event: [] },
            displayNewFiles: { event: [] },
            deletedFiles: { event: [] },
            error: false,
            inventory: null,
            openModal: null,
            eventDetail: null,
            tabIndex: 0,
            usersSelected: [],
            usersSelectedRtq: [],
            isUserRtq: false,
            emailAddUser: null,
            emailAddUserRtq: null,
            checkModalOpen: null,
            loadingImage: false,
            tax: 20,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeCommun = this.handleChangeCommun.bind(this)
        this.saveRepair = this.saveRepair.bind(this)
        this.addDocument = this.addDocument.bind(this)
        this.deleteDocumentNoUp = this.deleteDocumentNoUp.bind(this)
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.addNewEventDetail = this.addNewEventDetail.bind(this)
        this.clickDocument = this.clickDocument.bind(this)
        this.goToSecondStep = this.goToSecondStep.bind(this)
        this.handleUsersChange = this.handleUsersChange.bind(this)
        this.handleUsersChangeRtq = this.handleUsersChangeRtq.bind(this)
        this.getUserName = this.getUserName.bind(this)
        this.getUserNameRtq = this.getUserNameRtq.bind(this)
        this.conditionAlreadySet = this.conditionAlreadySet.bind(this)
        this.conditionAlreadySetRtq = this.conditionAlreadySetRtq.bind(this)
        this.handleOpenModalNewUser = this.handleOpenModalNewUser.bind(this)
        this.handleOpenModalNewUserRtq = this.handleOpenModalNewUserRtq.bind(
            this,
        )
        this.handleNewUser = this.handleNewUser.bind(this)
        this.isConfirmedToggler = this.isConfirmedToggler.bind(this)
        this.handleCloseCheckModal = this.handleCloseCheckModal.bind(this)
        this.validateEvent = this.validateEvent.bind(this)
        this.unValidateEvent = this.unValidateEvent.bind(this)
        this.handleValidation = this.handleValidation.bind(this)
        this.handleUnvalidation = this.handleUnvalidation.bind(this)
        this.handleChangeAmount = this.handleChangeAmount.bind(this)
        this.handleChangeTax = this.handleChangeTax.bind(this)
    }

    // CheckModal
    isConfirmedToggler() {
        this.setState({ isConfirmed: true })
    }

    handleCloseCheckModal() {
        this.setState({ checkModalOpen: null })
    }

    clickDocument() {
        this.refs.fileUploader.click()
    }

    goToSecondStep() {
        this.setTabIndex(1)
    }

    async handleNewUser(email, role, boatGroup) {
        const { groupId } = this.props
        await this.props.dispatch(
            groupActions.addUserToGroup(
                groupId,
                role,
                boatGroup,
                email,
                this.context,
            ),
        )
        if (this.state.isUserRtq) {
            this.setState({ emailAddUserRtq: email })
        } else {
            this.setState({ emailAddUser: email })
        }
        await this.props.dispatch(groupActions.requestUserGroups(groupId))
        this.handleCloseModal()
    }

    handleOpenModalNewUser() {
        this.setState({ openModal: `newUser`, isUserRtq: false })
    }

    handleOpenModalNewUserRtq() {
        this.setState({ openModal: `newUser`, isUserRtq: true })
    }

    _setIdPartToCheckup(part) {
        const { event, eventDetail } = this.state

        if (part) {
            const parts = event?.checkup?.parts || []
            const index = parts.findIndex(check => {
                return (
                    check.id === undefined &&
                    eventDetail?.detail?.part?.userPart?.name ===
                        check?.userPart?.name
                )
            })
            if (index !== -1) {
                parts[index] = part
                event.checkup = {
                    ...event.checkup,
                    parts: parts,
                }
            }
        }
        return event
    }

    _setIdEquipmentToCheckup(equipment) {
        const { event, eventDetail } = this.state

        if (equipment) {
            if (event?.checkup?.equipments?.length > 0) {
                const equipments = event?.checkup?.equipments || []
                const index = equipments.findIndex(
                    check =>
                        check.id === undefined &&
                        eventDetail?.detail?.equipment?.userEquipment?.name ===
                            check?.userEquipment?.name,
                )
                if (index !== -1) {
                    equipments[index] = equipment
                    event.checkup = {
                        ...event.checkup,
                        equipments: equipments,
                    }
                }
            } else if (event?.checkup?.parts?.length > 0) {
                const parts = event?.checkup?.parts || []
                parts.map(part => {
                    part.equipment = equipment
                    return part
                })
                event.checkup = {
                    ...event.checkup,
                    parts: parts,
                }
            }
        }
        return event
    }

    componentDidUpdate() {
        const {
            newEvent,
            linkedUsers,
            activity,
            eventTypes,
            reparation,
        } = this.props
        const {
            emailAddUser,
            emailAddUserRtq,
            usersSelected,
            usersSelectedRtq,
            event,
        } = this.state

        if (reparation && !event?.eventType?.id) {
            this.handleChange({ eventTypeId: 2 })
        }
        if (newEvent) {
            const { detail } = newEvent
            if (detail) {
                const { equipment, part } = detail
                let { event } = this.state
                event = this._setIdPartToCheckup(part)
                event = this._setIdEquipmentToCheckup(equipment)
                this.handleChangeCommun({
                    checkup: event.checkup,
                })
                this.setState({ eventDetail: null }, () => {
                    this.props.dispatch(unSetNewEvent())
                })
            }
        }
        if (emailAddUser) {
            for (var i = 0; i < linkedUsers.length; i++) {
                if (
                    linkedUsers[i]?.user?.email === emailAddUser ||
                    linkedUsers[i]?.userSubscribe?.mail === emailAddUser
                ) {
                    if (linkedUsers[i]?.user) {
                        this.setState({
                            emailAddUser: null,
                            usersSelected: [
                                ...usersSelected,
                                { user: linkedUsers[i]?.user },
                            ],
                        })
                    } else {
                        this.setState({
                            emailAddUser: null,
                            usersSelected: [
                                ...usersSelected,
                                {
                                    userSubscribe:
                                        linkedUsers[i]?.userSubscribe,
                                },
                            ],
                        })
                    }
                }
            }
        } else if (emailAddUserRtq) {
            for (var i = 0; i < linkedUsers.length; i++) {
                if (
                    linkedUsers[i]?.user?.email === emailAddUserRtq ||
                    linkedUsers[i]?.userSubscribe?.mail === emailAddUserRtq
                ) {
                    if (linkedUsers[i]?.user) {
                        this.setState({
                            emailAddUserRtq: null,
                            usersSelectedRtq: [
                                ...usersSelectedRtq,
                                { user: linkedUsers[i]?.user },
                            ],
                        })
                    } else {
                        this.setState({
                            emailAddUserRtq: null,
                            usersSelectedRtq: [
                                ...usersSelectedRtq,
                                {
                                    userSubscribeRtq:
                                        linkedUsers[i]?.userSubscribe,
                                },
                            ],
                        })
                    }
                }
            }
        }
    }

    handleOpenModal(openModal, { event }) {
        this.setState({
            openModal: openModal,
            eventDetail: JSON.parse(JSON.stringify(event)),
        })
    }

    addNewEventDetail(callback) {
        this.setState({ openModal: null }, () => {
            callback()
        })
    }

    handleCloseModal() {
        this.setState({ openModal: null, eventDetail: null })
    }

    componentDidMount() {
        if (!this.state.event) setBOBStateEvent(this, 'event')
        else if (this.state?.event?.attachedTo) {
            const { event } = this.state
            const usersSelected = []
            const usersSelectedRtq = []
            event.attachedTo.forEach(attach => {
                if (attach.user) usersSelected.push({ user: attach.user })
                else if (attach.userSubscribe)
                    usersSelected.push({ userSubscribe: attach.userSubscribe })
                else if (attach.userRtq)
                    usersSelectedRtq.push({ userRtq: attach.userRtq })
                else if (attach.userSubscribeRtq)
                    usersSelectedRtq.push({
                        userSubscribeRtq: attach.userSubscribeRtq,
                    })
            })
            this.setState({
                usersSelected: usersSelected,
                usersSelectedRtq: usersSelectedRtq,
            })
        }
        if (!this.props.checkupTypes) {
            // this.props.dispatch(typesActions.requestCheckupTypes())
        }

        // Lancement du chargement de l'activité lorsque la modale est montée
        if (this.state?.event?.id) {
            this.props.dispatch(
                activityActions.getBobEventActivity(this.state?.event?.id),
            )
            this.props.dispatch(
                commentsActions.getBobEventComments(this.state?.event?.id),
            )
        }

        if (this.state.event?.transaction) {
            const transaction = this.state.event.transaction

            if (transaction?.amountIt && transaction?.amountEt) {
                const diff = transaction?.amountIt - transaction?.amountEt
                const tax = (diff / transaction?.amountEt) * 100

                this.setState({ tax })
            }
        }
    }

    addDocument(event) {
        if (
            event?.target?.files &&
            [...event.target.files].find(file =>
                file.name.toLowerCase().includes('.heic'),
            )
        ) {
            // So we display a loading icon during the time of heic conversion
            this.setState({ loadingImage: true }, async () => {
                await addFileToBobEvent(this, event, {
                    fileNoUrl: this.state.fileNoUrl.event,
                    displayNewFiles: this.state.displayNewFiles.event,
                })
                this.setState({ loadingImage: false })
            })
        } else {
            addFileToBobEvent(this, event, {
                fileNoUrl: this.state.fileNoUrl.event,
                displayNewFiles: this.state.displayNewFiles.event,
            })
        }
    }

    deleteDocumentNoUp(i) {
        deleteFileFromBobEvent(this, i, {
            deletedFiles: this.state.deletedFiles.event,
            displayNewFiles: this.state.displayNewFiles.event,
            fileNoUrl: this.state.fileNoUrl.event,
            files: this.state.event.files,
        })
    }

    setTitle(checkup) {
        const { checkupTypes } = this.props
        if (checkup) {
            const userCheckupName =
                checkup.userCheckup && checkup.userCheckup.name
                    ? checkup.userCheckup.name
                    : null
            const checkupType = typesActions.getTypeById(
                checkupTypes?.filter(checkup => checkup.checkupType) || [],
                checkup && checkup.checkupType && checkup.checkupType.id
                    ? checkup.checkupType.id
                    : null,
            )
            return userCheckupName
                ? userCheckupName
                : checkupType?.checkupType?.translation
                ? this.displayTextApi(checkupType.checkupType.translation)
                : null
        }
        return null
    }

    _setCycle(event) {
        if (event?.checkup?.userCheckup) {
            event.checkup.userCheckup.cycles = [
                {
                    cycleType: { id: event.actualDuration !== null ? 2 : 1 },
                    cycleLength: 0,
                },
            ]
        }
        return event
    }

    _getNewEventAdded(results) {
        const { events } = this.props
        let newEvent = null

        if (results && events) {
            results.forEach(result => {
                if (newEvent === null || newEvent.id < result.id) {
                    newEvent = result
                }
            })
        }
        return newEvent
    }

    async saveOneEvent(nameEvent) {
        const { boat, type } = this.props
        const {
            deletedFiles,
            fileNoUrl,
            isConfirmed,
            usersSelected,
            usersSelectedRtq,
        } = this.state
        let event = this.state[nameEvent]
        let cycle = getCycle({ event: event })
        if (!cycle) {
            event = this._setCycle(event)
        }

        cycle = getCycle({ event: event })
        let {
            description,
            transaction,
            eventType,
            checkup,
            delimitedDate,
            actualDuration,
            protocol,
        } = event

        if (
            (delimitedDate?.endDate && delimitedDate?.startDate) ||
            type === `LAST_REPAIR`
        ) {
            delimitedDate.endDate = delimitedDate.startDate
        }

        if (
            delimitedDate?.startDate ||
            (cycle &&
                cycle.cycleType &&
                cycle.cycleType.id === 2 &&
                actualDuration !== null)
        ) {
            let result = null
            if (
                (event.transaction === null
                    ? true
                    : parseFloat(event.transaction.amountIt) !== 0) ||
                isConfirmed
            ) {
                if (event.id) {
                    result = await this.props.dispatch(
                        putBOBEvents(event.id, this.setTitle(checkup), {
                            newFiles: fileNoUrl[nameEvent],
                            files: event.files,
                            delimitedDate: delimitedDate,
                            transaction: transaction,
                            description: description,
                            checkup: checkup,
                            eventTypeId: eventType.id,
                            deletedFiles: deletedFiles[nameEvent],
                            actualDuration: actualDuration,
                            attachedTo: [...usersSelected, ...usersSelectedRtq],
                            protocol: protocol,
                        }),
                    )
                } else {
                    result = await this.props.dispatch(
                        postBOBEvents(
                            boat.id,
                            eventType.id,
                            this.setTitle(checkup),
                            {
                                files: fileNoUrl[nameEvent],
                                duplicateFiles: event.files,
                                delimitedDate: delimitedDate,
                                transaction: transaction,
                                description: description,
                                checkup: checkup,
                                actualDuration: actualDuration,
                                attachedTo: [
                                    ...usersSelected,
                                    ...usersSelectedRtq,
                                ],
                                protocol: protocol,
                            },
                            this.props.language,
                        ),
                    )
                }
            } else if (
                parseFloat(event.transaction.amountIt) === 0 ||
                !isConfirmed
            ) {
                // show ModalAmountItCheck
                this.setState({ checkModalOpen: 'inventoryCheck' })
            } else {
                this.setState({ error: true })
            }
            if (result) this._handleNextStep(result, fileNoUrl[nameEvent])
        }
    }

    _handleNextStep(event, files) {
        const { type } = this.props
        let cycle = getCycle({ event: event })
        if (type === `LAST_REPAIR` && cycle?.cycleType?.id === 1) {
            const startDate = new Date(event.delimitedDate.startDate)
            const nextDate = new Date(
                startDate.setMonth(
                    startDate.getMonth() + cycle.cycleLength || 12,
                ),
            )
            this.props.handleClose({
                files: files,
                title: event.title,
                eventType: event.eventType,
                checkup: event.checkup,
                delimitedDate: { startDate: nextDate },
                protocol: event.protocol,
                transaction: event.transaction,
                attachedTo: event.attachedTo,
                description: event.description,
            })
        } else if (type === `LAST_REPAIR` && cycle?.cycleType?.id === 2) {
            const actualDuration = event.actualDuration + cycle.cycleLength
            this.props.handleClose({
                files: files,
                title: event.title,
                actualDuration: actualDuration,
                eventType: event.eventType,
                checkup: event.checkup,
                protocol: event.protocol,
                transaction: event.transaction,
                attachedTo: event.attachedTo,
                description: event.description,
            })
        } else this.props.handleClose()
    }

    checkupSet() {
        const { event } = this.state
        const { type } = this.props
        let toSend = true

        if (event && event.checkup) {
            const { eventType, checkup, delimitedDate, actualDuration } = event
            const cycle = getCycle({ event: event })
            if (!eventType || !eventType.id) {
                this.setState({ error: true, tabIndex: 0 })
                toSend = false
            }
            if (
                toSend &&
                checkup.checkupType === null &&
                checkup.userCheckup === null
            ) {
                this.setState({ error: true, tabIndex: 0 })
                toSend = false
            }
            if (
                toSend &&
                (!checkup.equipments ||
                    !Array.isArray(checkup.equipments) ||
                    checkup.equipments.length === 0) &&
                (!checkup.parts ||
                    !Array.isArray(checkup.parts) ||
                    checkup.parts.length === 0)
            ) {
                this.setState({ error: true, tabIndex: 0 })
                toSend = false
            }
            if (
                toSend &&
                checkup.parts &&
                Array.isArray(checkup.parts) &&
                checkup.parts.length > 0
            ) {
                checkup.parts.forEach(part => {
                    if (!part.equipment || !part.equipment.equipmentFamily)
                        toSend = false
                })
            } else if (
                toSend &&
                checkup.equipments &&
                Array.isArray(checkup.equipments) &&
                checkup.equipments.length > 0
            ) {
                checkup.equipments.forEach(equipment => {
                    if (!equipment || !equipment.equipmentFamily) toSend = false
                })
            }
            if (toSend && cycle?.cycleType?.id !== undefined) {
                if (
                    toSend &&
                    cycle?.cycleType?.id === 2 &&
                    actualDuration === null
                ) {
                    this.setState({ error: true, tabIndex: 1 })
                    return false
                }
                if (
                    toSend &&
                    (cycle?.cycleType?.id === 1 ||
                        type === `LAST_REPAIR` ||
                        event?.delimitedDate?.endDate) &&
                    (!delimitedDate || !delimitedDate.startDate)
                ) {
                    this.setState({ error: true, tabIndex: 1 })
                    toSend = false
                }
            } else if (cycle?.cycleType?.id === undefined) {
                if (
                    toSend &&
                    type === `LAST_REPAIR` &&
                    (!delimitedDate || !delimitedDate.startDate)
                ) {
                    this.setState({ error: true, tabIndex: 1 })
                    return false
                } else if (
                    toSend &&
                    actualDuration === null &&
                    (!delimitedDate || !delimitedDate.startDate)
                ) {
                    this.setState({ error: true, tabIndex: 1 })
                    return false
                }
            }
        } else {
            this.setState({ error: true, tabIndex: 0 })
            toSend = false
        }
        return toSend
    }

    async saveRepair() {
        if (this.checkupSet()) {
            await this.saveOneEvent('event')
        }
    }

    handleChangeCommun(value) {
        this.handleChange(value, 'event')
    }

    handleChange(value, name) {
        updateStateEvent(this, { ...value, name: name })
    }

    _setTitle() {
        const { eventDetail } = this.state
        if (eventDetail) {
            const { detail } = eventDetail
            if (detail && detail.part && detail.part.equipment) {
                return `${
                    eventDetail.id
                        ? this.displayText('part')
                        : this.displayText('addPart')
                }: ${
                    detail.part.partType
                        ? this.displayTextApi(detail.part.partType.translation)
                        : detail.part.userPart.name
                } (${
                    detail.part.equipment.equipmentType
                        ? this.displayTextApi(
                              detail.part.equipment.equipmentType.translation,
                          )
                        : detail.part.equipment.userEquipment.name
                })`
            } else if (
                detail &&
                detail.equipment &&
                detail.equipment.equipmentFamily
            ) {
                return `${this.displayText('equipment')}: ${
                    detail.equipment.equipmentType
                        ? this.displayTextApi(
                              detail.equipment.equipmentType.translation,
                          )
                        : detail.equipment.userEquipment.name
                } (${
                    detail.equipment.equipmentFamily.type
                        ? this.displayTextApi(
                              detail.equipment.equipmentFamily.type.translation,
                          )
                        : detail.equipment.equipmentFamily.userEquipmentFamily
                              .name
                })`
            } else if (detail && detail.part) {
                return `${
                    eventDetail.id
                        ? this.displayText('part')
                        : this.displayText('addPart')
                }: ${
                    detail.part.partType
                        ? this.displayTextApi(detail.part.partType.translation)
                        : detail.part.userPart.name
                }`
            } else if (detail && detail.equipment) {
                return `${this.displayText('equipment')}: ${
                    detail.equipment.equipmentType
                        ? this.displayTextApi(
                              detail.equipment.equipmentType.translation,
                          )
                        : detail.equipment.userEquipment.name
                }`
            }
        }
        return ``
    }

    _renderDate() {
        const { classes, type } = this.props
        const { event } = this.state
        const today = new Date()
        return (
            <MuiPickersUtilsProvider
                utils={dateUtils}
                locale={this.getLanguage() === 'FR' ? LocaleFr : LocaleEn}
            >
                <BoatOnDateSelector
                    id={'dateCheckup'}
                    margin
                    label={
                        <>
                            {this.displayText('checkupDate')}
                            <Tooltip
                                title={
                                    type === `LAST_REPAIR` ||
                                    event?.delimitedDate?.endDate
                                        ? this.displayText(`helpDateLast`)
                                        : this.displayText(`helpDate`)
                                }
                                classes={{
                                    tooltip: classes.tooltip,
                                }}
                            >
                                <HelpIcon className={classes.helpIcon} />
                            </Tooltip>
                        </>
                    }
                    minDate={
                        type === `NEXT_REPAIR`
                            ? today.setDate(today.getDate())
                            : undefined
                    }
                    maxDate={
                        type === `LAST_REPAIR`
                            ? today.setDate(today.getDate())
                            : undefined
                    }
                    onChange={value => {
                        this.handleChange({
                            delimitedDate: {
                                startDate: value,
                            },
                        })
                    }}
                    value={
                        event &&
                        event.delimitedDate &&
                        event.delimitedDate.startDate
                            ? new Date(event.delimitedDate.startDate)
                            : null
                    }
                    error={this.state.error && !event?.delimitedDate?.startDate}
                    helperText={this.displayText('errorDate')}
                    required
                />
            </MuiPickersUtilsProvider>
        )
    }

    _renderCycleHours() {
        const { classes, type } = this.props
        const { event, error } = this.state
        const cycle = getCycle({ event: event })

        return (
            <>
                {(cycle?.cycleType?.id === undefined ||
                    cycle?.cycleType?.id === 2) && (
                    <BoatOnNumberField
                        isDecimal
                        style={{ marginRight: '20px' }}
                        id="actualCycle"
                        variant="outlined"
                        label={
                            <>
                                {this.displayText('actualCycleHours')}
                                <Tooltip
                                    title={
                                        type === `LAST_REPAIR` ||
                                        event?.delimitedDate?.endDate
                                            ? this.displayText(`helpHoursLast`)
                                            : this.displayText(`helpHours`)
                                    }
                                    classes={{
                                        tooltip: classes.tooltip,
                                    }}
                                >
                                    <HelpIcon className={classes.helpIcon} />
                                </Tooltip>
                            </>
                        }
                        required={cycle?.cycleType?.id !== undefined}
                        error={
                            ((cycle?.cycleType?.id === 2 &&
                                event?.actualDuration === null) ||
                                (cycle?.cycleType?.id === undefined &&
                                    event?.actualDuration === null &&
                                    !event?.delimitedDate?.startDate)) &&
                            error
                        }
                        helperText={
                            ((cycle?.cycleType?.id === 2 &&
                                event?.actualDuration === null) ||
                                (cycle?.cycleType?.id === undefined &&
                                    event?.actualDuration === null &&
                                    !event?.delimitedDate?.startDate)) &&
                            error
                                ? type === `LAST_REPAIR` ||
                                  event?.delimitedDate?.endDate
                                    ? this.displayText(
                                          'errorActualCycleLengthLast',
                                      )
                                    : this.displayText(
                                          'errorActualCycleLengthNext',
                                      )
                                : ''
                        }
                        margin={`normal`}
                        className={classes.textField}
                        value={
                            event?.actualDuration === null
                                ? ''
                                : event?.actualDuration
                        }
                        onChange={e => {
                            this.handleChange({
                                actualDuration:
                                    e.target.value !== ''
                                        ? parseInt(e.target.value)
                                        : null,
                            })
                        }}
                        InputProps={{
                            endAdornment: (
                                <>
                                    <InputAdornment position="start">
                                        {'h'}
                                    </InputAdornment>
                                </>
                            ),
                        }}
                        InputLabelProps={{ style: { pointerEvents: 'auto' } }}
                    />
                )}
            </>
        )
    }

    handleChangeAmount(type, value) {
        value = value.toString().replace(',', '.')
        const { tax } = this.state

        const transaction =
            type === 'amountIt'
                ? {
                      amountIt: value.trim(),
                      amountEt: parseFloat(value / (1 + tax / 100)).toFixed(2),
                  }
                : {
                      amountIt: parseFloat(value * (1 + tax / 100)).toFixed(2),
                      amountEt: value.trim(),
                  }

        this.handleChange({ transaction })
    }

    handleChangeTax(value) {
        const { event } = this.state

        this.setState(
            {
                tax: value,
            },
            () =>
                this.handleChangeAmount(
                    'amountEt',
                    event?.transaction?.amountEt ?? '',
                ),
        )
    }

    _renderAmount() {
        const { classes, eventTypes } = this.props
        const { event, tax } = this.state
        const eventType = typesActions.getEventTypeById(
            eventTypes,
            event?.eventType?.id,
        )
        return (
            <div className={classes.amountContainer}>
                <BoatOnNumberField
                    isDecimal
                    id="amountEt"
                    variant="outlined"
                    label={this.displayText('amountEt')}
                    margin={`normal`}
                    value={event?.transaction?.amountEt ?? ' '}
                    onChange={e =>
                        this.handleChangeAmount('amountEt', e.target.value)
                    }
                    className={classes.textField}
                    InputProps={{
                        startAdornment:
                            event && eventType ? (
                                <InputAdornment position="start">
                                    {eventType.spending ? (
                                        <RemoveIcon fontSize="small" />
                                    ) : (
                                        <AddIcon fontSize="small" />
                                    )}
                                </InputAdornment>
                            ) : null,
                        endAdornment: (
                            <InputAdornment position="end">€</InputAdornment>
                        ),
                    }}
                />
                <BoatOnNumberField
                    isDecimal
                    variant="outlined"
                    label={this.displayText('tax')}
                    margin={`normal`}
                    value={tax}
                    min={0}
                    onChange={e => this.handleChangeTax(e.target.value)}
                    className={classes.taxField}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                        ),
                    }}
                />
                <BoatOnNumberField
                    isDecimal
                    id="amountIt"
                    variant="outlined"
                    label={this.displayText('amountIt')}
                    margin={`normal`}
                    value={event?.transaction?.amountIt ?? ' '}
                    onChange={e =>
                        this.handleChangeAmount('amountIt', e.target.value)
                    }
                    className={classes.textField}
                    InputProps={{
                        startAdornment:
                            event && eventType ? (
                                <InputAdornment position="start">
                                    {eventType.spending ? (
                                        <RemoveIcon fontSize="small" />
                                    ) : (
                                        <AddIcon fontSize="small" />
                                    )}
                                </InputAdornment>
                            ) : null,
                        endAdornment: (
                            <InputAdornment position="end">€</InputAdornment>
                        ),
                    }}
                />
            </div>
        )
    }

    _renderDocuments() {
        const { classes } = this.props
        const { event, displayNewFiles } = this.state

        return (
            <>
                <Typography variant={`body1`}>
                    {this.displayText(`documents`)}
                </Typography>
                <div className={classes.addDoc} onClick={this.clickDocument}>
                    <AddAPhotoIcon className={classes.doc} />
                </div>
                <input
                    onChange={e => {
                        this.addDocument(e)
                    }}
                    multiple
                    type="file"
                    id="fileLast"
                    ref="fileUploader"
                    style={{ display: 'none' }}
                />
                <DisplayerImgModal
                    file={getImageToDisplay(event, displayNewFiles.event)}
                    deleteDocumentNoUp={this.deleteDocumentNoUp}
                    loadingImage={this.state.loadingImage}
                />
            </>
        )
    }

    getUserName(element) {
        const { linkedUsers } = this.props
        if (element?.user?.id) {
            let name = ``
            linkedUsers.forEach(link => {
                if (link?.user?.id === element?.user?.id) {
                    name = `${link?.user?.firstName} ${link?.user?.lastName}`
                }
            })
            return name
        } else if (element?.userSubscribe?.id && element?.userSubscribe?.mail) {
            return element?.userSubscribe?.mail
        }
        return null
    }

    getUserNameRtq(element) {
        const { linkedUsers } = this.props
        if (element?.userRtq?.id) {
            let name = ``
            linkedUsers.forEach(link => {
                if (link?.user?.id === element?.userRtq?.id) {
                    name = `${link?.user?.firstName} ${link?.user?.lastName}`
                }
            })
            return name
        } else if (
            element?.userSubscribeRtq?.id &&
            element?.userSubscribeRtq?.mail
        ) {
            return element?.userSubscribeRtq?.mail
        }
        return null
    }

    filterUsers(option, input) {
        const firstLast = option?.user?.id
            ? `${option?.user?.firstName} ${option?.user?.lastName}`
            : null
        const lastFirst = option?.user?.id
            ? `${option?.user?.lastName} ${option?.user?.firstName}`
            : null

        if (
            !input?.inputValue ||
            (option?.user?.id &&
                ((firstLast &&
                    firstLast.toLowerCase().includes(input.inputValue)) ||
                    (lastFirst &&
                        lastFirst.toLowerCase().includes(input.inputValue)))) ||
            (option?.userSubscribe?.id &&
                option?.userSubscribe?.mail
                    .toLowerCase()
                    .includes(input.inputValue.toLowerCase()))
        ) {
            return true
        }
        return false
    }

    handleUsersChange(_, values) {
        const arrayUserId = []
        values.forEach(value => {
            if (value?.user?.id)
                arrayUserId.push({ user: { id: value.user.id } })
            else if (value.id && !value.user && !value.userSubscribe)
                arrayUserId.push({ user: { id: value.id } })
            else if (value.userSubscribe)
                arrayUserId.push({ userSubscribe: value.userSubscribe })
        })
        this.setState({ usersSelected: arrayUserId })
    }

    handleUsersChangeRtq(_, values) {
        const arrayUserId = []
        values.forEach(value => {
            if (value?.user?.id)
                arrayUserId.push({ userRtq: { id: value.user.id } })
            else if (value.id && !value.user && !value.userSubscribe)
                arrayUserId.push({ userRtq: { id: value.id } })
            else if (value.userSubscribe)
                arrayUserId.push({ userSubscribeRtq: value.userSubscribe })
            else if (value.userRtq)
                arrayUserId.push({ userRtq: { id: value.userRtq.id } })
            else if (value.userSubscribeRtq)
                arrayUserId.push({ userSubscribeRtq: value.userSubscribeRtq })
        })
        this.setState({ usersSelectedRtq: arrayUserId })
    }

    conditionAlreadySet(option) {
        const { usersSelected } = this.state
        return usersSelected?.some(
            element =>
                (option?.user?.id && option?.user?.id === element?.user?.id) ||
                (option?.userSubscribe?.id &&
                    element?.userSubscribe?.id === option?.userSubscribe?.id),
        )
    }

    conditionAlreadySetRtq(option) {
        const { usersSelectedRtq } = this.state
        return usersSelectedRtq?.some(
            element =>
                (option?.user?.id &&
                    option?.user?.id === element?.userRtq?.id) ||
                (option?.userSubscribe?.id &&
                    element?.userSubscribeRtq?.id ===
                        option?.userSubscribe?.id),
        )
    }

    isOptionEqualToValue(option, value) {
        if (
            (value?.userSubscribe?.id &&
                value?.userSubscribe?.id === option?.userSubscribe?.id) ||
            (value?.user?.id && value?.user?.id === option?.user?.id)
        ) {
            return true
        }
        return false
    }

    _renderAutoCompleteUsers() {
        const {
            currentGroupId,
            linkedUsers,
            role,
            classes,
            subscriptions,
        } = this.props
        const { usersSelected } = this.state

        if (currentGroupId === -1) return <></>
        else if (!userActions.checkSubscriptionPage(subscriptions, 7))
            return <></>
        else {
            return (
                <BoatOnAutoComplete
                    autoComplete
                    renderTagCondition={option =>
                        !option.user && option.userSubscribe
                    }
                    getOptionSelected={this.isOptionEqualToValue}
                    helpGreyChip={this.displayText(`helpGreyChip`)}
                    addButton
                    labelAdd={this.displayText('addLabelButton')}
                    onAddButtonClicked={this.handleOpenModalNewUser}
                    margin={`normal`}
                    multiple
                    value={usersSelected}
                    conditionAlready={this.conditionAlreadySet}
                    options={linkedUsers}
                    label={
                        <>
                            {this.displayText('responsible')}
                            <Tooltip
                                title={this.displayText(`tooltipResponsible`)}
                                classes={{
                                    tooltip: classes.tooltip,
                                }}
                            >
                                <HelpIcon className={classes.helpIcon} />
                            </Tooltip>
                        </>
                    }
                    onChange={this.handleUsersChange}
                    getElementToShow={this.getUserName}
                    getName={this.getUserName}
                    filterOptions={this.filterUsers}
                    disabled={!role?.id || role.id > 3}
                />
            )
        }
    }

    _renderAutoCompleteUsersRtq() {
        const {
            currentGroupId,
            linkedUsers,
            role,
            classes,
            subscriptions,
        } = this.props
        const { usersSelectedRtq } = this.state
        if (currentGroupId === -1) return <></>
        else if (!userActions.checkSubscriptionPage(subscriptions, 7))
            return <></>
        else {
            return (
                <BoatOnAutoComplete
                    autoComplete
                    renderTagCondition={option =>
                        !option.userRtq && option.userSubscribeRtq
                    }
                    getOptionSelected={this.isOptionEqualToValue}
                    helpGreyChip={this.displayText(`helpGreyChip`)}
                    addButton
                    labelAdd={this.displayText('addLabelButton')}
                    onAddButtonClicked={this.handleOpenModalNewUserRtq}
                    margin={`normal`}
                    multiple
                    value={usersSelectedRtq}
                    conditionAlready={this.conditionAlreadySetRtq}
                    options={linkedUsers}
                    label={
                        <>
                            {this.displayText('supervisor')}
                            <Tooltip
                                title={this.displayText(`tooltipSupervisor`)}
                                classes={{
                                    tooltip: classes.tooltip,
                                }}
                            >
                                <HelpIcon className={classes.helpIcon} />
                            </Tooltip>
                        </>
                    }
                    onChange={this.handleUsersChangeRtq}
                    getElementToShow={this.getUserName}
                    getName={this.getUserNameRtq}
                    filterOptions={this.filterUsers}
                />
            )
        }
    }

    _renderProtocol() {
        const { classes } = this.props
        const { event } = this.state

        return (
            <TextField
                id="details"
                variant="outlined"
                label={
                    <>
                        {event?.delimitedDate?.endDate
                            ? this.displayText('protocol')
                            : this.displayText('protocolFollow')}
                        <Tooltip
                            title={this.displayText(`helpProtocol`)}
                            classes={{
                                tooltip: classes.tooltip,
                            }}
                        >
                            <HelpIcon className={classes.helpIcon} />
                        </Tooltip>
                    </>
                }
                margin={`normal`}
                multiline
                minRows={5}
                maxRows={5}
                value={event?.protocol || ''}
                onChange={e => {
                    this.handleChange({
                        protocol: e.target.value,
                    })
                }}
                className={classes.textField}
                InputProps={{
                    classes: {
                        input: classes.input,
                        marginDense: classes.marginInput,
                    },
                }}
                InputLabelProps={{
                    classes: {
                        root: classes.labelInput,
                    },
                    style: { pointerEvents: 'auto' },
                }}
            />
        )
    }

    _renderDetails() {
        const { classes } = this.props
        const { event } = this.state
        const cycle = getCycle({ event: event })

        return (
            <>
                {cycle?.cycleType?.id === undefined ||
                cycle?.cycleType?.id === 2 ? (
                    <div className={classes.twoTextField}>
                        {this._renderDate()}
                        {this._renderCycleHours()}
                    </div>
                ) : (
                    this._renderDate()
                )}
                {this._renderAmount()}
                {this._renderAutoCompleteUsersRtq()}
                {this._renderAutoCompleteUsers()}
                {this._renderProtocol()}
                <TextField
                    id="details"
                    variant="outlined"
                    label={
                        event?.delimitedDate?.endDate
                            ? this.displayText('conclude')
                            : this.displayText('notes')
                    }
                    margin={`normal`}
                    multiline
                    minRows={5}
                    maxRows={5}
                    value={event?.description || ''}
                    onChange={e => {
                        this.handleChange({ description: e.target.value })
                    }}
                    className={classes.textField}
                    InputProps={{
                        classes: {
                            input: classes.input,
                            marginDense: classes.marginInput,
                        },
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.labelInput,
                        },
                    }}
                />
                {this._renderDocuments()}
            </>
        )
    }

    _renderEssential() {
        const { classes, eventTypes } = this.props
        const { event, error } = this.state

        return (
            <>
                <TextField
                    id="type"
                    variant="outlined"
                    label={this.displayText('typeRepair')}
                    margin={`normal`}
                    required
                    error={
                        !(event && event.eventType && event.eventType.id) &&
                        error
                    }
                    helperText={
                        !(event && event.eventType && event.eventType.id) &&
                        error
                            ? this.displayText('errorChoiceType')
                            : ''
                    }
                    select
                    value={
                        event && event.eventType && event.eventType.id
                            ? event.eventType.id
                            : -1
                    }
                    onChange={e => {
                        this.handleChange({ eventTypeId: e.target.value })
                    }}
                    className={classes.textField}
                    InputLabelProps={{
                        classes: {
                            root: classes.labelInput,
                        },
                    }}
                >
                    {!(event && event.eventType && event.eventType.id) && (
                        <MenuItem key="type" disabled value={-1}>
                            <em> {this.displayText(`typeEv`)} </em>
                        </MenuItem>
                    )}
                    {eventTypes.map((type, index) => {
                        return (
                            <MenuItem key={`type${index}`} value={type.id}>
                                {this.displayTextApi(type.translation)}
                            </MenuItem>
                        )
                    })}
                </TextField>
                <InputLabel
                    onTagsChange={this.handleChangeCommun}
                    event={event}
                    error={error}
                    arrayFor={`CHECKUP`}
                    handleOpenModal={this.handleOpenModal}
                    margin={`normal`}
                />
            </>
        )
    }

    handleValidation() {
        this.setState({ openModal: `validEvent` })
    }

    handleUnvalidation() {
        this.setState({ openModal: `unvalidEvent` })
    }

    _valided(validedDate) {
        if (validedDate) return true
        return false
    }

    _getContextButton() {
        const { tabIndex, event } = this.state

        // Si on est en mode édition et qu'on est sur le fil d'activité
        if (event?.id && tabIndex === 2) {
            return []
        }

        let baseTab = [
            {
                disabled: this.props.loading > 0,
                label:
                    tabIndex === 0
                        ? this.displayText(`next`)
                        : event?.id
                        ? this.displayText(`update`)
                        : this.displayText(`validateTask`),
                action: tabIndex === 0 ? this.goToSecondStep : this.saveRepair,
                type: `primary`,
                blockIds: 21,
            },
        ]
        return baseTab
    }

    async unValidateEvent() {
        await this.saveRepair()
        const { event, error } = this.state
        if (!error) {
            this.props.dispatch(
                putBOBEvents(event.id, event.title, {
                    delimitedDate: {
                        ...event.delimitedDate,
                        endDate: null,
                    },
                }),
            )
        }
    }

    async validateEvent() {
        await this.saveRepair()
        const { event, error } = this.state
        if (!error) {
            let nextEvent = null
            if (event?.eventType?.id === 20) {
                const cycle = getCycle({ event: event })
                if (cycle?.cycleType?.id === 1) {
                    const startDate = new Date(event.delimitedDate.startDate)
                    const nextDate = new Date(
                        startDate.setMonth(
                            startDate.getMonth() + cycle.cycleLength || 12,
                        ),
                    )
                    nextEvent = {
                        title: event.title,
                        eventType: event.eventType,
                        checkup: event.checkup,
                        delimitedDate: { startDate: nextDate },
                        protocol: event.protocol,
                    }
                } else if (cycle?.cycleType?.id === 2) {
                    const actualDuration =
                        event.actualDuration + cycle.cycleLength
                    nextEvent = {
                        title: event.title,
                        actualDuration: actualDuration,
                        eventType: event.eventType,
                        checkup: event.checkup,
                        protocol: event.protocol,
                    }
                }
            }

            await this.props.dispatch(
                putBOBEvents(event.id, event.title, {
                    delimitedDate: {
                        ...event.delimitedDate,
                        startDate: event?.delimitedDate?.startDate
                            ? event.delimitedDate.startDate
                            : new Date(),
                        endDate: new Date(),
                    },
                }),
            )

            if (this.props.handleValidatedEvent && nextEvent)
                this.props.handleValidatedEvent(nextEvent)
        }
    }

    renderCircle(valid) {
        const { classes } = this.props

        if (valid) {
            return (
                <Tooltip
                    title={this.displayText('unvalidate')}
                    placement="right"
                    classes={{
                        tooltip: `${classes.tooltipCircle}`,
                    }}
                >
                    <IconButton
                        className={`${classes.buttonValid} ${classes.circle} ${classes.greenColor}`}
                        onClick={this.handleUnvalidation}
                        disableFocusRipple
                        disableRipple
                    >
                        <CheckCircle />
                    </IconButton>
                </Tooltip>
            )
        } else {
            return (
                <Tooltip
                    title={this.displayText('validate')}
                    placement="right"
                    classes={{
                        tooltip: `${classes.tooltipCircle} ${classes.backgroundGreenColor}`,
                    }}
                >
                    <IconButton
                        className={`${classes.buttonValid} ${classes.circle}`}
                        onClick={this.handleValidation}
                        disableFocusRipple
                        disableRipple
                    >
                        <CheckCircle />
                    </IconButton>
                </Tooltip>
            )
        }
    }

    titleContext() {
        const { title } = this.props
        const { event } = this.state
        const valid = this._valided(event?.delimitedDate?.endDate)

        if (event?.id) {
            return (
                <>
                    <Typography>{title}</Typography>
                    <BoatOnBlock
                        onlyOneId={true}
                        blockIds={[24, 25]}
                        addedCondition={id =>
                            (id === 24 && !valid) || (id === 25 && valid)
                        }
                        children={() => this.renderCircle(valid)}
                    />
                </>
            )
        }

        return title
    }

    render() {
        const {
            boat,
            title,
            activity,
            noCross = true,
            showFollowButton,
        } = this.props
        const { checkModalOpen, event } = this.state
        const boatId = boat?.id
        const valid = this._valided(event?.delimitedDate?.endDate)
        return (
            <>
                {this._renderTitle(
                    title,
                    noCross,
                    [
                        this.displayText(`essentialInfos`),
                        this.displayText(`details`),
                        event?.id ? this.displayText('activity') : undefined,
                    ],
                    {
                        buttonPersonalized: this.state.event?.id ? (
                            this.renderCircle(valid)
                        ) : (
                            <></>
                        ),
                        addedCondition: id =>
                            (id === 24 && !valid) || (id === 25 && valid),
                        onlyOneId: true,
                        blockIds: [24, 25],
                    },
                    showFollowButton,
                )}
                {this._renderBody({
                    bodies: [
                        this._renderEssential(),
                        this._renderDetails(),
                        event?.id
                            ? this._renderActivity(activity, event, 'repair')
                            : undefined,
                    ],
                })}
                {this._renderActions(this._getContextButton())}
                <BoatOnModal
                    openCondition={this.state.openModal}
                    handleClose={this.handleCloseModal}
                    modalCores={{
                        detail: (
                            <InventoryModal
                                type={
                                    this.state.eventDetail &&
                                    this.state.eventDetail.part
                                        ? `INVENTORY_PART`
                                        : `INVENTORY_EQUIPMENT`
                                }
                                modalAdd={true}
                                event={this.state.eventDetail}
                                inventories={this.props.inventories}
                                inventory={this.state.event}
                                onlyDetails
                                addNewEventDetail={this.addNewEventDetail}
                            />
                        ),
                        newUser: (
                            <NewUserModal
                                handleSend={this.handleNewUser}
                                groupId={this.props.groups?.id}
                                boats={this.props.boatsGroup}
                                boatId={boatId}
                            />
                        ),
                        validEvent: (
                            <ValidModal validatedEvent={this.validateEvent} />
                        ),
                        unvalidEvent: (
                            <UnvalidModal
                                unvalidatedEvent={this.unValidateEvent}
                            />
                        ),
                    }}
                    maxWidth={{
                        detail: 'md',
                        newUser: `xs`,
                        validEvent: `sm`,
                        unvalidEvent: `sm`,
                    }}
                    titles={{
                        detail: this._setTitle(),
                        newUser: this.displayText(`newUserTitle`),
                        validEvent: this.displayText(`validEventTitle`),
                        unvalidEvent: this.displayText(`unvalidEventTitle`),
                    }}
                />
                <BoatOnModal
                    openCondition={checkModalOpen}
                    handleClose={this.handleCloseCheckModal}
                    maxWidth={{ inventoryCheck: `sm` }}
                    modalCores={{
                        inventoryCheck: (
                            <ModalAmountItCheck
                                isConfirmedToggler={this.isConfirmedToggler}
                                save={this.saveRepair}
                                handleCloseCheckModal={
                                    this.handleCloseCheckModal
                                }
                            />
                        ),
                    }}
                    titles={{
                        inventoryCheck: `Confirmer`,
                    }}
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        boat: state.bob.boat,
        events: state.bob.events,
        checkupTypes: state.types.checkupTypes,
        newEvent: state.bob.newEvent,
        eventTypes: typesActions.getEventTypeCheckup(state.types.eventTypes),
        loading: state.bob.loading,
        currentGroupId: state.group.currentGroupId,
        linkedUsers: state.group?.groupsMembers?.linkRGU || [],
        role: state.block.selectedGroup.userRole,
        groups: state.group.groupsMembers,
        groupId: state.group.currentGroupId,
        boatsGroup: state.group.groupsMembers
            ? state.group.groupsMembers.boats
            : null,
        subscriptions: state.group?.groupsMembers?.user?.sub || [],
        activity: state.activity.activity,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(RepairModal))
