import React from 'react'
import { connect } from 'react-redux'
import dictionary from './Dictionary/MyFleetListDico'
import styles from './Styles/MyFleetCss'
import BoatOnComponent from '../../../common/BoatOnComponent'
import RepairMonogram from '../../../../images/Repairer_monogram_1200x1200.png'
import CreditCard from '../../../../images/Credit card monogram 1200x1200.png'
import Pointeur from '../../../../images/pointeurmini.png'
import { withStyles } from '@material-ui/core/styles'
import { Typography, Button, Divider, Tooltip } from '@material-ui/core'
import { fetchDataBOB } from '../../../../actions/bob.actions'
import { history } from '../../../../helpers'
import AppRoute from '../../../../constants/AppRoute'
import { API_URL } from '../../../../services/config.service'
import bellicon from '../../../../images/bellicon.png'
import moteurboaticon from '../../../../images/moteurboaticon.svg'
import { groupActions } from '../../../../actions/group.actions'
import { Button as Btn } from '../../../common/'

class MyFleet extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            boatsBobEvents: null,
        }

        this.goToBoatInfo = this.goToBoatInfo.bind(this)
    }

    componentDidMount() {
        const { groupId } = this.props
        this.props.dispatch(groupActions.getFleetInfos(groupId))
    }

    async goToBoatInfo(boat) {
        localStorage.setItem('boatIdSelect', boat.id)
        await this.props.dispatch(fetchDataBOB(boat.id))
        this.historyPush(history, AppRoute.LogBook.Informations)
    }

    renderBell({ red = false }) {
        const { classes } = this.props
        let color = null
        let text = null

        if (red) {
            color = classes.dateAlertRed
            text = this.displayText('dateReplaceDeprecated')
        } else return <></>

        return (
            <Tooltip
                className={classes.tooltip}
                title={
                    text ? (
                        <React.Fragment>
                            <Typography color="inherit">{text}</Typography>
                        </React.Fragment>
                    ) : (
                        ''
                    )
                }
            >
                <Button className={classes.bellBtn}>
                    <div className={`${color} ${classes.dateAlert}`}>
                        <img
                            src={bellicon}
                            alt="bellicon"
                            className={classes.bellIcon}
                        ></img>
                    </div>
                </Button>
            </Tooltip>
        )
    }

    renderBoat(boat, index) {
        const { classes } = this.props
        const lateCheckup = boat.lateCheckup || 0
        const threeMonthCheckup = boat.threeMonthsCheckup || 0
        const earned = boat.earnThisYear || 0
        const spent = boat.spentThisYear || 0
        return (
            <div className={classes.bloc} key={index}>
                <div className={classes.buttonsContainer}>
                    <Btn
                        label={this.displayText('details')}
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            this.goToBoatInfo(boat)
                        }}
                    />
                    {lateCheckup > 0 && this.renderBell({ red: true })}
                </div>
                <div className={classes.imageHead}>
                    <img
                        src={
                            (boat.files &&
                                boat.files.length >= 1 &&
                                boat.files[boat.files.length - 1] &&
                                boat.files[boat.files.length - 1].link &&
                                API_URL +
                                    '/files/' +
                                    boat.files[boat.files.length - 1].link) ||
                            moteurboaticon
                        }
                        alt={'boat'}
                        className={classes.image}
                    />
                </div>
                <div className={classes.title}>{boat.name}</div>
                <Divider style={{ marginBottom: '15px', marginTop: '0px' }} />
                <div className={classes.infos}>
                    <div className={classes.line}>
                        <div className={classes.iconDiv}>
                            <img
                                className={classes.icon}
                                src={RepairMonogram}
                                alt="RepairMonogram"
                            ></img>
                        </div>
                        <div className={classes.infosBlock}>
                            <div className={classes.texts40}>
                                <Typography className={classes.description}>
                                    {lateCheckup}{' '}
                                </Typography>
                                <Typography className={classes.helpText}>
                                    {lateCheckup === 0
                                        ? this.displayText('overdue')
                                        : this.displayText('overduePl')}
                                </Typography>
                            </div>
                            <div className={classes.texts60}>
                                <Typography className={classes.description}>
                                    {threeMonthCheckup}{' '}
                                </Typography>
                                <Typography className={classes.helpText}>
                                    {threeMonthCheckup === 0
                                        ? this.displayText('maintenance')
                                        : this.displayText('maintenancePl')}
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div className={classes.line}>
                        <div className={classes.iconDiv}>
                            <img
                                className={classes.icon}
                                src={CreditCard}
                                alt="CreditCard"
                            ></img>
                        </div>
                        <div className={classes.infosBlock}>
                            <div className={classes.texts40}>
                                <Typography className={classes.description}>
                                    {'+ '}
                                    {earned.toFixed(2).replace(/[.,]00$/, '')}
                                    {' €'}
                                </Typography>
                                <Typography className={classes.helpText}>
                                    {earned === 0
                                        ? this.displayText('earnedIn')
                                        : this.displayText('earnedInPl')}{' '}
                                    {new Date().getFullYear()}
                                </Typography>
                            </div>
                            <div className={classes.texts60}>
                                <Typography className={classes.description}>
                                    {'- '}
                                    {spent.toFixed(2).replace(/[.,]00$/, '')}
                                    {' €'}
                                </Typography>
                                <Typography className={classes.helpText}>
                                    {spent === 0
                                        ? this.displayText('spentOn')
                                        : this.displayText('spentOnPl')}{' '}
                                    {new Date().getFullYear()}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.line}>
                    <div className={classes.iconDiv}>
                        <img
                            src={Pointeur}
                            alt="Pointeur"
                            className={classes.icon}
                        ></img>
                    </div>
                    <div className={classes.infosBlock}>
                        <div className={classes.texts40}>
                            <Typography className={classes.description}>
                                {(boat.address && boat.address.city) ||
                                (boat.address && boat.address.fullAddress) ? (
                                    boat.address.city ||
                                    boat.address.fullAddress
                                ) : (
                                    <i>{this.displayText('notSet')}</i>
                                )}
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { classes, loading, fleetInfos } = this.props
        const boats = fleetInfos?.boats
        const isMobile = window.innerWidth < 600

        if (!boats || loading > 0)
            return this.renderLoading(`100px`, `100px`, true)

        return (
            <div className={classes.root}>
                {isMobile && (
                    <Typography className={classes.mobileTitle}>
                        {this.displayText('myFleet')}
                    </Typography>
                )}
                <div className={classes.blocs}>
                    {boats.map((boat, index) => this.renderBoat(boat, index))}
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        groupId: state.group.currentGroupId,
        bob: state.bob,
        fleetInfos: state.group.fleetInfos,
        loading: state.group.loading,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(MyFleet))
