import React from 'react'
import { withStyles } from '@material-ui/styles'
import styles from './Styles/RepairCategoryCss'
import dictionary from './Dictionary/RepairCategoryDico'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import eyeicon from '../../../../images/eyeicon.png'
import BobFilter from '../BobFilter'
import BoatOnComponent from '../../../common/BoatOnComponent'
import HourAlert from '../HourAlert'

const maxNumberOfShowedRepairs = 8

class RepairCategory extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            seeMore: false,
            unfoldAll: [],
        }

        this._renderTabByEquipment = this._renderTabByEquipment.bind(this)
        this._renderTabByTime = this._renderTabByTime.bind(this)
        this._showMore = this._showMore.bind(this)
        this.handleUnfoldAll = this.handleUnfoldAll.bind(this)
        this.expandCategory = this.expandCategory.bind(this)
        this.isExpanded = this.isExpanded.bind(this)
    }

    handleUnfoldAll() {
        let repairs = []
        if (this.props.repairEvents.length !== this.state.unfoldAll.length) {
            for (let repair of this.props.repairEvents) {
                repairs.push(repair.id)
            }
        }
        this.setState({ unfoldAll: repairs })
    }

    expandCategory(categ) {
        if (this.state.unfoldAll.includes(categ[0].id)) {
            const res = this.state.unfoldAll.filter(
                id => undefined === categ.find(elem => elem.id === id),
            )
            this.setState({ unfoldAll: res })
        } else {
            this.setState({
                unfoldAll: [
                    ...this.state.unfoldAll,
                    ...categ.map(elem => elem.id),
                ],
            })
        }
    }

    isExpanded(categ) {
        let categId = categ.map(elem => elem.id)
        return (
            undefined !==
            this.state.unfoldAll.find(elem => categId.includes(elem))
        )
    }

    _renderTabByEquipment() {
        const {
            classes,
            repairEvents,
            renderLine,
            isRepairByHours,
            checkedBoxes,
            newLastRepair,
            newNextRepair,
        } = this.props

        let typeEquip = []
        let displaySeeMore = []
        repairEvents.forEach(e => {
            const res =
                e?.checkup?.parts[0]?.equipment?.equipmentFamily?.type ??
                e.checkup?.equipments[0]?.equipmentFamily?.type

            const keyMemo =
                res?.translation?.[this.getContext()] ||
                e?.checkup?.equipments[0]?.equipmentFamily?.userEquipmentFamily
                    ?.name ||
                e?.checkup?.parts[0]?.equipment?.equipmentFamily
                    ?.userEquipmentFamily?.name

            if (typeEquip[keyMemo]) {
                if (!this.state['showMore' + keyMemo]) {
                    if (
                        typeEquip[keyMemo][0].length <
                        maxNumberOfShowedRepairs / 2
                    )
                        typeEquip[keyMemo][0].unshift(e)
                    else displaySeeMore[keyMemo] = true
                } else {
                    typeEquip[keyMemo][0].unshift(e)
                }
            } else {
                typeEquip[keyMemo] = [[e], res]
            }
        })
        return (
            <>
                <div className={classes.secondLine}>
                    <BobFilter
                        fieldName={'events'}
                        editableButton
                        editableButtonBlockIds={22}
                        editableButtonLabel={this.displayText('addRepair')}
                        editableButtonDropDown={[
                            {
                                label: this.displayText(`repairDone`),
                                action: newLastRepair,
                            },
                            {
                                label: this.displayText(`repairToDo`),
                                action: newNextRepair,
                            },
                        ]}
                        activeButton={{
                            rangeDate: true,
                            checkupType: true,
                            equipmentFamily: true,
                            rangeAmount: true,
                            rangeHour: true,
                            equipment: true,
                            userAttachedRole: true,
                            managerSearch: true,
                        }}
                        unfoldAll={this.state.unfoldAll}
                        handleUnfoldAll={this.handleUnfoldAll}
                        repair
                        repairUnfoldChecked={
                            this.state.unfoldAll.length ===
                            this.props.repairEvents.length
                        }
                        selectAll={this.props.selectAll}
                        checkedBoxes={checkedBoxes}
                        nbEvent={this.props.repairEvents.length}
                        nbCheckedEvents={this.props.nbCheckedEvents}
                    />
                </div>
                <HourAlert />
                {repairEvents.length === 0 && (
                    <Typography>{this.displayText('recorded')}</Typography>
                )}
                {Object.entries(typeEquip).map(e => (
                    <Accordion
                        key={`Accordion-${e[0]}`}
                        classes={{
                            root: classes.extension,
                        }}
                        expanded={this.isExpanded(e[1][0])}
                        square
                    >
                        <AccordionSummary
                            className={classes.PanelSummary}
                            onClick={() => this.expandCategory(e[1][0])}
                            expandIcon={
                                <ExpandMoreIcon
                                    style={{
                                        width: '48px',
                                    }}
                                    color="primary"
                                />
                            }
                            style={{
                                height: '41px',
                                minHeight: '41px',
                            }}
                            classes={{
                                root: classes.backgroundBlue,
                                content: classes.lineAccordeon,
                                expandIcon: classes.expandButton,
                                expanded: classes.expandedFixRadius,
                            }}
                        >
                            <img
                                src={
                                    e[1][1]?.image
                                        ? require(`images/${e[1][1]?.image}`)
                                        : eyeicon
                                }
                                alt="bellicon"
                                className={classes.catIconTop}
                            />
                            <Typography className={classes.categorie}>
                                {e[0]}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            className={classes.container}
                            children={
                                <>
                                    {e[1][0].map((repair, index) =>
                                        renderLine(
                                            repair,
                                            index,
                                            repairEvents,
                                            isRepairByHours(repair),
                                        ),
                                    )}
                                    {!this.state['showMore' + e[0]] &&
                                        displaySeeMore[e[0]] && (
                                            <div
                                                className={
                                                    classes.showMoreContainer
                                                }
                                                style={{ marginTop: 20 }}
                                            >
                                                <Typography
                                                    variant="subtitle"
                                                    onClick={() =>
                                                        this.setState({
                                                            ['showMore' +
                                                            e[0]]: true,
                                                        })
                                                    }
                                                    className={classes.showMore}
                                                >
                                                    {this.displayText(
                                                        'seeMore',
                                                    )}
                                                </Typography>
                                            </div>
                                        )}
                                </>
                            }
                        />
                    </Accordion>
                ))}{' '}
            </>
        )
    }

    _renderTabByTime() {
        const {
            classes,
            repairEvents,
            renderLine,
            isRepairByHours,
            checkedBoxes,
            newLastRepair,
            newNextRepair,
        } = this.props

        let perHours = [],
            perDates = []

        repairEvents.map(e => {
            if (isRepairByHours(e)) perHours.unshift(e)
            else perDates.unshift(e)
            return e
        })

        const reduced =
            perDates.length > maxNumberOfShowedRepairs / 2 ||
            perHours.length > maxNumberOfShowedRepairs / 2
        if (!this.state.seeMore && reduced) {
            perHours = perHours.slice(0, maxNumberOfShowedRepairs / 2)
            perDates = perDates.slice(0, maxNumberOfShowedRepairs / 2)
        }

        return (
            <>
                <div className={classes.secondLine}>
                    <BobFilter
                        fieldName={'events'}
                        editableButton
                        editableButtonBlockIds={22}
                        editableButtonLabel={this.displayText('addRepair')}
                        editableButtonDropDown={[
                            {
                                label: this.displayText(`repairDone`),
                                action: newLastRepair,
                            },
                            {
                                label: this.displayText(`repairToDo`),
                                action: newNextRepair,
                            },
                        ]}
                        activeButton={{
                            rangeDate: true,
                            checkupType: true,
                            equipmentFamily: true,
                            rangeAmount: true,
                            rangeHour: true,
                            equipment: true,
                            userAttachedRole: true,
                            managerSearch: true,
                        }}
                        repair
                        checkedBoxes={checkedBoxes}
                        selectAll={this.props.selectAll}
                        nbEvent={this.props.repairEvents.length}
                        nbCheckedEvents={this.props.nbCheckedEvents}
                    />
                </div>
                <HourAlert />
                {/*
                    LISTE DES ENTRETIENS
                */}
                <div>
                    <p style={{ fontWeight: 600, marginTop: 0 }}>
                        {this.displayText('dates')}
                    </p>
                    {repairEvents.length === 0 ? (
                        <Typography>{this.displayText('recorded')}</Typography>
                    ) : (
                        <>
                            {perDates.map((repair, index) =>
                                renderLine(repair, index, repairEvents),
                            )}
                            <div>
                                <br />
                                <p style={{ fontWeight: 600 }}>
                                    {this.displayText('hours')}
                                </p>
                            </div>
                            {perHours.map((repair, index) =>
                                renderLine(repair, index, repairEvents, true),
                            )}
                        </>
                    )}
                </div>
                {!this.state.seeMore && reduced && (
                    <div className={classes.showMoreContainer}>
                        <Typography
                            // variant="subtitle"
                            onClick={this._showMore}
                            className={classes.showMore}
                        >
                            {this.displayText('seeMore')}
                        </Typography>
                    </div>
                )}
            </>
        )
    }

    _showMore() {
        this.setState({ seeMore: true })
    }

    render() {
        const { perEquipment, classes } = this.props

        return (
            <div className={classes.mainContainer}>
                {perEquipment
                    ? this._renderTabByEquipment()
                    : this._renderTabByTime()}
            </div>
        )
    }
}

export default withStyles(styles)(RepairCategory)
