import BoatOnComponent from '../BoatOnComponent'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { DateRange, Calendar } from 'react-date-range'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import {
    isToday,
    format,
    isWeekend,
    isWithinInterval,
    isSameDay,
    isSunday,
    isLastDayOfMonth,
    isMonday,
} from 'date-fns'
import fr from 'date-fns/locale/fr'
import enGB from 'date-fns/locale/en-GB'
import styles from './Styles/BoatOnDateSelectorCss'
import { Popover, TextField } from '@material-ui/core'
import LocaleFr from 'date-fns/locale/fr'
import dictionnary from './Dictionary/BoatOnDateSelectorDico'
import ButtonFilterPopOverDateRange from '../../ParkingSearchPage/ButtonFilterPopOverDateRange'

class BoatOnDateSelector extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionnary
        this.state = {
            month: window.innerWidth < 420 ? 2 : 1,
            isPopOver: props.isPopOver ?? false,
            value: props.value ? new Date(props.value) : new Date(),
            startDate: props?.startDate || null,
            endDate: props?.endDate || null,
            key: 'selection',
            clickCounter: 0,
        }

        this.handleRangeSelect = this.handleRangeSelect.bind(this)
        this.handleDateSelect = this.handleDateSelect.bind(this)
        this.customDayContent = this.customDayContent.bind(this)
        this.reset = this.reset.bind(this)
        this.resize = this.resize.bind(this)
        this.switchPopOver = this.switchPopOver.bind(this)
    }

    componentDidMount() {
        if (this.props.startDate)
            this.setState({ startDate: this.props.startDate })
        if (this.props.endDate) this.setState({ endDate: this.props.endDate })
    }

    componentDidUpdate() {
        if (this.props.reset) {
            this.setState({ startDate: null, endDate: null })
        }
    }

    handleDateSelect(dateSelected) {
        const { onChange } = this.props

        this.setState(
            {
                value: new Date(dateSelected).toISOString(),
                isPopOver: false,
            },
            () =>
                onChange
                    ? onChange(new Date(dateSelected).toISOString())
                    : null,
        )
    }

    handleRangeSelect(dateRangeSelected) {
        const { startDate, endDate } = JSON.parse(
            JSON.stringify(dateRangeSelected?.selection),
        )

        let start = startDate
        let end = endDate

        if (start && end && new Date(start) > new Date(end)) {
            let tmp = start

            start = end
            end = tmp
        }

        this.setState(
            {
                startDate: new Date(start),
                endDate: new Date(end),
                clickCounter: this.state.clickCounter + 1,
            },
            () => {
                if (this.state.clickCounter === 2) {
                    this.setState({
                        isPopOver: false,
                        clickCounter: 0,
                    })

                    if (this.props.onDateChange)
                        this.props.onDateChange(
                            this.state.startDate,
                            this.state.endDate,
                        )
                }
            },
        )
    }

    switchPopOver() {
        this.setState(
            {
                isPopOver: !this.state.isPopOver,
            },
            () => {
                if (this.props.handlePopOver)
                    this.props.handlePopOver(this.state.isPopOver)
            },
        )
    }

    customDayContent(day) {
        const { classes } = this.props
        const start = this.state.startDate
            ? new Date(this.state.startDate)
            : new Date()
        const end = this.state.endDate
            ? new Date(this.state.endDate)
            : new Date()

        const resultIsWithinInterval = isWithinInterval(new Date(day), {
            start: start,
            end: end,
        })

        let formattedDate = <span>{format(day, 'd')}</span>

        if (isSameDay(new Date(day), start)) {
            formattedDate = (
                <div className={classes.startDateCustom}>
                    {format(day, 'd')}
                </div>
            )
        } else if (isSameDay(new Date(day), end)) {
            formattedDate = (
                <div className={classes.endDateCustom}>{format(day, 'd')}</div>
            )
        } else if (isToday(day)) {
            formattedDate = (
                <div
                    className={
                        resultIsWithinInterval
                            ? classes.todayWithinIntervalCustom
                            : classes.todayCustom
                    }
                >
                    {format(day, 'd')}
                </div>
            )
        } else if (isWeekend(day)) {
            formattedDate = (
                <div className={classes.weekEndCustom}>{format(day, 'd')}</div>
            )
        } else if (resultIsWithinInterval) {
            formattedDate = (
                <div className={classes.inRangeCustom}>{format(day, 'd')}</div>
            )
        }

        return (
            <span>
                <span>
                    {formattedDate}{' '}
                    {!isSameDay(start, end) &&
                    resultIsWithinInterval &&
                    isSameDay(new Date(day), start) &&
                    !isSunday(new Date(day)) &&
                    !isLastDayOfMonth(new Date(day)) ? (
                        <div className={classes.startDateFilledCustom} />
                    ) : !isSameDay(start, end) &&
                      resultIsWithinInterval &&
                      isSameDay(new Date(day), end) &&
                      !isMonday(new Date(day)) &&
                      new Date(day).getDate() !== 1 ? (
                        <div className={classes.endDateFilledCustom} />
                    ) : (
                        ''
                    )}
                </span>
            </span>
        )
    }

    resize(e) {
        e = e.target.innerWidth < 420 ? 2 : 1
        this.setState({ month: e })
    }

    reset() {
        this.setState({
            value: new Date(),
        })
        this.setState({
            startDate: null,
            endDate: null,
        })

        if (this.props.onChange) {
            this.props.onChange(null)
        }
        if (this.props.onDateChange) {
            this.props.onDateChange(null, null)
        }
    }

    _renderInputField() {
        const {
            classes,

            isDateRange,
            isCompact,

            id,
            idStartInput,
            idEndInput,
            required,
            startRequired,
            endRequired,
            disabled,
            margin,
            variant = 'outlined',
            autoComplete = 'off',
            label,
            value,
            error,
            errorStart,
            errorEnd,
            helperText,
            inputProps,
            inputPropsStart,
            inputPropsEnd,
        } = this.props

        const { startDate, endDate } = this.state

        if (isDateRange && isCompact) {
            return (
                <div className={classes.pageRightAlign}>
                    <TextField
                        className={classes.startDate}
                        id={idStartInput}
                        required={startRequired}
                        disabled={disabled}
                        margin={margin ? 'normal' : 'dense'}
                        variant={variant}
                        autoComplete={autoComplete}
                        label={this.displayText('startDate')}
                        onClick={this.switchPopOver}
                        value={
                            startDate
                                ? new Date(startDate).toLocaleDateString(
                                      this.getLanguage(this.context),
                                  )
                                : ''
                        }
                        error={error || errorStart}
                        helperText={
                            error || errorStart
                                ? helperText || this.displayText('errorDate')
                                : ''
                        }
                        {...inputProps}
                        {...inputPropsStart}
                    />

                    <TextField
                        className={classes.endDate}
                        id={idEndInput}
                        required={endRequired}
                        disabled={disabled}
                        margin={margin ? 'normal' : 'dense'}
                        variant={variant}
                        autoComplete={autoComplete}
                        label={this.displayText('endDate')}
                        onClick={this.switchPopOver}
                        value={
                            endDate
                                ? new Date(endDate).toLocaleDateString(
                                      this.getLanguage(this.context),
                                  )
                                : ''
                        }
                        error={error || errorEnd}
                        helperText={
                            error || errorEnd
                                ? helperText || this.displayText('errorDate')
                                : ''
                        }
                        {...inputProps}
                        {...inputPropsEnd}
                    />
                </div>
            )
        }

        return (
            <TextField
                className={classes.textField}
                id={id}
                required={required}
                disabled={disabled}
                margin={margin ? 'normal' : 'dense'}
                variant={variant}
                autoComplete={autoComplete}
                label={label}
                onClick={this.switchPopOver}
                value={
                    value
                        ? new Date(value).toLocaleDateString(
                              this.getLanguage(this.context),
                          )
                        : this.displayText('dateEmpty')
                }
                error={error}
                helperText={
                    error ? helperText || this.displayText('errorDate') : ''
                }
                InputLabelProps={{
                    shrink: true,
                    classes: {
                        root: classes.labelInput,
                    },
                }}
                {...inputProps}
            />
        )
    }

    _renderDateRangeModal() {
        const { classes } = this.props

        const { month, startDate, endDate, key } = this.state

        return (
            <DateRange
                className={classes.inRange}
                weekStartsOn={1}
                months={month}
                displayMode="dateRange"
                moveRangeOnFirstSelection={false}
                onChange={this.handleRangeSelect}
                retainEndDateOnFirstSelection={false}
                ranges={
                    !startDate && !endDate
                        ? [
                              {
                                  key: 'selection',
                                  startDate: new Date(),
                                  endDate: new Date(),
                              },
                          ]
                        : [
                              {
                                  key: key,
                                  startDate: new Date(startDate),
                                  endDate: new Date(endDate),
                              },
                          ]
                }
                locale={this.context === 'fr' ? fr : enGB}
                rangeColors={['#D5EFFF']}
                showDateDisplay={false}
                dayContentRenderer={this.customDayContent}
                dateDisplayFormat={'P'}
                classNames={{
                    startEdge: classes.startEdge,
                    endEdge: classes.endEdge,
                    monthAndYearPickers: classes.monthAndYearPickers,
                    calendarWrapper: classes.calendarWrapper,
                    dayPassive: classes.dayPassive,
                    dayNumber: classes.dayNumber,
                    weekDays: classes.weekDays,
                    inRange: classes.inRange,
                    month: classes.month,
                    days: classes.days,
                    weekDay: classes.weekDay,
                    dayToday: classes.dayToday,
                    dayEndPreview: classes.dayEndPreview,
                    dayInPreview: classes.dayInPreview,
                    dayStartPreview: classes.dayStartPreview,
                }}
            />
        )
    }

    _renderCompactDateRange() {
        const { classes } = this.props
        const { isPopOver } = this.state

        return (
            <>
                {this._renderInputField()}
                <Popover
                    open={isPopOver}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    classes={{
                        paper: classes.paper,
                        root: classes.rootPaper,
                    }}
                    style={{ left: '0px' }}
                    anchorEl={document.getElementById('root')}
                >
                    <ButtonFilterPopOverDateRange
                        reset={this.reset}
                        save={this.switchPopOver}
                        body={this._renderDateRangeModal()}
                    />
                </Popover>
            </>
        )
    }

    _renderDateRange() {
        const {
            classes,
            id,
            disabledDay,
            disabledDates,
            inputProps,
            isCompact,
            calendarProps,
        } = this.props

        const { month, startDate, endDate, key } = this.state

        if (isCompact) return this._renderCompactDateRange()

        return (
            <DateRange
                id={id}
                className={classes.inRange}
                disabledDay={disabledDay}
                disabledDates={disabledDates}
                weekStartsOn={1}
                months={month}
                displayMode="dateRange"
                moveRangeOnFirstSelection={false}
                retainEndDateOnFirstSelection={false}
                onChange={this.handleRangeSelect}
                ranges={
                    !startDate && !endDate
                        ? [
                              {
                                  key: 'selection',
                                  startDate: new Date(),
                                  endDate: new Date(),
                              },
                          ]
                        : [
                              {
                                  key: key,
                                  startDate: new Date(startDate),
                                  endDate: new Date(endDate),
                              },
                          ]
                }
                locale={this.context === 'fr' ? fr : enGB}
                rangeColors={['#D5EFFF']}
                showDateDisplay={false}
                dayContentRenderer={this.customDayContent}
                renderInput={params => (
                    <TextField {...params} {...inputProps} />
                )}
                classNames={{
                    startEdge: classes.startEdge,
                    endEdge: classes.endEdge,
                    monthAndYearPickers: classes.monthAndYearPickers,
                    calendarWrapper: classes.calendarWrapper,
                    dayPassive: classes.dayPassive,
                    dayNumber: classes.dayNumber,
                    weekDays: classes.weekDays,
                    inRange: classes.inRange,
                    month: classes.month,
                    days: classes.days,
                    weekDay: classes.weekDay,
                    dayToday: classes.dayToday,
                    dayEndPreview: classes.dayEndPreview,
                    dayInPreview: classes.dayInPreview,
                    dayStartPreview: classes.dayStartPreview,
                }}
                {...calendarProps}
            />
        )
    }

    _renderCalendarModal() {
        const {
            classes,
            isDateCommissioning,
            isDatePurchase,
            minDate,
            maxDate,
            value,
            calendarProps,
            disabledDay,
        } = this.props

        const { month } = this.state

        const minDateFormated = minDate ? new Date(minDate) : new Date(null)
        const maxDateFormated = maxDate ? new Date(maxDate) : undefined

        let date =
            minDateFormated ||
            new Date().toLocaleDateString(this.getLanguage(this.context))

        if (value || isDateCommissioning || new Date(value) >= minDateFormated)
            date = value

        return (
            <Calendar
                minDate={
                    isDateCommissioning || isDatePurchase
                        ? undefined
                        : minDateFormated
                }
                maxDate={maxDateFormated}
                date={date}
                months={month}
                onChange={this.handleDateSelect}
                displayMode="date"
                locale={this.context === 'fr' ? LocaleFr : enGB}
                color="#3040a0"
                showDateDisplay={false}
                disabledDay={disabledDay}
                classNames={{
                    monthAndYearWrapper: classes.monthAndYearWrapper,
                    monthAndYearPickers: classes.monthAndYearPickers,
                    calendarWrapper: classes.calendarWrapper,
                    dayPassive: classes.dayPassive,
                    dayNumber: classes.dayNumber,
                    weekDays: classes.weekDays,
                    month: classes.month,
                    days: classes.days,
                    weekDay: classes.weekDay,
                    dayToday: classes.dayToday,
                    selected: classes.selected,
                    dayHovered: classes.dayHovered,
                    dayDisabled: classes.dayDisabled,
                }}
                className={classes.dayStartPreview}
                {...calendarProps}
            />
        )
    }

    _renderDateDisplay() {
        const { classes } = this.props

        const { isPopOver } = this.state

        return (
            <>
                {this._renderInputField()}
                <Popover
                    open={isPopOver}
                    onClose={this.switchPopOver}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    classes={{
                        paper: classes.paper,
                        root: classes.rootPaper,
                    }}
                    style={{ left: '0px' }}
                    anchorEl={document.getElementById('root')}
                >
                    <ButtonFilterPopOverDateRange
                        reset={this.reset}
                        save={this.switchPopOver}
                        body={this._renderCalendarModal()}
                    ></ButtonFilterPopOverDateRange>
                </Popover>
            </>
        )
    }

    render() {
        const { isDateRange } = this.props

        window.onresize = this.resize

        if (isDateRange) return this._renderDateRange()
        return this._renderDateDisplay()
    }
}

/**
 * Input field to puck up date, dates ranges.
 *
 * @class BoatOnDateSelector
 * @extends {BoatOnComponent}
 *
 * @region ```
 * Globals Params
 * ```
 *
 * @param {Boolean} disabled - disable the input fields.
 * @param {Boolean} required - if true : add * on input field label.
 * @param {Boolean} margin - if true : set input field margin to 'normal' else if false : set margin to 'dense'.
 * @param {String} [variant = 'outlined'] - varient applied to input field.
 * @param {String} [autoComplete = 'off'] - if true : active autocomplete on input field.
 * @param {Boolean} error - if true put field in error.
 * @param {String} helperText - text to add under input field.
 * @param {Object} inputProps - overrides default values of InputTextFields component.
 * @param {Object} calendarProps - In case of Calendar display : overrides default values of Calendar component.
 * @param {Boolean} isPopOver - control if the calendar is open or not.
 * @param {HandlePopOver} handlePopOver - called each time isPopOver changed.
 * @param {DisabledDay} disabledDay - predicate function that disable day if return true
 *
 *
 * @region ```
 * Single Date Params
 * ```
 *
 * @param {String} id - id set on the input field
 * @param {Boolean} isDateCommissioning - true if is commissioning date
 * @param {Boolean} isDatePurchase - true if is date purchase
 * @param {Date} minDate - minimal date authorized
 * @param {Date} maxDate - maximal date authorized
 * @param {String} label - label of the input field
 * @param {Date} value - date of the current value
 * @param {OnChange} onChange - called each time value change
 *
 * @region ```
 * Date Range Params
 * ```
 *
 * @param {Boolean} isDateRange - input calendar for date range
 * @param {Boolean} isCompact - if true, date range will be compacted
 * @param {OnDateChange} onDateChange - call after each change : function(Date: startDate, Date: endDate)
 * @param {Date} startDate - value of startDate
 * @param {Date} endDate - value of endDate
 * @param {String} idStartInput - id set on the startDate input field
 * @param {String} idEndInput - id set on the endDate input field
 * @param {Boolean} startRequired - if true : add * on startDate input field label
 * @param {Boolean} endRequired - if true : add * on endDate input field label
 * @param {Boolean} errorStart - if true put startDate input field in error
 * @param {Boolean} errorEnd - if true put endDate input field in error
 * @param {Object} inputPropsStart - overrides default values of startDate input Field component
 * @param {Object} inputPropsEnd - overrides default values of endDate input Field component
 * @param {Date[]} disabledDates - dates that are disabled
 *
 *
 * @region ```
 * Callbacks
 * ```
 *
 * @callback HandlePopOver
 * @param {Boolean} value - new value of popover
 *
 * @callback OnChange
 * @param {Date} value - new value of input
 *
 * @callback OnDateChange
 * @param {Date} startDate - new value of startDate input
 * @param {Date} endDate - new value of endDate input
 *
 * @callback DisabledDay
 * @param {Date} - value of the day to test
 *
 * @region ```
 * Documentation infos
 * ```
 *
 * @date 15/04/2024 - 15:18
 * @author Samuel.C
 */
export default withStyles(styles)(BoatOnDateSelector)
