import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import BoatOnComponent from '../../common/BoatOnComponent'

import styles from './Styles/CaracteristicsTabCss'
import dictionary from './Dictionary/CaracteristicsTabDico'

import SearchBar from '../../common/UsefullComponents/SearchBarV2'

import { addressActions } from '../../../actions/address.actions'

class AddressSearch extends BoatOnComponent {
    constructor(props) {
        super(props)

        this.dictionary = dictionary
        this.handleSearchChange = this.handleSearchChange.bind(this)
        this.renderAutocomplete = this.renderAutocomplete.bind(this)

        this.state = {
            value: null,
            selected: null,
        }
    }

    componentDidMount() {
        const { fullAddressInfo } = this.props

        if (fullAddressInfo)
            this.setState({
                value: fullAddressInfo,
                selected: fullAddressInfo,
            })
    }

    componentDidUpdate() {
        const { selected } = this.state
        const { fullAddressInfo } = this.props

        if (
            fullAddressInfo &&
            fullAddressInfo?.fullText !== selected?.fullText
        ) {
            this.setState({
                value: fullAddressInfo,
                selected: fullAddressInfo,
            })
        }
    }

    render() {
        const {
            classes,
            style,
            notOutlined,
            fullWidth,
            labelHide,
            placeholder,
            label,
            margin,
            InputProps,
            required,
            helperText,
            error,
            multiline,
            disabled,
            type,
            city,
        } = this.props
        return (
            <div className={classes.addressGroup} style={style}>
                {
                    <div className={classes.addressField}>
                        <SearchBar
                            city={city}
                            disabled={disabled}
                            multiline={multiline}
                            error={error}
                            helperText={helperText}
                            value={this.state.value}
                            selected={this.state.selected}
                            loading={false}
                            label={labelHide ? placeholder : label}
                            labelHide={labelHide}
                            onChange={this.handleSearchChange}
                            autocomplete={this.autocomplete}
                            renderAutocomplete={this.renderAutocomplete}
                            className={classes.field}
                            notOutlined={notOutlined}
                            fullWidth={fullWidth}
                            placeholder={placeholder}
                            margin={margin}
                            InputProps={InputProps}
                            required={required}
                            type={type}
                        />
                    </div>
                }
            </div>
        )
    }

    handleSearchChange(value) {
        this.setState({
            value: value,
        })
        if (this.props.onChange) {
            this.props.onChange()
        }
    }

    autocomplete(value) {
        if (value.length >= 4) {
            this.props.dispatch(addressActions.requestAddressData(value))
        }
    }

    renderAutocomplete(results, classes) {
        const { value, selected } = this.state

        if (results && value !== selected) {
            return (
                <ul
                    className={classes.autoCompletion}
                    style={{ listStyle: 'none' }}
                >
                    {results.length > 0 ? (
                        results
                            .filter(
                                address => address?.display_name !== undefined,
                            )
                            .map((address, index) => {
                                return (
                                    <li
                                        className={classes.field}
                                        key={index}
                                        onClick={() => {
                                            this.select(address)
                                        }}
                                    >
                                        {address.display_name}
                                    </li>
                                )
                            })
                    ) : (
                        <li
                            className={classes.field}
                            onClick={() => {
                                this.create(value)
                            }}
                        >
                            {
                                <>
                                    Ajouter cette adresse:{' '}
                                    <span
                                        style={{
                                            fontSize: '0.8em',
                                            fontWeight: 200,
                                        }}
                                    >
                                        {value}
                                    </span>
                                </>
                            }
                        </li>
                    )}
                </ul>
            )
        }
        return <div />
    }

    select(element) {
        const value = {
            fullText: element.display_name,
            latitude: element.lat,
            longitude: element.lon,
        }
        this.setState({
            value: value,
            selected: value,
        })
        this.props.setAddress(value)
    }

    create(query) {
        const value = {
            fullText: query,
            latitude: -1,
            longitude: -1,
        }
        this.setState({
            value: value,
            selected: value,
        })
        this.props.setAddress(value)
    }
}

/**
 * display input address field based on Textfield from MUI
 *
 * @class AddressSearch
 * @extends {BoatOnComponent}
 *
 * @region ```
 * Globals Params
 * ```
 *
 * @param {Object} style - object of style.
 * @param {Boolean} notOutlined - if true, remove outline style.
 * @param {Boolean} fullWidth - if true, the field will take all space available.
 * @param {Boolean} fullWidth - if true, the field will take all space available.
 * @param {Boolean} labelHide - if true, hide the label.
 * @param {String} placeholder - placeholder of the input.
 * @param {String} label - label of the input.
 * @param {String} [margin='dense'] - type of the margin (cf MUI docs).
 * @param {Object} InputProps - check MUI documentation.
 * @param {Boolean} required - if true, add * on the label of the field.
 * @param {String} helperText - text to display on hover ? icon.
 * @param {Boolean} error - if true, put field in error style.
 * @param {Boolean} multiline - if true, authorize multiline.
 * @param {Boolean} disabled - if true, disable the input field.
 * @param {String} type - type of the search : ["city, address"] default address.
 *
 * @region ```
 * Documentation infos
 * ```
 *
 * @date 23/04/2024 - 17:01
 * @author Samuel.C
 */
export default withStyles(styles)(AddressSearch)
