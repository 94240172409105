import { combineReducers } from 'redux'

import authentication from './authentication.reducer'
import alert from './alert.reducer'
import filter from './filter.reducer'
import fetch from './fetch.reducer'
import address from './address.reducer'
import post from './post.reducer'
import bob from './bob.reducer'
import types from './types.reducer'
import boat from './boat.reducer'
import block from './block.reducer'
import group from './group.reducer'
import activity from './activity.reducer'
import comments from './comments.reducer'
import allParkings from './parkings/allParkings.reducer'
import parking from './parkings/parking.reducer'
import inqueries from './inqueries/inqueries.reducer'
import inquery from './inqueries/inquery.reducer'
import companies from './companies/companies.reducer'
import download from './download.reducer'
import absence from './absence.reducer'
import chrono from './chrono.reducer'
import settings from './settings.reducer'
import workTime from './workTime.reducer'
import calendarEvent from './calendarEvent.reducer'
import logbook from './bob/navigation.reducer'
import redirect from './redirect.reducer'
import navigationApiData from './navigationApiData.reducer'
import bobOrder from './bob/bobOrder.reducer'

const rootReducer = combineReducers({
    authentication,
    alert,
    fetch,
    filter,
    post,
    address,
    bob,
    types,
    boat,
    block,
    group,
    activity,
    comments,
    allParkings,
    parking,
    inqueries,
    inquery,
    companies,
    download,
    absence,
    chrono,
    settings,
    workTime,
    calendarEvent,
    logbook,
    redirect,
    navigationApiData,
    bobOrder,
})

export default rootReducer
