import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import MButton from '@material-ui/core/Button'
import { Button } from '../../common/BoatOnButton'
import BoatOnModalCore from '../../common/BoatOnModalCore'
import styles from './Styles/ParkingModalCss'
import dictionary from './Dictionary/ParkingModalDico'
import Divider from '@material-ui/core/Divider'
import ParkingMap from '../../common/UsefullComponents/ParkingMap'
import BoatOnImageRender from '../../common/BoatOnImageRender'
import { parkingActions } from '../../../actions/parkings/parking.actions'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import MessageIcon from '@material-ui/icons/Message'
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import RoomIcon from '@material-ui/icons/Room'
import PrintIcon from '@material-ui/icons/Print'
import AppRoute from '../../../constants/AppRoute'
import { history } from '../../../helpers'
import { inqueryActions } from '../../../actions/inqueries/inquery.actions'

class ParkingModal extends BoatOnModalCore {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.handleClickShowParking = this.handleClickShowParking.bind(this)
        this.handleCopyAddressClipboard = this.handleCopyAddressClipboard.bind(
            this,
        )
        this.handleGoogleMap = this.handleGoogleMap.bind(this)
        this.handlePrint = this.handlePrint.bind(this)
        this.handleOpenChatBox = this.handleOpenChatBox.bind(this)
        this.cancel = this.cancel.bind(this)
    }

    cancel() {
        const { inquery } = this.props
        this.props.dispatch(
            inqueryActions.cancelInquery(inquery.id, this.context),
        )
    }

    handleClickShowParking() {
        const { inquery } = this.props
        const { parkingInq } = inquery

        if (parkingInq)
            this.historyPush(
                history,
                AppRoute.ParkingID,
                '',
                { state: {} },
                { id: `${parkingInq.parking.id}` },
            )
    }

    handleSubscribe() {
        this.historyPush(history, AppRoute.ParkingSearchPage)
    }

    handleOpenChatBox() {
        /*const { inquery } = this.props

        this.historyPush(
            history,
            AppRoute.ChatBox,
            '',
            { state: { inquerySelected: inquery } },
            null,
        )*/
    }

    handleCopyAddressClipboard() {
        const { inquery } = this.props
        const { parkingInq } = inquery

        if (parkingInq.parking) {
            if (inquery.inqueryStatus.id === 2)
                navigator.clipboard.writeText(
                    parkingInq.parking.address.fullAddress,
                )
            else navigator.clipboard.writeText(parkingInq.parking.address.city)
            alert(this.displayText('addressCopied'))
        }
    }

    handleGoogleMap() {
        const { inquery } = this.props
        const { parkingInq } = inquery

        if (parkingInq.parking) {
            window.open(
                'https://www.google.fr/maps/place/' +
                    (inquery.inqueryStatus.id === 2
                        ? parkingInq.parking.address.fullAddress
                        : parkingInq.parking.address.city),
            )
        }
    }

    handlePrint() {
        // const printableElements = document.getElementById('rootPrintable')
        //     .innerHTML
        // const oldPage = document.body.innerHTML
        // document.body.innerHTML =
        //     '<html><head><title></title></head><body>' +
        //     printableElements +
        //     '</body></html>'
        window.print()
        let key = encodeURIComponent('modal-id')
        let value = encodeURIComponent(this.props.inquery.id)
        document.location.search = '?' + key + '=' + value
        this.forceUpdate()
    }

    renderIconWithText(icon, text, callback) {
        const { classes } = this.props

        return (
            <MButton
                size="small"
                className={classes.buttonChevron}
                onClick={e => {
                    callback(e)
                }}
            >
                <div className={classes.flexRow}>
                    {icon}
                    <Typography
                        style={{ marginLeft: '15px', textTransform: 'none' }}
                    >
                        {text}
                    </Typography>
                </div>
                <ChevronRightIcon className={classes.chevron} />
            </MButton>
        )
    }

    renderDate() {
        const { classes, inquery } = this.props
        if (!inquery) return <></>
        const startDate = new Date(
            inquery.delimitedDate.startDate,
        ).toLocaleDateString('default', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        })
        const endDate = inquery.delimitedDate.endDate
            ? new Date(inquery.delimitedDate.endDate).toLocaleDateString(
                  'default',
                  {
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric',
                  },
              )
            : null
        const parkingInq = inquery?.parkingInq

        if (!parkingInq) return <></>

        return (
            <div className={classes.rows}>
                {!endDate ? (
                    <div className={classes.rows}>
                        <div className={classes.rowDate}>
                            <Typography>
                                {this.displayText('arrival')}
                            </Typography>
                            <Typography>{startDate}</Typography>
                        </div>
                        <Divider
                            orientation="vertical"
                            className={classes.verticalDivider}
                        />
                        <div className={classes.rowDate}>
                            <Typography>
                                {this.displayText('departure')}
                            </Typography>
                            <Typography>{this.displayText('ended')}</Typography>
                        </div>
                    </div>
                ) : (
                    <div className={classes.rows}>
                        <div className={classes.rowDate}>
                            <Typography>
                                {this.displayText('arrival')}
                            </Typography>
                            <Typography>{startDate}</Typography>
                        </div>

                        <Divider
                            orientation="vertical"
                            className={classes.verticalDivider}
                        />
                        <div className={classes.rowDate}>
                            <Typography>
                                {this.displayText('departure')}
                            </Typography>
                            <Typography>{endDate}</Typography>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    renderImage() {
        const { classes, inquery } = this.props
        const { parkingInq } = inquery
        let imgs = []

        if (parkingInq?.parking?.files?.length > 0) {
            imgs = parkingActions.getImageToDisplay(parkingInq.parking.files)

            return (
                <div className={classes.imageParking}>
                    <BoatOnImageRender imgs={imgs} multi bottomImage />
                    <Divider className={classes.divider} />
                </div>
            )
        }
        return <div />
    }

    renderPersonnalInfo() {
        const { classes, inquery } = this.props
        const receiver = inquery?.inqueryRelations?.[0]?.user || null
        const phone = inquery?.inqueryRelations?.[0]?.user.contactDetails.find(
            contact => contact.contactType.id === 1,
        )

        if (!receiver) return <></>

        return (
            <div className={classes.particularInfo}>
                <Typography>
                    <span className={classes.bold}>
                        {this.displayText('name')} :
                    </span>
                    &nbsp;
                    {receiver.firstName} {receiver.lastName}
                </Typography>
                <Typography>
                    <span className={classes.bold}>
                        {this.displayText('phone')} :
                    </span>
                    &nbsp;
                    {phone.contact}
                </Typography>
            </div>
        )
    }

    renderDetails() {
        const { classes, inquery } = this.props

        if (inquery.inqueryStatus.id === 3) {
            return (
                <div className={classes.box}>
                    <Typography>{this.displayText('textDecline')}</Typography>
                    <Typography>{this.displayText('textGoTo')}</Typography>
                </div>
            )
        }

        if (inquery.inqueryStatus.id === 4) {
            return (
                <div className={classes.box}>
                    <Typography>{this.displayText('textCancel')}</Typography>
                    <Typography>{this.displayText('textGoTo')}</Typography>
                </div>
            )
        }

        return (
            <div className={classes.box}>
                <Typography className={classes.subtitle}>
                    {this.displayText('detailsLocalization')}
                </Typography>
                <Typography>
                    {inquery.inqueryStatus.id === 2
                        ? this.renderPersonnalInfo()
                        : this.displayText('textLocalization')}
                </Typography>
                {/* TODO: order.CancellationPolicy &&
                order.CancellationPolicy !== 'undefined' ? (
                    <Typography className={classes.particularInfo}>
                        <span className={classes.bold}>
                            {this.displayText('particularInfo')} :{' '}
                        </span>{' '}
                        {order.CancellationPolicy}
                    </Typography>
                ) : (
                    <div />
                )*/}
                {this.renderIconWithText(
                    <MessageIcon />,
                    this.displayText('sendMessage'),
                    this.handleOpenChatBox,
                )}
                {this.renderIconWithText(
                    <MeetingRoomIcon />,
                    this.displayText('showAnnouncement'),
                    this.handleClickShowParking,
                )}
            </div>
        )
    }

    renderHowToGo() {
        const { classes, inquery } = this.props
        const { parkingInq } = inquery

        if (inquery.inqueryStatus.id === 3 || inquery.inqueryStatus.id === 4) {
            return (
                <Button
                    variant="contained"
                    type="primary"
                    style={{
                        margin: '20px auto',
                        width: `100%`,
                    }}
                    onClick={() => this.handleSubscribe()}
                    label={this.displayText('findAnotherPlace')}
                />
            )
        }

        return (
            <div className={classes.box}>
                <Typography className={classes.subtitle}>
                    {this.displayText('howToGo')}
                </Typography>
                <div className={classes.parkingMap}>
                    <ParkingMap
                        mapStatus
                        height="20vh"
                        parking={parkingInq.parking}
                        precise={inquery.inqueryStatus.id === 2 ? true : false}
                        isOneParking
                    />
                </div>
                <Typography className={classes.bold}>
                    {this.displayText('address')}
                </Typography>
                <Typography className={classes.particularInfo}>
                    {inquery.inqueryStatus.id === 2
                        ? parkingInq.parking.address.fullAddress
                        : parkingInq.parking.address.city}
                </Typography>
                {this.renderIconWithText(
                    <FileCopyIcon />,
                    this.displayText('copyAddress'),
                    this.handleCopyAddressClipboard,
                )}
                {this.renderIconWithText(
                    <RoomIcon />,
                    this.displayText('getItinerary'),
                    this.handleGoogleMap,
                )}
            </div>
        )
    }

    renderDetailsRequest() {
        const { classes, inquery } = this.props
        const { parkingInq } = inquery

        if (
            inquery.inqueryStatus.id === 3 ||
            inquery.inqueryStatus.id === 4 ||
            !parkingInq
        ) {
            return <></>
        }

        return (
            <div className={classes.box}>
                <Divider className={classes.divider} />
                <Typography className={classes.subtitle}>
                    {this.displayText('detailsRequest')}
                </Typography>
                <div>
                    {parkingInq?.boat?.model && parkingInq?.boat?.maker ? (
                        <Typography>
                            {this.displayText('toStock')}{' '}
                            {parkingInq.boat.model} - {parkingInq.boat.maker}
                        </Typography>
                    ) : (
                        <></>
                    )}
                    <Typography>
                        {this.displayText('lengthNeed')} {parkingInq.length} m
                    </Typography>
                    <Typography>
                        {this.displayText('widthNeed')} {parkingInq.width} m
                    </Typography>
                    <Typography>
                        {this.displayText('surfaceNeed')}{' '}
                        {parkingInq.length * parkingInq.width} m
                        <sup style={{ fontSize: '10px' }}>2</sup>
                    </Typography>
                </div>
            </div>
        )
    }

    renderInfoPaiement() {
        const { classes, inquery } = this.props
        const { parkingInq } = inquery
        if (
            inquery.inqueryStatus.id === 3 ||
            inquery.inqueryStatus.id === 4 ||
            !parkingInq
        ) {
            return <></>
        }
        return (
            <div className={classes.box}>
                <Divider className={classes.divider} />
                <Typography className={classes.subtitle}>
                    {this.displayText('infoPayment')}
                </Typography>
                <div>
                    <Typography className={classes.particularInfo}>
                        {this.displayText('amount')} (TTC) :{' '}
                        {parkingInq?.transactions?.[0]?.amountIt} €
                        {parkingInq?.transactions?.[0]?.recurenceType?.id === 4
                            ? this.displayText('perMonth')
                            : ''}
                    </Typography>
                </div>
                {/*this.renderIconWithText(<ReceiptIcon />, this.displayText('getReceipt'))*/}
                {this.renderIconWithText(
                    <PrintIcon />,
                    this.displayText('printInfo'),
                    this.handlePrint,
                )}
            </div>
        )
    }

    _body() {
        const { classes } = this.props
        return (
            <div className={classes.rootPrintable} id="rootPrintable">
                {this.renderDate()}
                {this.renderImage()}
                {this.renderDetails()}
                {this.renderHowToGo()}
                {this.renderDetailsRequest()}
                {this.renderInfoPaiement()}
            </div>
        )
    }

    render() {
        const { loading, title, inquery } = this.props

        return (
            <>
                {this._renderTitle(title)}
                {this._renderBody({
                    body: this._body(),
                })}
                {this._renderActions([
                    {
                        label: this.displayText('cancel'),
                        action: this.cancel,
                        type: `cancel`,
                        disabled:
                            (inquery.delimitedDate.startDate &&
                                new Date(inquery.delimitedDate.startDate) <=
                                    new Date()) ||
                            inquery.inqueryStatus.id !== 1 ||
                            loading > 0,
                    },
                ])}{' '}
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        inquery: state.inquery.parkingInquery,
        loading: state.inquery.loading,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ParkingModal))
