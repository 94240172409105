export default theme => ({
    navigationContainer: {
        display: 'grid',
        width: '100%',
        gap: 20,
        gridTemplateColumns: '2fr 1fr 2fr',
        gridTemplateAreas: `
            "step-btns map map"
            "steps map map"
            "crew stocks stocks"
            "crew workingtime workingtime"
            "feedback feedback feedback"
        `,
        [theme.breakpoints.down('sm')]: {
            gap: 10,
            marginTop: -10,
            gridTemplateColumns: '1fr',
            gridTemplateAreas: `
                "shared-btns"
                "step-btns"
                "crew"
                "steps"
                "map"
                "details-btn"
                "stocks"
                "workingtime"
                "feedback"
            `,
        },
    },
    navWithoutBtn: {
        gridTemplateAreas: `
            "steps map map"
            "crew stocks stocks"
            "crew workingtime workingtime"
            "feedback feedback feedback"
        `,
        [theme.breakpoints.down('sm')]: {
            gridTemplateAreas: `
                "shared-btns"
                "step-btns"
                "crew"
                "steps"
                "map"
                "details-btn"
                "stocks"
                "workingtime"
                "feedback"
            `,
        },
    },

    sectionTitle: {
        fontSize: 16,
        fontWeight: 700,
    },

    sharedButtons: {
        gridArea: 'shared-btns',
        display: 'flex',
        flexDirection: 'row',
        gap: 30,
        alignItems: 'center',
    },
    outlinedBtn: {
        backgroundColor: 'white',
        color: 'black',
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    travelIcons: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    popoverContent: {
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
    },
    popoverRow: {
        padding: 10,
        gap: 5,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    popoverRowRight: {
        gap: 5,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'right',
    },

    stepBtns: {
        gridArea: 'step-btns',
        display: 'flex',
        flexDirection: 'row',
    },
    travelVerticalContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
    travelHorizontalContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
        width: '100%',
    },
    alert: {
        marginBottom: 8,
        border: '1px solid',
        backgroundColor: 'rgba(156, 184, 227, 0.1) !important',
        borderColor: 'rgb(156, 184, 227)',
        alignItems: 'center',
        color: `${theme.palette.primary.main} !important`,
        fontSize: 14,
    },
    alertIcon: {
        color: theme.palette.primary.main,
    },
    hideIcon: {
        opacity: 0,
        width: '0px !important',
    },

    containerCrew: {
        gridArea: 'crew',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: 'repeat(2, min-content) 10px repeat(2, min-content)',
        gridTemplateAreas: `
            "captain-title edit-crew-icon"
            "captain-data captain-data"
            ". ."
            "crew-title crew-title"
            "crew-data crew-data"
        `,
        border: '1px solid #E6E8E9',
        padding: 10,
        gap: 5,
        height: 'fit-content',
        borderRadius: 12,
        boxShadow: '0px 4px 4px 0px #00000040',
        border: 'solid 1px #E6E8E9',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr 1fr',
            gridTemplateRows: '1fr 1fr auto',
            gridTemplateAreas: `
                "captain-title crew-title edit-crew-icon"
                "captain-data crew-data edit-crew-icon"
            `,
            boxShadow: 'unset',
        },
    },
    iconEditCrew: {
        gridArea: 'edit-crew-icon',
        justifySelf: 'end',
        '&:hover': {
            cursor: 'pointer',
        },
        [theme.breakpoints.down('sm')]: {
            alignSelf: 'center',
        },
    },
    containerCrewTitle: {
        fontSize: 14,
        fontWeight: 400,
        color: '#7B7D7E',
        alignSelf: 'end',
        [theme.breakpoints.down('sm')]: {
            alignSelf: 'center',
            fontSize: 12,
        },
    },
    containerCrewData: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        fontWeight: 500,
        fontSize: 14,
    },
    badge: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 10,
        backgroundColor: '#E5E7F0',
        width: 'fit-content',
        color: theme.palette.primary.main,
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        borderRadius: '100%',
        width: 20,
        height: 20,
        fontWeight: 'bold',
    },
    containerCrewDataGroup: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: 5,
        [theme.breakpoints.down('sm')]: {
            gap: 0,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            fontSize: 14,
            '& > div': {
                marginRight: -5,
            },
        },
    },
    crewItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 14,
        gap: 10,
        fontWeight: 500,
    },

    rowStep: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 10,
    },
    stepDotContainer: {
        width: 50,
        position: 'relative',
    },
    stepHours: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    stepDotRoute: {
        top: -21,
        left: 'calc(50% - 1.5px)',
        height: '100%',
        position: 'absolute',
        borderLeftWidth: 3,
        borderLeftStyle: 'dotted',
        borderLeftColor: '#EBEBEB',
    },
    stepDotStop: {
        position: 'absolute',
        top: 'calc(50% - 5px)',
        left: 'calc(50% - 5px)',
        width: 10,
        height: 10,
        borderRadius: '100%',
        backgroundColor: '#EBEBEB',
    },
    stepDot: {
        position: 'absolute',
        top: 'calc(50% - 7px)',
        left: 'calc(50% - 7px)',
        width: 14,
        height: 14,
        borderRadius: '100%',
        backgroundColor: '#EBEBEB',
    },
    stepDataContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    stepDataHourStopover: {
        fontSize: 14,
        fontWeight: 500,
    },
    stepDataPlaceLabel: {
        fontSize: 14,
        color: '#7B7D7E',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
        },
    },
    stepPlaceIcon: {
        color: theme.palette.primary.main,
        height: '14px !important',
        width: '14px !important',
        marginLeft: 5,
    },
    stepDataPlace: {
        color: theme.palette.primary.main,
    },
    stepSeatsContainer: {
        width: 50,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 16,
    },
    seatsIcon: {
        height: '16px !important',
        width: '16px !important',
    },
    travelSteps: {
        gridArea: 'steps',
        display: 'flex',
        flexDirection: 'column-reverse',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
        },
    },
    travelStepsTime: {
        padding: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gap: 10,
        minWidth: 110,
        flex: 1,
        '& > div': {
            flex: 1,
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    travelTimeData: {
        fontSize: 32,
        color: theme.palette.primary.main,
    },
    black: {
        color: '#212121',
    },
    travelTimeTitle: {
        fontSize: 14,
        fontWeight: 400,
        color: '#7B7D7E',
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
        },
    },
    travelTimeSubtitle: {
        fontSize: 14,
        marginLeft: 5,
    },
    travelStepsDetails: {
        flex: 3,
    },
    stepDataHour: {
        fontSize: 20,
        fontWeight: 500,
    },

    mapContainer: {
        gridArea: 'map',
        width: '100%',
        height: '100%',
        minHeight: 300,
        backgroundColor: 'blue',
        '& .leaflet-container': {
            height: '100%',
            width: '100%',
            minHeight: 300,
        },
        '& path': {
            cursor: 'grab',
        },
        '& .leaflet-marker-pane > img': {
            cursor: 'grab',
        },
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% + 40px)',
            minHeight: 150,
            marginLeft: -20,
            marginRight: -20,
        },
    },

    detailsBtn: {
        gridArea: 'details-btn',
    },

    outlinedPrimaryBtn: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: 'white',
        },
    },

    stocks: {
        gridArea: 'stocks',
        display: 'grid',
        gap: 10,
        alignItems: 'center',
        gridTemplateAreas: `
            "stock-title stock-edit-btn"
            "stock-container stock-container"
        `,
        [theme.breakpoints.down('sm')]: {
            gridTemplateAreas: `
                "stock-title"
                "stock-container"
                "stock-edit-btn"
            `,
        },
    },
    stockTitle: {
        gridArea: 'stock-title',
    },
    stockContainer: {
        gridArea: 'stock-container',
        display: 'grid',
        columnGap: 10,
        rowGap: 16,
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
        '& > .case:nth-child(5n+2)::before': {
            content: `''`,
            position: 'absolute',
            top: '10%',
            left: -5,
            height: '90%',
            borderLeft: '1px solid #EBEBEB',
        },
        '& > .case:nth-child(5n+3)::before': {
            content: `''`,
            position: 'absolute',
            top: '10%',
            left: -5,
            height: '90%',
            borderLeft: '1px solid #EBEBEB',
        },
        '& > .case:nth-child(5n+4)::before': {
            content: `''`,
            position: 'absolute',
            top: '10%',
            left: -5,
            height: '90%',
            borderLeft: '1px solid #EBEBEB',
        },
        '& > .case:nth-child(5n+5)::before': {
            content: `''`,
            position: 'absolute',
            top: '10%',
            left: -5,
            height: '90%',
            borderLeft: '1px solid #EBEBEB',
        },
        '& > .case:nth-child(5n+5)::after': {
            content: `''`,
            position: 'absolute',
            top: 'calc(100% + 8px)',
            left: 'calc(-1 * (400% + 40px))',
            width: 'calc(500% + 40px)',
            borderTop: '1px solid #EBEBEB',
        },

        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr 1fr 1fr',
            '& > .case:nth-child(5n+2)::before': {
                display: 'none',
            },
            '& > .case:nth-child(5n+3)::before': {
                display: 'none',
            },
            '& > .case:nth-child(5n+4)::before': {
                display: 'none',
            },
            '& > .case:nth-child(5n+5)::before': {
                display: 'none',
            },
            '& > .case:nth-child(5n+5)::after': {
                display: 'none',
            },

            '& > .case:nth-child(3n)::before': {
                display: 'unset',
                content: `''`,
                position: 'absolute',
                top: '10%',
                left: -5,
                height: '90%',
                borderLeft: '1px solid #EBEBEB',
            },
            '& > .case:nth-child(3n-1)::before': {
                display: 'unset',
                content: `''`,
                position: 'absolute',
                top: '10%',
                left: -5,
                height: '90%',
                borderLeft: '1px solid #EBEBEB',
            },
            '& > .case:nth-child(3n+4)::after': {
                display: 'unset',
                content: `''`,
                position: 'absolute',
                top: '-8px',
                left: 0,
                width: 'calc(300% + 20px)',
                borderTop: '1px solid #EBEBEB',
            },
        },
    },
    stockItemContainer: {
        position: 'relative',
    },
    stockItemTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 5,
        fontSize: 12,
        fontWeight: 400,
        color: '#7B7D7E',
    },
    stockIcon: {
        height: 20,
        width: 20,
    },
    stockItemLevels: {
        height: 50,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    stockItemGraph: {
        height: 50,
        width: 50,
    },
    stockItemValues: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    stockPercent: {
        fontSize: 20,
    },
    redColor: {
        color: '#E40000',
    },
    stockCapacity: {
        fontSize: 12,
    },
    updateStocks: {
        color: theme.palette.primary.main,
        gridArea: 'stock-edit-btn',
        justifySelf: 'end',
        [theme.breakpoints.down('sm')]: {
            justifySelf: 'unset',
        },
    },
    updateIcon: {
        height: 20,
        width: 20,
    },

    workingtime: {
        position: 'relative',
        gridArea: 'workingtime',
        display: 'grid',
        gap: 0,
        alignItems: 'center',
        gridTemplateAreas: `
            "worktime-title worktime-edit-btn"
            "worktime-container worktime-container"
        `,
        '&::before': {
            content: `''`,
            borderBottom: '1px solid #E6E8E9',
            position: 'absolute',
            top: -5,
            width: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            gap: 10,
            paddingTop: 5,
            paddingBottom: 5,
            gridTemplateAreas: `
            "worktime-title"
            "worktime-container"
            "worktime-edit-btn"
            `,
            '&::after': {
                content: `''`,
                borderBottom: '1px solid #E6E8E9',
                position: 'absolute',
                bottom: -5,
                width: '100%',
            },
        },
    },
    worktimeTitle: {
        gridArea: 'worktime-title',
    },
    worktimeContainer: {
        gridArea: 'worktime-container',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        rowGap: 16,
        columnGap: 10,
        '& > .case:nth-child(4n+2)::before': {
            content: `''`,
            position: 'absolute',
            top: '10%',
            left: -5,
            height: '90%',
            borderLeft: '1px solid #EBEBEB',
        },
        '& > .case:nth-child(4n+3)::before': {
            content: `''`,
            position: 'absolute',
            top: '10%',
            left: -5,
            height: '90%',
            borderLeft: '1px solid #EBEBEB',
        },
        '& > .case:nth-child(4n+4)::before': {
            content: `''`,
            position: 'absolute',
            top: '10%',
            left: -5,
            height: '90%',
            borderLeft: '1px solid #EBEBEB',
        },
        '& > .case:nth-child(4n+4)::after': {
            content: `''`,
            position: 'absolute',
            top: 'calc(100% + 8px)',
            left: 'calc(-1 * (300% + 40px))',
            width: 'calc(400% + 40px)',
            borderTop: '1px solid #EBEBEB',
        },
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr 1fr',
            '& > .case:nth-child(2n+2)::before': {
                content: `''`,
                position: 'absolute',
                top: '10%',
                left: -5,
                height: '90%',
                borderLeft: '1px solid #EBEBEB',
            },
            '& > .case:nth-child(2n+2)::after': {
                content: `''`,
                position: 'absolute',
                top: 'calc(100% + 8px)',
                left: 'calc(-1 * (100% + 10px))',
                width: 'calc(200% + 10px)',
                borderTop: '1px solid #EBEBEB',
            },
            '& > .case:nth-child(4n+3)::before': {
                display: 'none',
            },
        },
    },
    worktimeItemContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    worktimeIcon: {
        height: 24,
        width: 24,
        margin: 10,
    },
    worktimeDataContainer: {
        flex: 1,
        paddingTop: 10,
        paddingBottom: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    worktimeItemTitle: {
        fontSize: 12,
        fontWeight: 400,
        color: '#7B7D7E',
    },
    worktimeItemValue: {
        fontSize: 20,
    },
    updateWorktime: {
        gridArea: 'worktime-edit-btn',
        color: theme.palette.primary.main,
        justifySelf: 'end',
        [theme.breakpoints.down('sm')]: {
            justifySelf: 'unset',
        },
    },

    containerFeedback: {
        gridArea: 'feedback',
        padding: 10,
        borderRadius: 12,
        boxShadow: '0px 4px 4px 0px #00000040',
        border: 'solid 1px #E6E8E9',
        [theme.breakpoints.down('sm')]: {
            borderRadius: 'unset',
            boxShadow: 'unset',
            border: 'unset',
        },
    },

    judgmentValue: {
        border: 'none',
        fontSize: 14,
        fontWeight: 300,
        marginTop: 10,
        marginBottom: 10,
        color: '#212121',
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
        },
    },

    containerHorizontalImages: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 10,
        width: '100%',
        flexFlow: 'wrap',
        gap: 10,
    },
    itemImage: {
        width: 100,
        marginBottom: 10,
    },
})
