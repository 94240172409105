import React from 'react'
import BoatOnComponent from './BoatOnComponent'
import dictionary from './Dictionary/BoatOnModalDico'
import { Close } from '@material-ui/icons'
import {
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Typography,
    Tooltip,
} from '@material-ui/core'
import BoatOnAppBar from './BoatOnAppBar'
import { Button } from './BoatOnButton'
import _uniqueId from 'lodash/uniqueId'
import BoatOnBlock from './Blocks/BoatOnBlock'
import ActivityComponent from './ActivityComponent'
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined'
import { fixIosHeaderBug } from 'utils/usefullFunctions'

class BoatOnModalCore extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            tabIndex: props.tabIndex || 0,
            forceSaveActivity: false,
        }

        this.dialogRef = React.createRef()

        this.setTabIndex = this.setTabIndex.bind(this)
        this.submitActivity = this.submitActivity.bind(this)
    }

    setTabIndex(newIndex) {
        this.setState({ tabIndex: newIndex })
    }

    _renderAppBar(labels) {
        const { tabIndex } = this.state

        return (
            <BoatOnAppBar
                tabIndex={tabIndex}
                displayBodyOut={this.setTabIndex}
                labels={labels}
                disableDivider
            />
        )
    }

    _renderTitle(
        title,
        noCross = null,
        labels = null,
        {
            buttonPersonalized,
            addedCondition,
            blockIds,
            onlyOneId,
            titleHtml,
        } = {},
        showFollowButton = false,
    ) {
        const { handleClose, classes } = this.props

        // Bouton Suivre masqué temporairement
        showFollowButton = false

        return (
            <DialogTitle
                id={`dialog-labelledby-${title}`}
                style={{
                    paddingBottom: labels ? 0 : 16,
                }}
                disableTypography
                children={
                    <>
                        <div
                            style={{
                                marginRight: `30px`,
                                display: `flex`,
                                flexDirection: `row`,
                                alignItems: `center`,
                                justifyContent: 'space-between',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {title ? (
                                    <Typography variant={`subtitle1`}>
                                        {title}
                                    </Typography>
                                ) : titleHtml ? (
                                    <>{titleHtml}</>
                                ) : (
                                    <Typography variant={`subtitle1`}>
                                        {'title'}
                                    </Typography>
                                )}
                                {buttonPersonalized && (
                                    <BoatOnBlock
                                        children={buttonPersonalized}
                                        blockIds={blockIds}
                                        onlyOneId={onlyOneId}
                                        addedCondition={addedCondition}
                                    />
                                )}
                            </div>
                            {showFollowButton && (
                                <Tooltip
                                    title={
                                        <React.Fragment>
                                            <Typography>
                                                <span>
                                                    {this.displayText(
                                                        'tooltipFollow1',
                                                    )}
                                                </span>{' '}
                                                <b>
                                                    <u>
                                                        {this.displayText(
                                                            'tooltipFollow2',
                                                        )}
                                                    </u>
                                                </b>
                                            </Typography>
                                        </React.Fragment>
                                    }
                                >
                                    <div>
                                        <Button
                                            style={{
                                                color: '#3040a0',
                                            }}
                                            classes={{
                                                label:
                                                    classes.followButtonLabel,
                                            }}
                                            label={
                                                <>
                                                    <VisibilityIcon
                                                        style={{
                                                            marginRight: 10,
                                                            width: 20,
                                                            height: 20,
                                                        }}
                                                    />
                                                    {this.displayText('follow')}
                                                </>
                                            }
                                            type="light"
                                        ></Button>
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                        {noCross ? (
                            <></>
                        ) : (
                            <IconButton
                                size={`small`}
                                id={`closeDialog`}
                                onClick={handleClose}
                                children={<Close />}
                                style={{
                                    position: `absolute`,
                                    right: `24px`,
                                    top: `16px`,
                                    color: `#C4C4C4`,
                                }}
                            />
                        )}
                        {labels && this._renderAppBar(labels)}
                    </>
                }
            ></DialogTitle>
        )
    }

    _renderBody({ body, bodies, bodyMinHeigh, hasDividers = true }) {
        const { tabIndex } = this.state

        if (!body && !bodies) {
            console.error(
                'missing parameters: ',
                'body & bodies:',
                !body && !bodies,
            )
            return <></>
        }

        if (bodies)
            return (
                <DialogContent
                    dividers={hasDividers}
                    style={{ maxHeight: `calc(100vh - 220px)` }}
                    ref={this.dialogRef}
                >
                    {bodies.map((body, index) => (
                        <div
                            key={index}
                            index={index}
                            hidden={tabIndex !== index}
                            style={{ minHeight: bodyMinHeigh }}
                        >
                            {body}
                        </div>
                    ))}
                </DialogContent>
            )
        return (
            <DialogContent dividers={hasDividers} key={`dialog-content`}>
                {body}
            </DialogContent>
        )
    }

    _onClickButton(callback, callbackArgs) {
        fixIosHeaderBug()

        if (callback && callbackArgs) callback(...callbackArgs)
        else if (callback) callback()
    }

    _renderButton(cta) {
        return (
            <Button
                primary
                label={cta.label}
                onClick={(...args) => this._onClickButton(cta.action, args)}
                style={{
                    minWidth: cta.minWidth || 0,
                    margin: 10,
                    height: 'calc(100% - 20px)',
                }}
                endIcon={cta.endIcon || null}
                disabled={cta.disabled}
                key={`cta.label-${_uniqueId()}`}
                type={cta.type}
                form={cta.form}
                size={'large'}
            />
        )
    }

    _renderActions(CTAs) {
        if (!CTAs) return <></>

        return (
            <DialogActions
                style={{ padding: `16px 24px`, alignItems: `stretch` }}
            >
                {CTAs.map(cta => {
                    return cta.blockIds ? (
                        <BoatOnBlock
                            blockIds={cta.blockIds}
                            onlyOneId={cta.onlyOneId}
                            addedCondition={cta.addedCondition}
                            children={this._renderButton(cta)}
                        />
                    ) : (
                        this._renderButton(cta)
                    )
                })}
            </DialogActions>
        )
    }

    submitActivity() {
        this.setState(
            {
                forceSaveActivity: true,
            },
            () => {
                setTimeout(
                    () => this.setState({ forceSaveActivity: false }),
                    100,
                )
            },
        )
    }

    _renderActivity(activity, event, eventType) {
        const { handleClose } = this.props

        return (
            <ActivityComponent
                forceSave={this.state.forceSaveActivity}
                activities={activity}
                event={event}
                closeModal={handleClose}
                eventType={eventType}
                scrollToTop={() => {
                    this.dialogRef.current.scrollTo(0, 0)
                }}
            />
        )
    }

    render() {
        const {
            title,
            body,
            bodies,
            actions,
            children,
            noCross,
            showFollowButton,
        } = this.props

        if (!title || (!body && !bodies && !children)) {
            console.error(
                'missing parameters: ',
                'title:',
                !title,
                'body & bodies & children:',
                !body && !bodies && !children,
            )
            return <></>
        }

        return (
            <>
                {this._renderTitle(
                    title,
                    noCross,
                    null,
                    undefined,
                    showFollowButton,
                )}
                {this._renderBody({ body: body || children(), bodies })}
                {this._renderActions(actions)}
            </>
        )
    }
}

export default BoatOnModalCore
