import React from 'react'
import BoatOnComponent from '../../../common/BoatOnComponent'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import {
    removeFilesFromBOBEvent,
    setBOBStateEvent,
    deleteFileFromBobEvent,
    getImageToDisplay,
} from '../../../../actions/bob.actions'
import { Popover, Button as ButtonMui, IconButton } from '@material-ui/core'
import { filterActions } from '../../../../actions/filter.actions'
import styles from './Styles/MyDocumentListCss'
import dictionary from './Dictionary/MyDocumentListDico'
import Typography from '@material-ui/core/Typography'
import AttachFile from '@material-ui/icons/AttachFile'
import Delete from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'
import BoatOnModal from '../../../common/BoatOnModal'
import BoatOnImageRender from '../../../common/BoatOnImageRender'
import BoatOnModalCore from '../../../common/BoatOnModalCore'
import { typesActions } from '../../../../actions/types.actions'
import BobFilter from '../BobFilter'
import DocumentModal from './DocumentModal'
import BoatOnRenderDate from '../../../common/UsefullComponents/BoatOnRenderDate'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import ModalNoBoat from '../../../common/UsefullComponents/ModalConnection/ModalNoBoat'
import BoatOnBlock from '../../../common/Blocks/BoatOnBlock'
import { getContextFromUrl } from '../../../../languages/LocalizerUtils'
import { Button as ButtonBON } from '../../../common/BoatOnButton'
import HourAlert from '../HourAlert'

class MyDocumentList extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            events: [],
            event: null,
            openModal: false,
            fileNoUrl: { event: [] },
            displayNewFiles: { event: [] },
            openDoc: false,
            deletedFiles: { event: [] },
            error: false,
            renderPopup: false,
            anchorPopup: null,
            documentPopup: null,
            openModalNoBoat: false,
        }

        this.handleClosePopup = this.handleClosePopup.bind(this)
        this._renderPopup = this._renderPopup.bind(this)
        this.handleOpenPopOverMore = this.handleOpenPopOverMore.bind(this)

        this.reset = this.reset.bind(this)
        this.handleOpenModalMyDocument = this.handleOpenModalMyDocument.bind(
            this,
        )
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    handleClosePopup() {
        this.setState({
            renderPopup: false,
            anchorPopup: null,
            documentPopup: null,
        })
    }

    async handleClose() {
        this.setState({ openModalNoBoat: false })
    }

    _renderPopup() {
        const { classes, documents = [] } = this.props
        const { documentPopup } = this.state

        return (
            <Popover
                id={'popover'}
                open={this.state.renderPopup}
                anchorEl={this.state.anchorPopup}
                onClose={this.handleClosePopup}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className={classes.iconsMobile}>
                    <BoatOnBlock
                        blockIds={4}
                        children={
                            <ButtonMui
                                onClick={() => {
                                    this.handleClosePopup()
                                    this.edit(documentPopup)
                                }}
                                className={classes.actionMobile}
                                startIcon={<Edit />}
                            >
                                {this.displayText(`edit`)}
                            </ButtonMui>
                        }
                    />

                    {documentPopup.files && documentPopup.files.length > 0 && (
                        <ButtonMui
                            onClick={() => {
                                this.handleClosePopup()
                                this.handleOpenModalMyDocument(documentPopup)
                            }}
                            className={classes.actionMobile}
                            startIcon={<AttachFile />}
                        >
                            {this.displayText(`files`)}
                        </ButtonMui>
                    )}

                    <BoatOnBlock
                        blockIds={6}
                        children={
                            <ButtonMui
                                onClick={() => {
                                    if (
                                        window.confirm(
                                            this.displayText('validateDelete'),
                                        )
                                    ) {
                                        this.handleClosePopup()
                                        this.delete(documentPopup)
                                    }
                                }}
                                className={classes.actionMobile}
                                startIcon={<Delete />}
                            >
                                {this.displayText(`supprimerDoc`)}
                            </ButtonMui>
                        }
                    />
                </div>
            </Popover>
        )
    }

    handleOpenPopOverMore(e, event) {
        this.setState({
            documentPopup: event,
            renderPopup: true,
            anchorPopup: e.currentTarget,
        })
    }

    componentDidUpdate(prevProps) {
        const {
            events,
            eventTypes,
            loadingEvents,
            bobFilter,
            groupLoading,
        } = this.props

        if (
            (events &&
                Array.isArray(events) &&
                eventTypes &&
                loadingEvents === 0 &&
                prevProps.loadingEvents !== 0) ||
            JSON.stringify(prevProps.bobFilter) !== JSON.stringify(bobFilter)
        ) {
            const documents = []
            events.forEach(event => {
                const eventType = typesActions.getEventTypeById(
                    eventTypes,
                    event.eventType.id,
                )
                if (
                    event.files &&
                    eventType &&
                    event.files.length > 0 &&
                    eventType.document
                ) {
                    documents.push(event)
                }
            })
            this.setState({ events: documents })
        }
    }

    componentDidMount() {
        const { events, eventTypes } = this.props
        if (events && eventTypes && Array.isArray(events)) {
            const documents = []
            events.forEach(event => {
                const eventType = typesActions.getEventTypeById(
                    eventTypes,
                    event.eventType.id,
                )
                if (
                    event.files &&
                    eventType &&
                    event.files.length > 0 &&
                    eventType.document
                ) {
                    documents.push(event)
                }
            })
            this.setState({ events: documents })
        }
    }

    handleOpenModalMyDocument(document) {
        this.setState({ openModal: 'showDocs', openDoc: document })
    }

    reset() {
        this.setState({
            openModal: false,
            event: null,
            fileNoUrl: { event: [] },
            displayNewFiles: { event: [] },
            deletedFiles: { event: [] },
            openDoc: false,
            error: false,
        })
    }

    handleOpenModal() {
        if (this.props.boat) {
            this.setState({ openModal: 'add', openDoc: false })
            setBOBStateEvent(this)
        } else {
            this.setState({ openModalNoBoat: true })
        }
    }

    deleteDocumentNoUp(i) {
        deleteFileFromBobEvent(this, i, {
            deletedFiles: this.state.deletedFiles.event,
            displayNewFiles: this.state.displayNewFiles.event,
            fileNoUrl: this.state.fileNoUrl.event,
            files: this.state.event.files,
        })
    }

    renderDocuments() {
        const { openDoc } = this.state

        return (
            <BoatOnModalCore
                body={
                    <BoatOnImageRender
                        vertical
                        multi
                        bottomImage
                        imgs={getImageToDisplay(
                            openDoc,
                            this.state.displayNewFiles.event,
                        )}
                    />
                }
            />
        )
    }

    edit(document) {
        this.setState({
            event: document,
            openModal: 'add',
            edit: true,
        })
    }

    delete(document) {
        this.props.dispatch(removeFilesFromBOBEvent(document.id))
    }

    _getDetailAndChekupTitle(event) {
        const { checkup, detail } = event
        if (checkup)
            return checkup?.checkupType
                ? this.displayTextApi(checkup.checkupType.translation)
                : checkup?.userCheckup
                ? checkup.userCheckup.name
                : event.title
        else if (detail) {
            const part = detail.part
            const equipment = detail.equipment
            if (part) {
                if (part.userPart) return part.userPart.name
                else if (part.partType)
                    return this.displayTextApi(part.partType.translation)
            } else if (equipment) {
                if (equipment.userEquipment) {
                    return equipment.userEquipment.name
                } else if (equipment.equipmentType)
                    return this.displayTextApi(
                        equipment.equipmentType.translation,
                    )
            }
        }
        return event.title
    }

    _setTitle(event) {
        if (!event) return ''
        if (event.checkup || event.detail)
            return `${this.displayText(
                'editDocument',
            )} - ${this._getDetailAndChekupTitle(event)}`
        return this.displayText('editDocument')
    }

    render() {
        const { classes, loadingEvents, eventTypes, boat } = this.props
        const { openModal, events } = this.state
        if (loadingEvents || !eventTypes) return <></>

        return (
            <div className={classes.root}>
                {/* test blocs roles */}
                <Typography className={classes.title}>
                    {this.displayText('documents')} - {boat.name}
                </Typography>
                <div className={classes.firstLine}>
                    <BobFilter
                        fieldName={'events'}
                        editableButton
                        editableButtonBlockIds={2}
                        editableButtonOnClick={this.handleOpenModal}
                        editableButtonLabel={this.displayText('youAddDocument')}
                        activeButton={{ rangeDate: true }}
                    />
                </div>
                <HourAlert />
                <div className={classes.infos}>
                    {!events || events.length === 0 ? (
                        <Typography className={classes.noDoc}>
                            {this.displayText('noDocument')}
                        </Typography>
                    ) : (
                        events.map((document, index) => {
                            const eventType = typesActions.getEventTypeById(
                                eventTypes,
                                document.eventType.id,
                            )
                            return (
                                <div
                                    key={index}
                                    className={`${classes.normalLine} ${
                                        index % 2 === 1
                                            ? classes.line
                                            : classes.blueLine
                                    }`}
                                >
                                    <div className={classes.dates}>
                                        <div className={classes.datesDayMonth}>
                                            <BoatOnRenderDate
                                                date={
                                                    new Date(
                                                        document?.delimitedDate?.startDate,
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.containerText}>
                                        <img
                                            src={
                                                eventType.photo
                                                    ? require(`images/EventBobIcon/${eventType.photo}`)
                                                    : ''
                                            }
                                            alt={this.displayTextApi(
                                                eventType.translation,
                                            )}
                                            className={classes.icon}
                                        />
                                        <div className={classes.titleAndType}>
                                            <Typography
                                                className={classes.subtitle}
                                                variant="body1"
                                            >
                                                {this._getDetailAndChekupTitle(
                                                    document,
                                                )}
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                className={classes.type}
                                            >
                                                {document.eventType &&
                                                    this.displayTextApi(
                                                        document.eventType
                                                            .translation,
                                                    )}
                                            </Typography>
                                        </div>
                                        <IconButton
                                            className={`${classes.iconButton}`}
                                            onClick={e => {
                                                this.handleOpenPopOverMore(
                                                    e,
                                                    document,
                                                )
                                            }}
                                        >
                                            <MoreHorizIcon
                                                style={{ color: `black` }}
                                            />
                                        </IconButton>
                                    </div>
                                </div>
                            )
                        })
                    )}
                </div>
                <BoatOnModal
                    openCondition={openModal}
                    handleClose={this.reset}
                    modalCores={{
                        add: (
                            <DocumentModal
                                eventTypes={eventTypes}
                                event={this.state.event}
                                noCross={false}
                            />
                        ),
                        showDocs: this.renderDocuments(),
                    }}
                    maxWidth={{
                        add: 'sm',
                    }}
                    titles={{
                        add: this.state.event?.id
                            ? this._setTitle(this.state.event)
                            : this.displayText('youAddDocument'),
                        showDocs: this.displayText('documents'),
                    }}
                />
                <ModalNoBoat
                    open={this.state.openModalNoBoat}
                    handleClose={() => {
                        this.handleClose()
                    }}
                />
                {this.state.renderPopup && this._renderPopup()}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const url = window.location.pathname
    const context = getContextFromUrl(url)

    return {
        user: state.authentication.user,
        boats: state.fetch.boats,
        documents: state.bob.documents,
        spendings: state.bob.spendings,
        bob: state.bob,
        boat: state.bob.boat,
        events: filterActions.filterBobState(
            state.bob.events,
            state.filter.bobFilter,
            context,
            {
                searchString: true,
                rangeDate: true,
                checkupTypesChecked: false,
            },
        ),
        loadingEvents: state.bob.loading,
        bobFilter: state.filter.bobFilter,
        eventTypes: typesActions.getEventTypeDocument(state.types.eventTypes),
        groupLoading: state.group.loading,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(MyDocumentList))
