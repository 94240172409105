import { userConstants } from '../constants'

const initialState = {
    user: null,
    subscriptions: null,
    loading: 0,
}

export default function(prevState = initialState, action) {
    const address =
        action.user && action.user.address && action.user.address.fullAddress
            ? {
                  fullText: action.user.address.fullAddress,
                  latitude: action.user.address.latitude.toString(),
                  longitude: action.user.address.longitude.toString(),
                  number: action.user.address.number,
                  street: action.user.address.street,
                  city: action.user.address.city,
                  zipcode: action.user.address.zipcode,
                  region: action.user.address.region,
                  country: action.user.address.country,
              }
            : null
    const birthday =
        action.user && action.user.birthday && new Date(action.user.birthday)
            ? new Date(action.user.birthday).toISOString().split('T')[0]
            : null
    const user = action.user
        ? {
              activeAccount: action.user.activeAccount || false,
              userGroup: action.user.userGroup || null,
              ownGroup: action.user.ownGroup || null,
              firstName: action.user.firstName || null,
              lastName: action.user.lastName || null,
              email: action.user.email || null,
              stripeCustomerId: action.user.stripeCustomerId || null,
              stripeAccountId: action.user.stripeAccountId || null,
              birthday: birthday,
              address: address,
              contactDetails: action.user.contactDetails,
              description: action.user.description || null,
              get indexPhoneNumber() {
                  const index = this.contactDetails?.findIndex(
                      contactDetail =>
                          contactDetail.contactType &&
                          contactDetail.contactType.id === 1,
                  )
                  return index
              },
          }
        : null

    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                ...prevState,
                user: null,
                subscriptions: null,
                loading: prevState.loading + 1,
            }

        case userConstants.UPDATE_USER:
            return { ...prevState, loading: prevState.loading + 1 }
        case userConstants.UPDATE_USER_EMAIL_USED:
            return { ...prevState, loading: prevState.loading - 1 }
        case userConstants.REQUEST_USER:
            return { ...prevState, user: null, loading: prevState.loading + 1 }
        case userConstants.SUBSCRIBE_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case userConstants.LOGIN_SUCCESS:
            if (!action.user)
                return { ...prevState, loading: prevState.loading - 1 }
            return {
                ...prevState,
                user: user,
                loading: prevState.loading - 1,
            }
        case userConstants.UPDATE_SUCCESS:
            if (!action.user)
                return { ...prevState, loading: prevState.loading - 1 }
            return {
                ...prevState,
                user: user,
                loading: prevState.loading - 1,
            }
        case userConstants.LOGIN_FAILURE:
            return {
                ...prevState,
                user: null,
                subscriptions: null,
                loading: prevState.loading - 1,
            }
        case userConstants.UPDATE_FAILURE:
            return {
                ...prevState,
                user: null,
                subscriptions: null,
                loading: prevState.loading - 1,
            }
        case userConstants.LOGOUT:
            return {
                ...prevState,
                user: null,
                subscriptions: null,
                loading: 0,
            }
        case userConstants.REFRESH_TOKEN:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case userConstants.TOKEN_REFRESH_SUCCESS:
            if (!action.user)
                return { ...prevState, loading: prevState.loading - 1 }
            return {
                ...prevState,
                user: user,
                loading: prevState.loading - 1,
            }
        case userConstants.TOKEN_REFRESH_FAILURE:
            return {
                ...prevState,
                user: null,
                subscriptions: null,
                loading: prevState.loading - 1,
            }
        case userConstants.SUBSCRIPTIONS_REQUEST:
            return {
                ...prevState,
                subscriptions: null,
                loading: prevState.loading + 1,
            }
        case userConstants.SUBSCRIPTIONS_SUCCESS:
            if (!action.subscriptions)
                return { ...prevState, loading: prevState.loading - 1 }
            return {
                ...prevState,
                subscriptions: action.subscriptions || [],
                loading: prevState.loading - 1,
            }
        case userConstants.SUBSCRIPTIONS_FAILURE:
            return {
                ...prevState,
                subscriptions: null,
                loading: prevState.loading - 1,
            }
        case userConstants.UPDATE_PASSWORD:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case userConstants.PASSWORD_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case userConstants.PASSWORD_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case userConstants.START_BOATS_UPDATE:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case userConstants.BOATS_UPDATED:
            return {
                ...prevState,
                user: user,
                loading: prevState.loading - 1,
            }
        case userConstants.BOATS_UPDATE_FAILED:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        default:
            return prevState
    }
}
