export const typesConstants = {
    REQUEST_LISTBOATMODELS: 'REQUEST_LISTBOATMODELS',
    LISTBOATMODELS_SUCCESS: 'LISTBOATMODELS_SUCCESS',
    LISTBOATMODELS_FAILURE: 'LISTBOATMODELS_FAILURE',
    REQUEST_BOATTYPES: 'REQUEST_REQUEST_BOATTYPES',
    REQUEST_BOATTYPES_SUCCESS: 'REQUEST_BOATTYPES_SUCCESS',
    REQUEST_BOATTYPES_FAILURE: 'REQUEST_BOATTYPES_FAILURE',
    REQUEST_ENGINETYPES: 'REQUEST_ENGINETYPES',
    REQUEST_ENGINETYPES_SUCCESS: 'REQUEST_ENGINETYPES_SUCCESS',
    REQUEST_ENGINETYPES_FAILURE: 'REQUEST_ENGINETYPES_FAILURE',
    REQUEST_ENGINETYPEDETAILS: 'REQUEST_ENGINETYPEDETAILS',
    REQUEST_ENGINETYPEDETAILS_SUCCESS: 'REQUEST_ENGINETYPEDETAILS_SUCCESS',
    REQUEST_ENGINETYPEDETAILS_FAILURE: 'REQUEST_ENGINETYPEDETAILS_FAILURE',
    REQUEST_EVENTTYPES: 'REQUEST_EVENTTYPES',
    REQUEST_EVENTTYPES_SUCCESS: 'REQUEST_EVENTTYPES_SUCCESS',
    REQUEST_EVENTTYPES_FAILURE: 'REQUEST_EVENTTYPES_FAILURE',
    REQUEST_BLOCKTYPES: 'REQUEST_BLOCKTYPES',
    REQUEST_BLOCKTYPES_SUCCESS: 'REQUEST_BLOCKTYPES_SUCCESS',
    REQUEST_BLOCKTYPES_FAILURE: 'REQUEST_BLOCKTYPES_FAILURE',

    REQUEST_CHECKUPTYPES: 'REQUEST_CHECKUPTYPES',
    REQUEST_CHECKUPTYPES_SUCCESS: 'REQUEST_CHECKUPTYPES_SUCCESS',
    REQUEST_CHECKUPTYPES_FAILURE: 'REQUEST_CHECKUPTYPES_FAILURE',

    REQUEST_EQUIPMENTFAMILYTYPES: 'REQUEST_EQUIPMENTFAMILYTYPES',
    REQUEST_EQUIPMENTFAMILYTYPES_SUCCESS:
        'REQUEST_EQUIPMENTFAMILYTYPES_SUCCESS',
    REQUEST_EQUIPMENTFAMILYTYPES_FAILURE:
        'REQUEST_EQUIPMENTFAMILYTYPES_FAILURE',

    REQUEST_EQUIPMENTTYPES: 'REQUEST_EQUIPMENTTYPES',
    REQUEST_EQUIPMENTTYPES_SUCCESS: 'REQUEST_EQUIPMENTTYPES_SUCCESS',
    REQUEST_EQUIPMENTTYPES_FAILURE: 'REQUEST_EQUIPMENTTYPES_FAILURE',

    REQUEST_PARTTYPES: 'REQUEST_PARTTYPES',
    REQUEST_PARTTYPES_SUCCESS: 'REQUEST_PARTTYPES_SUCCESS',
    REQUEST_PARTTYPES_FAILURE: 'REQUEST_PARTTYPES_FAILURE',

    REQUEST_USERCHECKUPS: 'REQUEST_USERCHECKUPS',
    REQUEST_USERCHECKUPS_SUCCESS: 'REQUEST_USERCHECKUPS_SUCCESS',
    REQUEST_USERCHECKUPS_FAILURE: 'REQUEST_USERCHECKUPS_FAILURE',

    REQUEST_USEREQUIPMENTFAMILIES: 'REQUEST_USEREQUIPMENTFAMILIES',
    REQUEST_USEREQUIPMENTFAMILIES_SUCCESS:
        'REQUEST_USEREQUIPMENTFAMILIES_SUCCESS',
    REQUEST_USEREQUIPMENTFAMILIES_FAILURE:
        'REQUEST_USEREQUIPMENTFAMILIES_FAILURE',

    REQUEST_USERPARTS: 'REQUEST_USERPARTS',
    REQUEST_USERPARTS_SUCCESS: 'REQUEST_USERPARTS_SUCCESS',
    REQUEST_USERPARTS_FAILURE: 'REQUEST_USERPARTS_FAILURE',

    REQUEST_USEREQUIPMENTS: 'REQUEST_USEREQUIPMENTS',
    REQUEST_USEREQUIPMENTS_SUCCESS: 'REQUEST_USEREQUIPMENTS_SUCCESS',
    REQUEST_USEREQUIPMENTS_FAILURE: 'REQUEST_USEREQUIPMENTS_FAILURE',
    REQUEST_PARKING_TYPES: `REQUEST_PARKING_TYPES`,
    REQUEST_PARKING_TYPES_SUCCESS: `REQUEST_PARKING_TYPES_SUCCESS`,
    REQUEST_PARKING_TYPES_FAILURE: `REQUEST_PARKING_TYPES_FAILURE`,

    REQUEST_USERUSER_TYPES: `REQUEST_USERUSER_TYPES`,
    REQUEST_USERUSER_TYPES_SUCCESS: `REQUEST_USERUSER_TYPES_SUCCESS`,
    REQUEST_USERUSER_TYPES_FAILURE: `REQUEST_USERUSER_TYPES_FAILURE`,

    REQUEST_FREQUENCY_TYPES: `REQUEST_FREQUENCY_TYPES`,
    REQUEST_FREQUENCY_TYPES_SUCCESS: `REQUEST_FREQUENCY_TYPES_SUCCESS`,
    REQUEST_FREQUENCY_TYPES_FAILURE: `REQUEST_FREQUENCY_TYPES_FAILURE`,

    REQUEST_PRESENCE_TYPES: `REQUEST_PRESENCE_TYPES`,
    REQUEST_PRESENCE_TYPES_SUCCESS: `REQUEST_PRESENCE_TYPES_SUCCESS`,
    REQUEST_PRESENCE_TYPES_FAILURE: `REQUEST_PRESENCE_TYPES_FAILURE`,

    REQUEST_PROBOATACTIVITY_TYPES: `REQUEST_PROBOATACTIVITY_TYPES`,
    REQUEST_PROBOATACTIVITY_TYPES_SUCCESS: `REQUEST_PROBOATACTIVITY_TYPES_SUCCESS`,
    REQUEST_PROBOATACTIVITY_TYPES_FAILURE: `REQUEST_PROBOATACTIVITY_TYPES_FAILURE`,

    REQUEST_PRIVATEBOATACTIVITY_TYPES: `REQUEST_PRIVATEBOATACTIVITY_TYPES`,
    REQUEST_PRIVATEBOATACTIVITY_TYPES_SUCCESS: `REQUEST_PRIVATEBOATACTIVITY_TYPES_SUCCESS`,
    REQUEST_PRIVATEBOATACTIVITY_TYPES_FAILURE: `REQUEST_PRIVATEBOATACTIVITY_TYPES_FAILURE`,

    REQUEST_NAVFREQUENCY_TYPES: `REQUEST_NAVFREQUENCY_TYPES`,
    REQUEST_NAVFREQUENCY_TYPES_SUCCESS: `REQUEST_NAVFREQUENCY_TYPES_SUCCESS`,
    REQUEST_NAVFREQUENCY_TYPES_FAILURE: `REQUEST_NAVFREQUENCY_TYPES_FAILURE`,

    REQUEST_BOATLICENCE_TYPES: `REQUEST_BOATLICENCE_TYPES`,
    REQUEST_BOATLICENCE_TYPES_SUCCESS: `REQUEST_BOATLICENCE_TYPES_SUCCESS`,
    REQUEST_BOATLICENCE_TYPES_FAILURE: `REQUEST_BOATLICENCE_TYPES_FAILURE`,

    REQUEST_LEAVE_TYPES: `REQUEST_LEAVE_TYPES`,
    REQUEST_LEAVE_TYPES_SUCCESS: `REQUEST_LEAVE_TYPES_SUCCESS`,
    REQUEST_LEAVE_TYPES_FAILURE: `REQUEST_LEAVE_TYPES_FAILURE`,

    REQUEST_ORDER_STATUS_TYPES: `REQUEST_ORDER_STATUS_TYPES`,
    REQUEST_ORDER_STATUS_TYPES_SUCCESS: `REQUEST_ORDER_STATUS_TYPES_SUCCESS`,
    REQUEST_ORDER_STATUS_TYPES_FAILURE: `REQUEST_ORDER_STATUS_TYPES_FAILURE`,
}
