import { navigationApiDataConstants } from '../constants'

const initialState = {
    loading: 0,
    navigationId: null,
    weatherArray: null,
    ais: null,
    lastAis: null,
}

export default function(prevState = initialState, action) {
    switch (action.type) {
        case navigationApiDataConstants.SWITCH_NAVIGATION:
            return {
                ...prevState,
                loading: 0,
                navigationId: action.navigationId,
                weatherArray: null,
                ais: null,
                lastAis: null,
            }
        case navigationApiDataConstants.REQUEST_NAVIGATION_WEATHER:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case navigationApiDataConstants.REQUEST_NAVIGATION_WEATHER_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
                weatherArray: action.weatherArray,
            }
        case navigationApiDataConstants.REQUEST_NAVIGATION_WEATHER_ERROR:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case navigationApiDataConstants.REQUEST_NAVIGATION_AIS:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case navigationApiDataConstants.REQUEST_NAVIGATION_AIS_SUCCESS:
            let currentAis = (prevState.ais || []).filter(
                item => item.navigationId !== action.navigationId,
            )

            currentAis = [
                ...currentAis,
                {
                    navigationId: action.navigationId,
                    aisData: action.ais,
                },
            ]

            return {
                ...prevState,
                loading: prevState.loading - 1,
                ais: currentAis,
            }
        case navigationApiDataConstants.REQUEST_NAVIGATION_LAST_AIS_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
                lastAis: action.lastAis,
            }
        case navigationApiDataConstants.REQUEST_NAVIGATION_AIS_ERROR:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        default:
            return prevState
    }
}
