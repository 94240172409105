class ParkingsFilter {
    constructor() {
        this.filterParking = this.filterParking.bind(this)
    }

    deg2rad(x) {
        return (Math.PI * x) / 180
    }

    distance(lat1, lng1, lat2, lng2) {
        const earth_radius = 6378137
        const rlo1 = this.deg2rad(lng1)
        const rla1 = this.deg2rad(lat1)
        const rlo2 = this.deg2rad(lng2)
        const rla2 = this.deg2rad(lat2)
        const dlo = (rlo2 - rlo1) / 2
        const dla = (rla2 - rla1) / 2
        const a =
            Math.sin(dla) * Math.sin(dla) +
            Math.cos(rla1) * Math.cos(rla2) * (Math.sin(dlo) * Math.sin(dlo))
        const d = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
        return earth_radius * d
    }

    checkAddress(parking, filters) {
        if (
            filters.address &&
            this.distance(
                parking.address.latitude,
                parking.address.longitude,
                filters.address.lat,
                filters.address.lon,
            ) <= 60000
        ) {
            return true
        }
        if (!filters.address) {
            return true
        }
        return false
    }

    filterParking(parking, filters) {
        if (parking?.activationStatus?.id === 2) {
            return false
        }
        return this.checkAddress(parking, filters)
    }

    filter(filters, parkings, orders) {
        if (!parkings || !filters || !Array.isArray(parkings)) {
            return parkings
        }
        return parkings.filter(parking => {
            return this.filterParking(parking, filters, orders)
        })
    }

    filterPage(filters, parkings, departementPage, cityPage) {
        if (!filters.address) {
            return parkings
        } else {
            return parkings.filter(parking => {
                return this.filterParkingPage(
                    parking,
                    departementPage,
                    cityPage,
                )
            })
        }
    }

    filterParkingPage(parking, departementPage, cityPage) {
        if (cityPage) {
            let lat = parking?.address?.latitude || 0
            let lng = parking?.address?.longitude || 0

            let latCity = cityPage.lat
            let lngCity = cityPage.lon

            return (
                this.distance(lat, lng, latCity, lngCity) <=
                cityPage.radius * 1000
            )
        } else if (departementPage) {
            let show = false
            show =
                this.checkDepartmeentFromZipCode(
                    departementPage,
                    parking.address.zipcode,
                ) || show
            show =
                this.checkDepartementFromFullAdress(
                    departementPage,
                    parking.address.fullAddress,
                ) || show
            return show
        }
        return true
    }

    checkDepartementFromFullAdress(departementPage, fullAddress) {
        if (!fullAddress) return false
        const regex = /[0-9]{5}/
        const found = fullAddress.match(regex)
        return (
            found && this.checkDepartmeentFromZipCode(departementPage, found[0])
        )
    }

    checkDepartmeentFromZipCode(departementPage, zipcode) {
        if (!zipcode) return false
        return zipcode.startsWith(departementPage.code_departement)
    }
}

export default ParkingsFilter
