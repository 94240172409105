import React from 'react'
import { connect } from 'react-redux'
import {
    TileLayer,
    GeoJSON,
    Circle,
    MapContainer,
    Marker,
    useMapEvents,
    useMap,
} from 'react-leaflet'
import L from 'leaflet'
import { withStyles } from '@material-ui/core/styles'
import { history } from '../../../helpers'
import styles from './Styles/ParkingMapCss'
import ParkingsFilter from './ParkingsFilter'
import pointeurMap from '../../../images/pointeurMap.svg'
import pointeurMapSelected from '../../../images/pointeurMapSelected.svg'
import AppRoute from '../../../constants/AppRoute'
import BoatOnComponent from '../BoatOnComponent'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import dictionary from './Dictionary/ParkingMapDico'

const MapEvents = ({ handleDrag }) => {
    // const map = useMapEvents({
    //     dragend: handleDrag,
    //     zoomend: handleDrag,
    // })

    return <></>
}

const MapBounds = ({ bounds, fitBounds, handleFittedBounds }) => {
    const map = useMap()
    if (bounds && fitBounds) {
        map.fitBounds(bounds)
        handleFittedBounds()
    }

    return <></>
}

class ParkingMap extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            nbPointPerpositions: {},
            live: true,
            fitBounds: true,
        }

        this.displayParkingMarker = this.displayParkingMarker.bind(this)
        this.handleDrag = this.handleDrag.bind(this)
        this.changeLiveMode = this.changeLiveMode.bind(this)
        this.handleFittedBounds = this.handleFittedBounds.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.addressFilter !== this.props.addressFilter ||
            prevProps.parkings.length !== this.props.parkings.length ||
            prevProps.geojson !== this.props.geojson
        ) {
            this.setState({ fitBounds: true })
        }
    }

    handleChangeParkingIdHovered(parkingId) {
        this.props.handleChangeParkingIdHovered(parkingId, true)
    }

    handleFittedBounds() {
        this.setState({ fitBounds: false })
    }

    alreadyPositionSet(parking, tmpPosition) {
        for (const i in tmpPosition) {
            if (
                tmpPosition[i].position.lat === parking?.address?.latitude &&
                tmpPosition[i].position.lng === parking?.address?.longitude
            ) {
                return i
            }
        }
        return -1
    }

    handleDrag(e) {
        const { onChangeSearch, loadingAddress } = this.props
        const { live } = this.state
        const { lat, lng } = e.target.getCenter()
        const zoom = e.target.getZoom()

        if (
            onChangeSearch &&
            lat &&
            lng &&
            zoom > 7 &&
            live &&
            loadingAddress === 0
        ) {
            onChangeSearch({
                x: lng,
                y: lat,
                fulltext: this.displayText(`liveSearch`),
                realTime: true,
            })
        } else if (onChangeSearch && zoom <= 7) {
            onChangeSearch(null)
        }
    }

    changeLiveMode() {
        const { live } = this.state
        const { onChangeSearch } = this.props

        this.setState({ live: !live })
        if (onChangeSearch && !live === false) {
            onChangeSearch(null)
        }
    }

    getNbPointPerPosition(parkings) {
        const tmpPosition = []
        for (const i in parkings) {
            const indexTmpPosition = this.alreadyPositionSet(
                parkings[i],
                tmpPosition,
            )
            if (indexTmpPosition >= 0) {
                tmpPosition[indexTmpPosition].parkingIds.push(parkings[i].id)
            } else {
                tmpPosition.push({
                    position: {
                        lat: parkings[i]?.address?.latitude,
                        lng: parkings[i]?.address?.longitude,
                    },
                    parkingIds: [parkings[i].id],
                })
            }
        }
        return tmpPosition
    }

    goToParkingPage(parking, e) {
        const { filters } = this.props
        if (e.originalEvent.which === 1) {
            this.historyPush(
                history,
                AppRoute.ParkingID,
                '',
                {
                    state: {
                        dates: {
                            startDate: filters.startDate,
                            endDate: filters.endDate,
                        },
                    },
                },
                { id: parking },
            )
        } else if (e.originalEvent.which === 3) {
            const url = `https://www.boaton.fr/fr/emplacement/${parking}`
            window.open(url)
        }
    }

    displayParkingMarker() {
        const parkingsFilter = new ParkingsFilter()
        const { allParkings, precise, isOneParking } = this.props

        const parkings =
            allParkings && Array.isArray(this.props.parkings) && !isOneParking
                ? parkingsFilter.filter(
                      this.props.filters,
                      this.props.parkings,
                      this.props.orders.orders,
                  )
                : [this.props.parking]
        const nbPointPerPositions = this.getNbPointPerPosition(parkings)
        const marker = L.icon({
            iconUrl: pointeurMap,
            iconSize: L.point(20, 35),
            iconAnchor: L.point(8, 40),
        })
        const markerSelected = L.icon({
            iconUrl: pointeurMapSelected,
            iconSize: L.point(20, 35),
            iconAnchor: L.point(8, 40),
            zIndexOffset: 1000,
        })

        return nbPointPerPositions.map(parkings => {
            return parkings.parkingIds.map((parking, i) => {
                if (
                    parkings.position.lat &&
                    parkings.position.lng &&
                    this.props.parkingIdHovered &&
                    this.props.parkingIdHovered.parkingId === parking
                ) {
                    return (
                        <Marker
                            icon={markerSelected}
                            zIndexOffset={1}
                            key={parking}
                            eventHandlers={{
                                click: e => {
                                    if (allParkings)
                                        this.goToParkingPage(parking, e)
                                },
                                mouseout: () => {
                                    if (allParkings)
                                        this.handleChangeParkingIdHovered(null)
                                },
                                contextmenu: e => {
                                    if (allParkings)
                                        this.goToParkingPage(parking, e)
                                },
                            }}
                            position={[
                                parkings.position.lat,
                                parkings.position.lng,
                            ]}
                        />
                    )
                }
                if (parkings.position.lat && parkings.position.lng) {
                    if (!allParkings && !precise)
                        return (
                            <Circle
                                key={parking}
                                center={[
                                    parkings.position.lat,
                                    parkings.position.lng,
                                ]}
                                fillColor="blue"
                                radius={400}
                            />
                        )
                    return (
                        <Marker
                            icon={marker}
                            zIndexOffset={0}
                            key={parking}
                            eventHandlers={{
                                mouseover: () => {
                                    if (allParkings)
                                        this.handleChangeParkingIdHovered(
                                            parking,
                                        )
                                },
                            }}
                            position={[
                                parkings.position.lat,
                                parkings.position.lng,
                            ]}
                        />
                    )
                }
                return <div key={parking} />
            })
        })
    }

    render() {
        const {
            classes,
            mapStatus,
            allParkings,
            height,
            addressFilter,
            liveOn,
            loadingParking,
        } = this.props
        var { geojson } = this.props
        const { live, fitBounds } = this.state
        const parkingsFilter = new ParkingsFilter()
        const parkings =
            allParkings && Array.isArray(this.props.parkings)
                ? parkingsFilter.filterPage(
                      this.props.filters,
                      parkingsFilter.filter(
                          this.props.filters,
                          this.props.parkings,
                          this.props.orders.orders,
                      ),
                      this.props.departementPage,
                      this.props.cityPage,
                  )
                : [this.props.parking]
        let bounds = L.latLngBounds([48.8534, 2.3488], [48.8534, 2.3488])
        if (!parkings || loadingParking > 0) {
            return <div />
        }

        if (addressFilter?.realTime && live && liveOn) {
            bounds = null
        } else if (parkings.length === 0 && geojson?.features?.length > 0) {
            bounds = L.latLngBounds(
                [geojson.features[0].bbox[1], geojson.features[0].bbox[0]],
                [geojson.features[0].bbox[3], geojson.features[0].bbox[2]],
            )
        } else if (!addressFilter || !addressFilter.realTime || !live) {
            if (parkings.length === 1) {
                bounds = L.latLngBounds(
                    [
                        parkings[0]?.address?.latitude || 0,
                        parkings[0]?.address?.longitude || 0,
                    ],
                    [
                        parkings[0]?.address?.latitude || 0,
                        parkings[0]?.address?.longitude || 0,
                    ],
                )
            }

            if (parkings.length > 1) {
                bounds = L.latLngBounds(
                    [
                        parkings[0]?.address?.latitude,
                        parkings[0]?.address?.longitude,
                    ],
                    [
                        parkings[1]?.address?.latitude,
                        parkings[1]?.address?.longitude,
                    ],
                )
            }

            if (parkings.length > 2) {
                parkings.forEach(parking => {
                    bounds.extend([
                        parking?.address?.latitude,
                        parking?.address?.longitude,
                    ])
                })
            }
        }

        if (!mapStatus) {
            return <div />
        }

        const center = bounds?.getCenter() || [48.8534, 2.3488]

        return (
            <div className={classes.root}>
                {liveOn ? (
                    <div className={classes.refreshMap}>
                        <Checkbox
                            color="primary"
                            onChange={() => {
                                this.changeLiveMode()
                            }}
                            checked={live}
                        />
                        <Typography className={classes.refreshMapText}>
                            {this.displayText('refresh')}
                        </Typography>
                    </div>
                ) : (
                    <></>
                )}
                <MapContainer
                    ref="map"
                    maxZoom={14}
                    minZoom={2}
                    center={center}
                    zoom={14}
                    attributionControl
                    zoomControl
                    doubleClickZoom
                    scrollWheelZoom
                    dragging
                    animate
                    eventHandlers={{
                        ondragend: this.handleDrag,
                        onZoomend: this.handleDrag,
                    }}
                    easeLinearity={0.35}
                    style={{ height: height ? height : '75vh' }}
                >
                    <MapEvents handleDrag={this.handleDrag} />
                    <MapBounds
                        bounds={bounds}
                        fitBounds={fitBounds}
                        handleFittedBounds={this.handleFittedBounds}
                    />
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    {this.displayParkingMarker()}
                    {geojson && (
                        <GeoJSON
                            key={Math.random()}
                            pointToLayer={() => 0}
                            data={geojson}
                        />
                    )}
                </MapContainer>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        parkings: state.allParkings.parkings,
        parking: state.parking.id === -1 ? ownProps.parking : state.parking,
        loadingParking: state.allParkings.loading,
        orders: state.fetch.orders || [],
        geojson: state.address.geojson,
        loadingAddress: state.address.loading,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ParkingMap))
