import { userConstants } from '../constants'
import { userService } from '../services/user.service'
import { alertActions } from './'
import { history } from '../helpers'
import { requestApi } from '../services/api.service'
import TagManager from 'react-gtm-module'
import { groupActions } from './group.actions'
import { companiesActions } from './companies/companies.actions'
import { getContextFromUrl } from '../languages/LocalizerUtils'

function refreshToken(dispatch) {
    dispatch({ type: userConstants.REFRESH_TOKEN })
    return userService.refreshToken().then(
        result => {
            dispatch({
                type: userConstants.TOKEN_REFRESH_SUCCESS,
                user: result,
            })
            return result
        },
        error => {
            dispatch({ type: userConstants.TOKEN_REFRESH_FAILURE, error })
        },
    )
}

function init() {
    return dispatch => {
        dispatch({
            type: userConstants.REQUEST_USER,
        })
        const cached = userService.getFromLS()
        if (cached && cached.accessToken) {
            return requestApi('/users', 'GET').then(
                result => {
                    dispatch({
                        type: userConstants.UPDATE_SUCCESS,
                        user: result,
                    })
                    if (
                        (result?.userGroup &&
                            Array.isArray(result.userGroup) &&
                            result.userGroup.length > 0) ||
                        result?.ownGroup?.id
                    ) {
                        let groupId = null
                        if (
                            parseInt(localStorage.getItem('groupIdSelect')) ===
                                result?.ownGroup?.id &&
                            result?.ownGroup?.boats?.length === 0 &&
                            result?.userGroup?.length > 0 &&
                            result.userGroup[0].linkRGU[0]?.boats?.length > 0
                        ) {
                            localStorage.setItem(
                                'groupIdSelect',
                                result.userGroup[0].id,
                            )
                            groupId = result?.userGroup[0]?.id
                        } else {
                            groupId = localStorage.getItem('groupIdSelect')
                                ? parseInt(
                                      localStorage.getItem('groupIdSelect'),
                                  ) ||
                                  result?.ownGroup?.id ||
                                  result?.userGroup[0]?.id
                                : result?.userGroup?.length > 1
                                ? result.userGroup[1].id
                                : result?.userGroup?.length > 0
                                ? result.userGroup[0].id
                                : result?.ownGroup?.id
                        }
                        dispatch(groupActions.changeCurrentGroup(groupId))
                    }

                    return result
                },
                error => {
                    dispatch({
                        type: userConstants.UPDATE_FAILURE,
                        payload: { error },
                    })
                },
            )
        } else {
            dispatch({
                type: userConstants.UPDATE_FAILURE,
            })
        }
    }
}

const setupSubscriptions = (
    stripeToken,
    subscribeType,
    user,
    reductionCode,
) => {
    let body = { frontStripeToken: stripeToken }
    body = {
        ...body,
        subType: { id: subscribeType },
        subTypePricing: { id: subscribeType * 2 },
        user: user,
    }
    if (reductionCode) {
        body = {
            ...body,
            stripeId: reductionCode,
        }
    }
    return dispatch => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'button-payment-bob',
                subscriptionId: subscribeType,
            },
        })
        dispatch({ type: userConstants.SUBSCRIPTIONS_REQUEST })
        return requestApi('/subscriptions/user', 'POST', body).then(
            result => {
                dispatch({
                    type: userConstants.SUBSCRIPTIONS_SUCCESS,
                    subscriptions: result,
                })
                if (
                    localStorage.getItem('groupIdSelect') !== null &&
                    !isNaN(localStorage.getItem('groupIdSelect'))
                ) {
                    dispatch(
                        groupActions.changeCurrentGroup(
                            parseInt(localStorage.getItem('groupIdSelect')),
                        ),
                    )
                }
                history.push('/boaton-book')
                return result
            },
            async error => {
                dispatch({
                    type: userConstants.SUBSCRIPTIONS_FAILURE,
                    error,
                })
            },
        )
    }
}

function requestSubscriptions() {
    return dispatch => {
        dispatch({ type: userConstants.SUBSCRIPTIONS_REQUEST })
        return requestApi('/users/subscriptions', 'GET').then(
            result => {
                dispatch({
                    type: userConstants.SUBSCRIPTIONS_SUCCESS,
                    subscriptions: result,
                })
                return result
            },
            error => {
                dispatch({ type: userConstants.SUBSCRIPTIONS_FAILURE, error })
            },
        )
    }
}

function showClientPortail() {
    return requestApi('/users/customer-portal-session', 'GET').then(
        result => {
            return result.url
        },
        error => {
            console.error(error)
        },
    )
}

function login(email, password) {
    return dispatch => {
        dispatch({ type: userConstants.LOGIN_REQUEST })
        return userService.login(email, password).then(
            result => {
                dispatch({
                    type: userConstants.LOGIN_SUCCESS,
                    user: result,
                })
                dispatch(companiesActions.getUserCompany())
                if (
                    (result?.userGroup &&
                        Array.isArray(result.userGroup) &&
                        result.userGroup.length > 0) ||
                    result?.ownGroup?.id
                ) {
                    dispatch(
                        groupActions.changeCurrentGroup(
                            result?.ownGroup?.boats?.length === 0 &&
                                result?.userGroup.length > 0
                                ? result?.userGroup[0].id
                                : result?.ownGroup?.id,
                        ),
                    )
                }
            },
            error => {
                console.error('[user] login failed')
                const url = window.location.pathname
                const context = getContextFromUrl(url)
                alert(
                    context === 'en'
                        ? 'Incorrect email or password'
                        : 'Email ou mot de passe incorrect',
                )
                dispatch({ type: userConstants.LOGIN_FAILURE, error })
                dispatch(alertActions.error(error))
                return { success: false, user: null }
            },
        )
    }
}

function logout() {
    return dispatch => {
        userService.logout()
        dispatch({ type: userConstants.LOGOUT, user: null })
    }
}

function signin(body, language, resolve = () => {}, reject = () => {}) {
    body.platformType = { id: 1 }
    return dispatch => {
        dispatch({ type: userConstants.LOGIN_REQUEST, user: { body } })
        return userService.signin(body, language).then(
            async result => {
                if (result?.email && result.email !== '') {
                    await dispatch({
                        type: userConstants.LOGIN_SUCCESS,
                        user: result,
                    })
                    if (
                        (result?.userGroup &&
                            Array.isArray(result.userGroup) &&
                            result.userGroup.length > 0) ||
                        result?.ownGroup?.id
                    ) {
                        dispatch(
                            groupActions.changeCurrentGroup(
                                result?.ownGroup?.id || result.userGroup[0].id,
                            ),
                        )
                    }
                    resolve(result)
                } else {
                    await dispatch({ type: userConstants.LOGIN_FAILURE })
                    reject(result)
                }
                return result
            },
            async error => {
                alert(error)
                await dispatch({ type: userConstants.LOGIN_FAILURE, error })
                await dispatch(alertActions.error(error))
                reject(error)
            },
        )
    }
}

function forget(email, lang) {
    return dispatch => {
        userService.forget(email, lang).then(() => {
            if (lang === 'fr') {
                history.push(`/fr/connexion`)
            } else {
                history.push(`/en/login`)
            }
        })
    }
}

const udpatePassword = (
    lastPassword,
    newPassword,
    language,
    reject = () => {},
) => {
    return dispatch => {
        dispatch({
            type: userConstants.UPDATE_PASSWORD,
        })
        userService
            .updatePassword(lastPassword, newPassword, language)
            .then(async result => {
                if (result.id) {
                    await dispatch({
                        type: userConstants.PASSWORD_SUCCESS,
                        user: result,
                    })
                    alert(
                        language.toLowerCase() === 'en'
                            ? 'Updated password'
                            : 'Mot de passe mis à jour',
                    )
                } else {
                    alert(
                        language.toLowerCase() === 'en'
                            ? 'The previous password you typed is not correct'
                            : "L'ancien mot de passe n'est pas bon",
                    )
                }
            })
            .catch(async error => {
                reject(error)
                await dispatch({ type: userConstants.PASSWORD_FAILURE, error })
                alert(
                    language.toLowerCase() === 'en'
                        ? 'The previous password you typed is not correct'
                        : "L'ancien mot de passe n'est pas bon",
                )
            })
    }
}

function subscribe(UserID) {
    // to delete
}

function updateUser(user) {
    return dispatch => {
        const address =
            user.address && user.address.fullText
                ? {
                      fullAddress: user.address.fullText,
                      latitude: user.address.latitude.toString(),
                      longitude: user.address.longitude.toString(),
                  }
                : null

        let body = {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            birthday: user.birthday || null,
            contactDetails: user.contactDetails,
            description: user.description,
        }

        if (address) {
            body.address = address
        }

        dispatch({ type: userConstants.UPDATE_USER })
        return requestApi('/users', 'PUT', body).then(
            result => {
                if (result === 409) {
                    dispatch({ type: userConstants.UPDATE_USER_EMAIL_USED })
                    const url = window.location.pathname
                    const context = getContextFromUrl(url)
                    alert(
                        context === 'en'
                            ? 'This email is already used'
                            : "C'est email est déja existant sur un autre compte",
                    )
                } else {
                    dispatch({
                        type: userConstants.LOGIN_SUCCESS,
                        user: result,
                    })
                }
            },
            error => {
                console.error(error)
                dispatch({ type: userConstants.LOGIN_FAILURE, error })
            },
        )
    }
}

function updateStripeAccountId(accountCode) {
    return dispatch => {
        dispatch({ type: userConstants.STRIPE_ACCOUNT_ID_REQUEST })
        return requestApi(
            '/users/updateStripeAccountId/' + accountCode,
            'PUT',
            {},
        ).then(
            result => {
                dispatch({
                    type: userConstants.STRIPE_ACCOUNT_ID_SUCCESS,
                    user: result,
                })
            },
            error => {
                dispatch({
                    type: userConstants.STRIPE_ACCOUNT_ID_FAILURE,
                    error,
                })
            },
        )
    }
}

function getStripeLoginLink(accountCode) {
    return dispatch => {
        return requestApi(
            '/users/getStripeLoginLink/' + accountCode,
            'GET',
        ).then(
            result => {
                return result
            },
            error => {
                console.error(error)
            },
        )
    }
}

const checkSubscriptionPage = (subs, pageNumber) => {
    let max = -1
    let subscriptions = null
    if (subs === null || typeof subs[Symbol.iterator] !== 'function') subs = []
    for (let sub of subs) {
        if (sub.subType.id > max) {
            max = sub.subType.id
            subscriptions = sub
        }
    }
    //fixe temporaire et attendant que les utilisateurs changent d'abonnement
    if (pageNumber === 15 && subscriptions?.subTypePricing?.version?.id === 1) {
        return 'sub_V1'
    }

    const editedRightsOnPage = subscriptions?.editedRights?.find(
        right => right.pageType.id === pageNumber,
    )

    return (
        (!editedRightsOnPage &&
            subscriptions?.subType?.rightsPage &&
            subscriptions.subType.rightsPage.find(
                e => e.pageType.id === pageNumber,
            ) !== undefined) ||
        editedRightsOnPage?.access === true
    )
}

const checkSubscriptionBOB = subscriptions => {
    const sub =
        (subscriptions && Array.isArray(subscriptions) === false) ||
        (subscriptions &&
            Array.isArray(subscriptions) &&
            subscriptions.filter(
                sub =>
                    sub.subType &&
                    (sub.subType.id === 1 || sub.subType.id === 2),
            ).length > 0)

    if (sub) return true
    return false
}

const checkSubscriptionMultiBoat = subscriptions => {
    const sub =
        (subscriptions && Array.isArray(subscriptions) === false) ||
        (subscriptions &&
            Array.isArray(subscriptions) &&
            subscriptions.filter(sub => sub.subType && sub.subType.id > 2)
                .length > 0)
    if (sub) return true
    return false
}

function addMonths(date, months) {
    var d = date.getDate()
    date.setMonth(date.getMonth() + +months)
    if (date.getDate() !== d) {
        date.setDate(0)
    }
    return date
}

const userIsSubscribed = subscriptions => {
    var bool = false
    if (subscriptions && Array.isArray(subscriptions)) {
        subscriptions.forEach(userdetail => {
            if (userdetail.subType.id === 5) {
                if (
                    addMonths(new Date(userdetail.subscribtionDate), 1) >=
                        new Date() &&
                    new Date() >= new Date(userdetail.subscribtionDate)
                ) {
                    bool = true
                }
            } else if (userdetail.subType.id === 6) {
                if (
                    addMonths(new Date(userdetail.subscribtionDate), 12) >=
                        new Date() &&
                    new Date() >= new Date(userdetail.subscribtionDate)
                ) {
                    bool = true
                }
            }
        })
    }
    return bool
}

export const userActions = {
    init,
    login,
    logout,
    forget,
    signin,
    subscribe,
    requestSubscriptions,
    setupSubscriptions,
    updateUser,
    updateStripeAccountId,
    refreshToken,
    checkSubscriptionBOB,
    checkSubscriptionPage,
    checkSubscriptionMultiBoat,
    udpatePassword,
    userIsSubscribed,
    showClientPortail,
    getStripeLoginLink,
}
