export const groupConstants = {
    GET_GROUPS: `GET_USER_GROUPS`,
    GET_GROUPS_SUCCESS: `GET_USER_GROUPS_SUCCESS`,
    GET_GROUPS_FAILURE: `GET_USER_GROUPS_FAILURE`,

    GET_ROLES: `GET_USER_ROLES`,
    GET_ROLES_SUCCESS: `GET_USER_ROLES_SUCCESS`,
    GET_ROLES_FAILURE: `GET_USER_ROLES_FAILURE`,

    CREATE_GROUP: `CREATE_GROUP`,
    CREATE_GROUP_SUCCESS: `CREATE_GROUP_SUCCESS`,
    CREATE_GROUP_FAILURE: `CREATE_GROUP_FAILURE`,

    REMOVE_USER_GROUP: `REMOVE_USER_GROUP`,
    REMOVE_USER_GROUP_SUCCESS: `REMOVE_USER_GROUP_SUCCESS`,
    REMOVE_USER_GROUP_FAILURE: `REMOVE_USER_GROUP_FAILURE`,

    ADD_USER_GROUP: `ADD_USER_GROUP`,
    ADD_USER_GROUP_SUCCESS: `ADD_USER_GROUP_SUCCESS`,
    ADD_USER_GROUP_FAILURE: `ADD_USER_GROUP_FAILURE`,

    EDIT_USER_GROUP: `EDIT_USER_GROUP`,
    EDIT_USER_GROUP_SUCCESS: `EDIT_USER_GROUP_SUCCESS`,
    EDIT_USER_GROUP_FAILURE: `EDIT_USER_GROUP_FAILURE`,

    CHANGE_GROUP_ID: `CHANGE_GROUP_ID`,
    CHANGE_GROUP_ID_SUCCESS: `CHANGE_GROUP_ID_SUCCESS`,
    CHANGE_GROUP_ID_FAILURE: `CHANGE_GROUP_ID_FAILURE`,

    CHANGE_NAME: `CHANGE_NAME`,
    CHANGE_NAME_SUCCESS: `CHANGE_NAME_SUCCESS`,
    CHANGE_NAME_FAILURE: `CHANGE_NAME_FAILURE`,

    START_CHANGE_GROUP: `START_CHANGE_GROUP`,
    END_CHANGE_GROUP: `END_CHANGE_GROUP`,

    GET_FLEET_INFOS: `GET_FLEET_INFOS`,
    GET_FLEET_INFOS_SUCCESS: `GET_FLEET_INFOS_SUCCESS`,
    GET_FLEET_INFOS_FAILURE: `GET_FLEET_INFOS_FAILURE`,

    EDIT_USER_LINK: 'EDIT_USER_LINK',
    EDIT_USER_LINK_SUCCESS: 'EDIT_USER_LINK_SUCCESS',
    EDIT_USER_LINK_FAILURE: 'EDIT_USER_LINK_FAILURE',

    GET_LINKED_GROUP: 'GET_LINKED_GROUP',
    GET_LINKED_GROUP_SUCCESS: 'GET_LINKED_GROUP_SUCCESS',
    GET_LINKED_GROUP_FAILURE: 'GET_LINKED_GROUP_SUCCESS',
}
