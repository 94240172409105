export default {
    documents: {
        FR: 'Mes documents',
        EN: 'My documents',
    },
    youAddDocument: {
        FR: 'Ajouter un document',
        EN: 'Add a document',
    },
    editDocument: {
        FR: `Édition d'un document`,
        EN: 'Edit a document',
    },
    typeEv: {
        FR: `Choisissez un type d'évènement`,
        EN: `Choose an event type`,
    },
    choiceType: {
        FR: 'Choisir un type de document',
        EN: 'Choose a document type',
    },
    date: {
        FR: 'Date',
        EN: 'Date',
    },
    amount: {
        FR: 'Montant',
        EN: 'Amount',
    },
    title: {
        FR: 'Libellé du document',
        EN: 'Document title',
    },
    details: {
        FR: 'Commentaires',
        EN: 'Details',
    },
    addDocument: {
        FR: 'Télécharger le(s) document(s)',
        EN: 'Upload the document(s)',
    },
    validate: {
        FR: 'Je valide',
        EN: 'Validate',
    },
    ifNeeded: {
        FR:
            'Si besoin, vous pouvez ajouter une date et un montant associés au document. Ils se retrouveront enregistrés dans votre tableau de dépenses et revenus.',
        EN:
            'If necessary, you can add a date and an amount associated with the document. They will be saved in "My budget" page.',
    },
    validateDelete: {
        FR: 'Voulez-vous vraiment supprimer ce document ?',
        EN: 'Do you really want to delete this document ?',
    },
    search: {
        FR: 'Je recherche',
        EN: 'Search',
    },
    noDateSet: {
        FR: 'Pas de date',
        EN: 'No date',
    },
    errorChoiceType: {
        FR: `Vous devez choisir un type de document.`,
        EN: `You must choose a document type.`,
    },
    errorDate: {
        FR: `Vous devez choisir une date.`,
        EN: `You must choose a date.`,
    },
    errorDocument: {
        FR: `Vous devez ajouter au moins 1 fichier.`,
        EN: `You add at least 1 file.`,
    },
    errorTitle: {
        FR: `Vous devez définir un titre.`,
        EN: `You must define a title.`,
    },
    edit: {
        FR: `Modifier le document`,
        EN: `Modify the document`,
    },
    files: {
        FR: `Voir les fichiers joints`,
        EN: `See attached files`,
    },
    supprimerDoc: {
        FR: `Supprimer le document`,
        EN: `Delete the document`,
    },
    subscribesForAmount: {
        FR:
            "Pour gérer votre budget, vous devez souscrire à une offre d'abonnement",
        EN: 'To manage your budget, you must take out a subscription offer',
    },
    noBoat: {
        FR: `Vous n'avez pas enregistré de bateau`,
        EN: `You have not registered any boat`,
    },
    noDocument: {
        FR: `Vous n'avez aucun document`,
        EN: `You have no document`,
    },
    dateEmpty: {
        FR: 'jj/mm/aaaa',
        EN: 'mm/dd/yyyy',
    },
    activity: {
        FR: "Fil d'activité",
        EN: 'Activity feed',
    },
    essentialInfos: {
        FR: 'Informations essentielles',
        EN: 'Essential information',
    },

    amountEt: {
        FR: 'HT',
        EN: 'VAT excl.',
    },
    amountIt: {
        FR: 'TTC',
        EN: 'VAT incl.',
    },
    tax: {
        FR: 'TVA',
        EN: 'VAT',
    },
}
