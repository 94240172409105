export default {
    send: {
        FR: 'Envoyer',
        EN: 'Send',
    },
    generatingOrderTitle: {
        FR: 'Envoi bon de commande pour validation',
        EN: 'Sending order for validation',
    },
    responsible: {
        FR: 'Responsable',
        EN: 'Responsible',
    },
    operator: {
        FR: 'Fournisseur',
        EN: 'Operator',
    },
    helpManager: {
        FR: `Veuillez indiquer la personne en charge de la validation du bon de commande.`,
        EN: `Please indicate the person in charge of validating the order.`,
    },
    helpOperator: {
        FR: `Veuillez indiquer le fournisseur du bon de commande.`,
        EN: `Please indicate the operator of the order.`,
    },
    orderForm: {
        FR: 'Bon de commande',
        EN: 'Order form',
    },
    errorManager: {
        FR: 'Veuillez indiquer le responsable',
        EN: 'Please indicate the responsible',
    },
    cancel: {
        FR: 'Annuler la commande',
        EN: 'Cancel the order',
    },
    orderReceived: {
        FR: 'Commande reçue',
        EN: 'Order received',
    },
    quoteReceived: {
        FR: 'Devis reçu',
        EN: 'Quote received',
    },
    validQuote: {
        FR: 'Valider le devis',
        EN: 'Validate the quote',
    },
    valid: {
        FR: 'Valider le bon de commande',
        EN: 'Validate the order',
    },
    save: {
        FR: 'Sauvegarder',
        EN: 'Save',
    },
    delete: {
        FR: 'Supprimer',
        EN: 'Delete',
    },
    listOrderTitle: {
        FR: 'Liste des articles',
        EN: 'List of items',
    },
    informationsTitle: {
        FR: 'Informations',
        EN: 'Informations',
    },
    activityTitle: {
        FR: `Fil d'activité`,
        EN: 'Activity',
    },
    equipmentAndPart: {
        FR: 'Pièce & Equipment',
        EN: 'Equipment & part',
    },
    model: {
        FR: 'Modèle',
        EN: 'Model',
    },
    reference: {
        FR: 'Référence',
        EN: 'Reference',
    },
    quantity: {
        FR: 'Quantité',
        EN: 'Quantity',
    },
}
