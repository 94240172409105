import { requestApi } from '../../services/api.service'
import { boatConstants } from '../../constants/boat.constants'
import { userConstants, boatsConstants } from '../../constants'
import { groupActions } from '../group.actions'
import { userActions } from '../user.actions'

export const postBOBBoat = (boat, handleAlert = json => {}) => {}

export const removeBOBBoat = (boatId, { callback = json => {} }) => {
    return dispatch => {
        dispatch({ type: boatConstants.REQUEST_BOAT })
        return requestApi('/boats/' + boatId, 'DELETE').then(
            result => {
                dispatch({
                    type: boatConstants.BOAT_SUCCESS,
                    boat: result,
                })
                callback()
            },
            error => {
                dispatch({ type: boatConstants.BOAT_FAILURE, error })
            },
        )
    }
}

export const addFileToBoat = (
    props,
    boat,
    boatSelected,
    fileNoUrl,
    displayNewFiles,
) => {
    var arrayNoUrl = fileNoUrl.slice()
    var newArray = displayNewFiles.slice()
    for (let i = 0; i < boat.target.files.length; i++) {
        if (boat.target.files[i].size > 4000000) {
            alert('Votre fichier ne doit pas dépasser 4 MO')
        } else if (
            boat.target.files[i].type.split('/')[0] !== 'image' &&
            (boat.target.files[i].type.split('/')[0] !== 'application' ||
                boat.target.files[i].type.split('/')[1] !== 'pdf')
        ) {
            alert('Seuls les images ou les PDF sont acceptés')
        } else {
            newArray.push({
                blop: URL.createObjectURL(boat.target.files[i]),
                infos: boat.target.files[i],
            })

            arrayNoUrl.push(boat.target.files[i])
            props.setState({
                displayNewFiles: {
                    ...props.state.displayNewFiles,
                    boat: newArray,
                },
                fileNoUrl: { ...props.state.fileNoUrl, boat: arrayNoUrl },
            })
        }
    }
    props.props.dispatch(updatePictureBoat(boatSelected, arrayNoUrl))
}

export const postDataBoatPicture = async (
    files,
    filesType,
    handleUpload = json => {},
) => {
    var formData = new FormData()
    files.forEach((file, i) => {
        formData.append('files', file, file.name)
    })
    return await requestApi(
        '/files/uploadFile/fileType/' + filesType,
        'FILES',
        formData,
    ).then(
        result => {
            return result
        },
        error => {
            console.error('cannot upload file')
            return error
        },
    )
}

export const getBOBBoat = (boatId, handleAlert = json => {}) => {
    return dispatch => {
        dispatch({ type: boatConstants.REQUEST_BOAT })
        return requestApi('/boats/' + boatId, 'GET').then(
            result => {
                dispatch({
                    type: boatConstants.BOAT_SUCCESS,
                    boat: result,
                })
                return result
            },
            error => {
                localStorage.setItem('boatIdSelect', -1)
                dispatch({ type: boatConstants.BOAT_FAILURE, error })
            },
        )
    }
}

const _updateBoatUser = (result, user, groupId) => {
    const actualGroupId =
        groupId || parseInt(localStorage.getItem('groupIdSelect'))

    if (actualGroupId > 0) {
        if (
            user?.ownGroup?.id === actualGroupId &&
            user?.ownGroup?.boats &&
            Array.isArray(user.ownGroup.boats)
        ) {
            user.ownGroup.boats = user.ownGroup.boats.map(boat => {
                if (boat.id === result.id) {
                    return result
                }
                return boat
            })
        } else if (user?.userGroup && Array.isArray(user.userGroup)) {
            user.userGroup = user.userGroup.map(group => {
                if (group?.linkRGU[0]?.boats) {
                    group.linkRGU[0].boats = group.linkRGU[0].boats.map(
                        boat => {
                            if (boat.id === result.id) {
                                return result
                            }
                            return boat
                        },
                    )
                }
                return group
            })
        }
    }
    return user
}

export const updateBOBBoat = (boat, user, groupId, callback = json => {}) => {
    return dispatch => {
        dispatch({ type: boatConstants.UPDATE_BOAT })
        dispatch({
            type: userConstants.START_BOATS_UPDATE,
        })
        return editBoatInfo(boat, dispatch)
    }

    function editBoatInfo(boat, dispatch) {
        const postUrl = '/boats/' + boat.id
        const address =
            boat.address && boat.address.fullText
                ? {
                      fullAddress: boat.address.fullText,
                      latitude: boat.address.latitude.toString(),
                      longitude: boat.address.longitude.toString(),
                  }
                : null
        const body = {
            name: boat.name,
            maker: boat.maker,
            model: boat.model,
            year: parseInt(boat.year),
            description: boat.description,
            serialNumber: boat.serialNumber,
            boatType: {
                id:
                    boat.boatTypeId && boat.boatTypeId >= 0
                        ? parseInt(boat.boatTypeId)
                        : null,
            },
            mmsi: boat.mmsi,
            imo: boat.imo,
            landingStage: boat.landingStage,
            pontoon: boat.pontoon,
            ensign: boat.ensign,
        }

        if (address) {
            body.address = address
        }
        return requestApi(postUrl, 'PUT', body)
            .then(response => {
                dispatch({
                    type: boatConstants.BOAT_SUCCESS,
                    boat: response,
                })
                dispatch({
                    type: userConstants.BOATS_UPDATED,
                    user: _updateBoatUser(response, user, groupId),
                })
            })
            .catch(error => {
                dispatch({ type: boatConstants.BOAT_FAILURE, error: error })
                dispatch({
                    type: userConstants.BOATS_UPDATE_FAILED,
                })
            })
    }
}

export const updatePictureBoat = (boat, files) => {
    return async dispatch => {
        dispatch({ type: boatConstants.UPDATE_BOAT })
        const postUrl = '/boats/' + boat.id
        const body = {}
        if (files) {
            body.files = await postDataBoatPicture(files, 1)
        }
        return requestApi(postUrl, 'PUT', body)
            .then(response => {
                dispatch({
                    type: boatConstants.BOAT_SUCCESS,
                    boat: response,
                })
                dispatch(userActions.init())
            })
            .catch(error => {
                dispatch({ type: boatConstants.BOAT_FAILURE, error: error })
            })
    }
}

export const getBoatIdToShow = (user, groupId) => {
    const actualBoatId = localStorage.getItem('boatIdSelect')
    if (parseInt(actualBoatId) > 0) return actualBoatId
    else if (
        user?.ownGroup?.boats &&
        Array.isArray(user.ownGroup.boats) &&
        user.ownGroup.boats.length > 0 &&
        user.ownGroup.id === groupId
    ) {
        return user.ownGroup.boats[0].id
    } else if (
        user?.userGroup &&
        Array.isArray(user.userGroup) &&
        user.userGroup.length > 0 &&
        user.userGroup[0]?.id === groupId &&
        user.userGroup[0]?.linkRGU[0]?.boats &&
        Array.isArray(user.userGroup[0].linkRGU[0]?.boats) &&
        user.userGroup[0].linkRGU[0].boats.length > 0
    ) {
        return user.userGroup[0].linkRGU[0].boats[0].id
    }
    return null
}

export const selectAvailableBoat = (user, onlyMyFloat) => {
    return dispatch => {
        let boatId = null
        if (
            user?.ownGroup?.boats &&
            Array.isArray(user.ownGroup.boats) &&
            user.ownGroup.boats.length > 0
        ) {
            dispatch(groupActions.changeCurrentGroup(user.ownGroup.id))
            localStorage.setItem('boatIdSelect', user.ownGroup.boats[0].id)
            boatId = user.ownGroup.boats[0].id
        } else if (
            !onlyMyFloat &&
            user?.userGroup &&
            Array.isArray(user.userGroup) &&
            user.userGroup.length > 0
        ) {
            for (const group of user.userGroup) {
                if (
                    group.linkRGU[0]?.boats &&
                    Array.isArray(group.linkRGU[0].boats) &&
                    group.linkRGU[0]?.boats &&
                    Array.isArray(group.linkRGU[0]?.boats) &&
                    group.linkRGU[0].boats.length > 0
                ) {
                    dispatch(groupActions.changeCurrentGroup(group.id))
                    localStorage.setItem(
                        'boatIdSelect',
                        group.linkRGU[0].boats[0].id,
                    )
                    boatId = group.linkRGU[0].boats[0].id
                    break
                }
            }
        }
        return boatId
    }
}

export const boatIsInMyFloat = (boatId, user) => {
    if (
        user?.ownGroup?.boats &&
        Array.isArray(user.ownGroup.boats) &&
        user.ownGroup.boats.length > 0
    ) {
        if (user.ownGroup.boats.find(boat => boat.id === boatId)) return true
    }
    return false
}

export const getTotalNumberBoat = user => {
    let numberBoat = 0
    if (user?.ownGroup?.boats && Array.isArray(user.ownGroup.boats)) {
        numberBoat += user.ownGroup.boats.length
    }
    if (user?.userGroup && Array.isArray(user.userGroup)) {
        for (const group of user.userGroup) {
            if (
                group.linkRGU[0]?.boats &&
                Array.isArray(group.linkRGU[0].boats)
            ) {
                numberBoat += group.linkRGU[0].boats.length
            }
        }
    }

    return numberBoat
}

export const getBoatsOfAFleet = (user, groupId) => {
    let boats = []
    const actualGroupId =
        groupId || parseInt(localStorage.getItem('groupIdSelect'))
    if (actualGroupId > 0) {
        if (
            user?.ownGroup?.id === actualGroupId &&
            user?.ownGroup?.boats &&
            Array.isArray(user.ownGroup.boats)
        ) {
            boats = user.ownGroup.boats
        } else if (user?.userGroup && Array.isArray(user.userGroup)) {
            for (const group of user.userGroup) {
                if (
                    group.id === actualGroupId &&
                    group.linkRGU[0]?.boats &&
                    Array.isArray(group.linkRGU[0].boats)
                ) {
                    boats = group.linkRGU[0].boats
                }
            }
        }
    }
    return boats
}

export const updateBoatWithoutLoading = (boat, user, groupId) => {
    return dispatch => {
        return editBoat(boat, dispatch)
    }

    function editBoat(boat, dispatch) {
        const postUrl = '/boats/' + boat.id
        const address =
            boat.address && boat.address.fullText
                ? {
                      fullAddress: boat.address.fullText,
                      latitude: boat.address.latitude.toString(),
                      longitude: boat.address.longitude.toString(),
                  }
                : null
        const body = {
            name: boat.name,
            maker: boat.maker,
            model: boat.model,
            year: parseInt(boat.year),
            description: boat.description,
            serialNumber: boat.serialNumber,
            boatType: {
                id:
                    boat.boatTypeId && boat.boatTypeId >= 0
                        ? parseInt(boat.boatTypeId)
                        : null,
            },
            mmsi: boat.mmsi,
            imo: boat.imo,
            landingStage: boat.landingStage,
            pontoon: boat.pontoon,
            ensign: boat.ensign,
        }

        if (address) body.address = address

        return requestApi(postUrl, 'PUT', body)
            .then(response => {
                dispatch({
                    type: boatConstants.BOAT_SUCCESS_WITHOUT_LOADING,
                    boat: response,
                })
                dispatch({
                    type: boatsConstants.UPDATE_LINKED_BOAT_WITHOUT_LOADING,
                    boat: response,
                })
            })
            .catch(error => {
                console.error('Error on boat update:', error)
            })
    }
}
