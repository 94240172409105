import React from 'react'
import { connect } from 'react-redux'
import BoatOnModal from '../../../../../common/BoatOnModal'
import BoatOnModalCore from '../../../../../common/BoatOnModalCore'
import { editNavigation } from '../../../../../../actions'

import dictionary from './Dictionary/PassengersModalDico'

import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/PassengersModalCss'
import BoatOnNumberField from '../../../../../common/UsefullComponents/BoatOnNumberField'
import FillAddressAisModal from './FillAddressAisModal'
import { getLastAisData } from '../../../../../../actions/bob/navigationApiData.actions'

class PassengersModal extends BoatOnModalCore {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {
            inPassengers: 0,
            outPassengers: 0,
            remplacementAddress: null,
            selectedModal: false,
        }

        this.handleInPassengers = this.handleInPassengers.bind(this)
        this.handleOutPassengers = this.handleOutPassengers.bind(this)
        this.save = this.save.bind(this)
        this.getLastAisData = this.getLastAisData.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.setRemplacementAddress = this.setRemplacementAddress.bind(this)
    }

    componentDidMount() {
        const { dispatch, navigation, aisData } = this.props

        if (
            aisData.length === 0 &&
            navigation?.nbDepartingPassengers === null &&
            navigation?.isAisUsed === true &&
            navigation?.boat?.mmsi
        ) {
            dispatch(getLastAisData(navigation.boat.mmsi))
        }
    }

    closeModal() {
        this.setState({ selectedModal: false })
    }

    setRemplacementAddress(remplacementAddress) {
        this.setState(
            {
                remplacementAddress,
            },
            () => this.save(),
        )
    }

    handleInPassengers(e) {
        const value = e.target.value !== '' ? parseInt(e.target.value) : 0

        this.setState({ inPassengers: value })
    }

    handleOutPassengers(e) {
        const value = e.target.value !== '' ? parseInt(e.target.value) : 0

        this.setState({ outPassengers: value })
    }

    getLastAisData() {
        const { aisData, lastAis } = this.props
        const { remplacementAddress } = this.state

        let lastAisData =
            (aisData || []).length > 0
                ? aisData.sort(
                      (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
                  )[aisData.length - 1]
                : lastAis

        if (!lastAisData)
            return remplacementAddress ? remplacementAddress : null

        const currentDate = new Date()
        const treshold = 1 * 60 + 20
        const oldCurrentDate = new Date(
            currentDate.getTime() - treshold * 60 * 1000,
        )

        if (new Date(lastAisData.createdAt) < oldCurrentDate)
            return remplacementAddress ? remplacementAddress : null

        return lastAisData
    }

    save() {
        const { inPassengers, outPassengers } = this.state
        const { onClose } = this.props
        const navigation = JSON.parse(JSON.stringify(this.props.navigation))
        const isStarted =
            navigation.delimitedDate.startDate &&
            navigation.nbDepartingPassengers !== null

        if (!isStarted) {
            navigation.nbDepartingPassengers = inPassengers
            if (!navigation.delimitedDate.startDate)
                navigation.delimitedDate.startDate = new Date()

            if (navigation.isAisUsed) {
                const lastAisData = this.getLastAisData()

                navigation.delimitedDate.startDate = new Date()

                if (!lastAisData) {
                    this.setState({ selectedModal: 'replacementAddressModal' })
                    return
                }

                if (navigation.departureAddress) {
                    navigation.departureAddress.latitude = lastAisData.latitude
                    navigation.departureAddress.longitude =
                        lastAisData.longitude
                } else {
                    navigation.departureAddress = {
                        latitude: lastAisData.latitude,
                        longitude: lastAisData.longitude,
                    }
                }

                this.props.dispatch(editNavigation(navigation))
                onClose()
                return
            } else {
                this.props.dispatch(editNavigation(navigation))
                onClose()
                return
            }
        }

        const currentStopoverIndex = navigation.stopOvers.findIndex(
            stop => stop.delimitedDate.startDate && !stop.delimitedDate.endDate,
        )

        navigation.stopOvers[
            currentStopoverIndex
        ].nbBoardingPassengers = inPassengers
        navigation.stopOvers[
            currentStopoverIndex
        ].nbLeavingPassengers = outPassengers
        navigation.stopOvers[
            currentStopoverIndex
        ].delimitedDate.endDate = new Date()

        this.props.dispatch(editNavigation(navigation))
        onClose()
    }

    _renderBody() {
        const { classes, navigation } = this.props
        const { inPassengers, outPassengers, selectedModal } = this.state
        const isStarted =
            navigation.delimitedDate.startDate &&
            navigation.nbDepartingPassengers !== null

        const modals = (
            <BoatOnModal
                openCondition={selectedModal}
                handleClose={this.closeModal}
                modalCores={{
                    replacementAddressModal: (
                        <FillAddressAisModal
                            onClose={this.closeModal}
                            onValid={this.setRemplacementAddress}
                            noCross={false}
                        />
                    ),
                }}
                maxWidth={{ replacementAddressModal: 'sm' }}
                titles={{
                    replacementAddressModal: this.displayText(
                        'aisWithoutAddress',
                    ),
                }}
            />
        )

        if (!isStarted)
            return (
                <div className={classes.root}>
                    <div className={classes.message}>
                        {this.displayText('startAlert')}
                    </div>
                    <BoatOnNumberField
                        id="inPassengers"
                        className={classes.inputPassengers}
                        label={this.displayText('passengersNumber')}
                        required
                        variant="outlined"
                        min={0}
                        value={inPassengers}
                        fullWidth={true}
                        margin="dense"
                        onChange={this.handleInPassengers}
                    />
                    {modals}
                </div>
            )

        return (
            <div className={classes.root}>
                <div className={classes.message}>
                    {this.displayText('endStopoverAlert')}
                </div>

                <BoatOnNumberField
                    id="outPassengers"
                    label={this.displayText('passengersNumberOut')}
                    required
                    variant="outlined"
                    min={0}
                    value={outPassengers}
                    fullWidth={true}
                    margin="dense"
                    onChange={this.handleOutPassengers}
                />

                <BoatOnNumberField
                    id="inPassengers"
                    label={this.displayText('passengersNumberIn')}
                    required
                    variant="outlined"
                    min={0}
                    value={inPassengers}
                    fullWidth={true}
                    margin="dense"
                    onChange={this.handleInPassengers}
                />
                {modals}
            </div>
        )
    }

    render() {
        const {
            title,
            noCross = true,
            showFollowButton,
            aisLoading,
        } = this.props

        return (
            <>
                {this._renderTitle(
                    title,
                    noCross,
                    null,
                    undefined,
                    showFollowButton,
                )}
                {this._renderBody({
                    body: this._renderBody(),
                })}
                {this._renderActions([
                    {
                        action: this.save,
                        label: this.displayText('save'),
                        disabled: aisLoading > 0,
                    },
                ])}
            </>
        )
    }
}

function mapStateToProps(state) {
    const url = window.location.pathname
    const id = url.split('/').slice(-1)[0]
    const navigation = state.logbook.navigations.find(
        nav => nav.id === parseInt(id),
    )

    const currentAis =
        (state.navigationApiData.ais || []).filter(
            ais => ais.navigationId === navigation.id,
        )[0] || null

    return {
        navigation,
        lastAis: state.navigationApiData.lastAis,
        aisData: currentAis?.aisData || [],
        aisLoading: state.navigationApiData.loading,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(PassengersModal))
