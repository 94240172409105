import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import BoatOnComponent from '../common/BoatOnComponent'

import SaveBar from '../common/SaveBar'

import styles from './Styles/ProfileTabCss'
import dictionary from './Dictionary/ProfileTabDico'
import AddressSearch from '../RentParkingPage/RentNavigationPages/AddressSearch'
import { userActions } from '../../actions/user.actions'
import { isValidPhoneNumber } from 'react-phone-number-input'
import PhoneField from '../common/PhoneField'
import BoatOnModal from '../common/BoatOnModal'
import PasswordModal from '../common/UsefullComponents/ModalConnection/ModalPassword'

class ProfileTab extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            userEdit: null,
            passwordModal: false,
        }

        this.save = this.save.bind(this)
        this.setAddress = this.setAddress.bind(this)
        this.openModalPassword = this.openModalPassword.bind(this)
        this.closeModalPassword = this.closeModalPassword.bind(this)
    }

    componentDidMount() {
        const { user } = this.props
        if (user) {
            this.setState({
                userEdit: JSON.parse(JSON.stringify(user)),
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { user } = this.props
        const { userEdit } = this.state
        if (!userEdit && user) {
            this.setState({
                userEdit: JSON.parse(JSON.stringify(user)),
            })
        }
    }

    setAddress(address) {
        this.setState(prevState => ({
            userEdit: {
                ...prevState.userEdit,
                address: address,
            },
        }))
    }

    handleChange(value, key) {
        this.setState(prevState => ({
            userEdit: {
                ...prevState.userEdit,
                [key]: value,
            },
        }))
    }

    handleChangeNumber(value) {
        const { userEdit } = this.state
        let index = userEdit.indexPhoneNumber
        if (index === null) {
            this.setState(prevState => ({
                userEdit: {
                    ...prevState.userEdit,
                    contactDetails: [
                        ...prevState.userEdit.contactDetails,
                        { contact: value, contactType: { id: 1 } },
                    ],
                },
            }))
        } else {
            let newContactDetails = userEdit.contactDetails
            newContactDetails[index] = {
                ...newContactDetails[index],
                contact: value,
            }
            this.setState(prevState => ({
                userEdit: {
                    ...prevState.userEdit,
                    contactDetails: newContactDetails,
                },
            }))
        }
    }

    openModalPassword() {
        this.setState({ passwordModal: true })
    }

    closeModalPassword() {
        this.setState({ passwordModal: false })
    }

    onChange(value, key) {
        this.setState({ [key]: value })
    }

    save() {
        const { userEdit } = this.state
        if (userEdit.email === '') {
            alert(this.displayText('emptyMail'))
        } else if (userEdit.firstName === '') {
            alert(this.displayText('emptyFirstName'))
        } else if (userEdit.lastName === '') {
            alert(this.displayText('emptyLastName'))
        } else if (
            userEdit.indexPhoneNumber === null ||
            userEdit.contactDetails[userEdit.indexPhoneNumber].contact === ''
        ) {
            alert(this.displayText('emptyPhone'))
        } else {
            this.props.dispatch(userActions.updateUser(userEdit))
            return true
        }
        return false
    }

    render() {
        const { classes, user } = this.props
        const { userEdit } = this.state

        if (!userEdit) {
            return <div>loading</div>
        }
        return (
            <form className={classes.root}>
                <div className={classes.textAndField}>
                    <TextField
                        label={this.displayText('firstName')}
                        id="firstName"
                        margin="dense"
                        required
                        variant="outlined"
                        placeholder="Paul"
                        value={userEdit.firstName}
                        onChange={e => {
                            this.handleChange(e.target.value, 'firstName')
                        }}
                        className={classes.field}
                    />
                </div>
                <div className={classes.textAndField}>
                    <TextField
                        label={this.displayText('lastName')}
                        id="lastName"
                        margin="dense"
                        required
                        variant="outlined"
                        placeholder="BoatOn"
                        value={userEdit.lastName}
                        onChange={e => {
                            this.handleChange(e.target.value, 'lastName')
                        }}
                        className={classes.field}
                    />
                </div>
                <div className={classes.textAndField}>
                    <TextField
                        label={this.displayText('email')}
                        id="email"
                        margin="dense"
                        type="email"
                        required
                        variant="outlined"
                        placeholder="paul@boaton.fr"
                        value={userEdit.email}
                        onChange={e => {
                            this.handleChange(e.target.value, 'email')
                        }}
                        className={classes.field}
                    />
                </div>
                <div className={classes.textAndField}>
                    <PhoneField
                        value={
                            userEdit.indexPhoneNumber !== null &&
                            userEdit.contactDetails &&
                            userEdit.contactDetails[userEdit.indexPhoneNumber]
                                .contact
                                ? userEdit.contactDetails[
                                      userEdit.indexPhoneNumber
                                  ].contact
                                : ''
                        }
                        className={classes.fieldPhone}
                        placeholder="+33 6 06 06 06 06"
                        margin="dense"
                        onChange={value => {
                            this.handleChangeNumber(value, 'contactDetails')
                        }}
                        label={this.displayText('phoneNumber')}
                        error={
                            !isValidPhoneNumber(
                                userEdit.indexPhoneNumber !== null &&
                                    userEdit.contactDetails &&
                                    userEdit.contactDetails[
                                        userEdit.indexPhoneNumber
                                    ].contact
                                    ? userEdit.contactDetails[
                                          userEdit.indexPhoneNumber
                                      ].contact
                                    : '',
                            )
                        }
                    />
                    {/*<TextField
                        id="phone"
                        margin="dense"
                        type="number"
                        required
                        variant="outlined"
                        placeholder="0600000000"
                        value={
                            userEdit.indexPhoneNumber !== null
                                ? userEdit.contactDetails[
                                      userEdit.indexPhoneNumber
                                  ].contact
                                : null
                        }
                        onChange={e => {
                            this.handleChangeNumber(
                                e.target.value,
                                'contactDetails',
                            )
                        }}
                        className={classes.field}
                    />*/}
                </div>
                {/* <div className={ classes.textAndField }>
          <Typography className={ classes.text }>{ this.displayText('address') }: </Typography>
          <TextField
            id="address"
            margin="dense"
            required
            variant="outlined"
            placeholder={ this.displayText('address') }
            value={ user.FullAddress }
            onChange={ e => {
              this.handleChange(e.target.value, 'FullAddress')
            } }
            className={ classes.field }
          />
        </div> */}
                <div className={classes.textAndField}>
                    <div className={classes.field}>
                        <AddressSearch
                            label={this.displayText('address')}
                            style={{ width: '100%' }}
                            setAddress={this.setAddress}
                            parkingAddress={
                                userEdit.address ? userEdit.address : null
                            }
                            fullAddressInfo={
                                userEdit.address ? userEdit.address : null
                            }
                        ></AddressSearch>
                    </div>
                </div>
                <div className={classes.textAndField}>
                    <TextField
                        label={this.displayText('description')}
                        id="description"
                        margin="dense"
                        variant="outlined"
                        placeholder={this.displayText('presentationSentence')}
                        value={userEdit.description ?? ``}
                        onChange={e => {
                            this.handleChange(e.target.value, 'description')
                        }}
                        className={classes.field}
                    />
                </div>
                <div className={classes.textAndField}>
                    <TextField
                        label={this.displayText('birthday')}
                        id="dateBirth"
                        margin="dense"
                        type="date"
                        required
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        value={userEdit.birthday ?? ``}
                        onChange={e => {
                            this.handleChange(e.target.value, 'birthday')
                        }}
                        className={classes.field}
                    />
                </div>
                <div className={classes.textAndField}>
                    <Typography
                        className={classes.password}
                        onClick={this.openModalPassword}
                    >
                        {this.displayText('forgetPassword')}
                    </Typography>
                </div>
                <SaveBar
                    save={{ userEdit }}
                    new={{ userEdit }}
                    onCancel={save => {
                        this.setState({
                            userEdit: JSON.parse(JSON.stringify(user)),
                        })
                    }}
                    onSave={this.save}
                />
                <BoatOnModal
                    openCondition={
                        this.state.passwordModal ? 'password' : false
                    }
                    handleClose={this.closeModalPassword}
                    modalCores={{
                        password: (
                            <PasswordModal
                                closeModal={this.closeModalPassword}
                            ></PasswordModal>
                        ),
                    }}
                    titles={{
                        password: this.displayText('forgetPassword'),
                    }}
                    maxWidth={{ password: `xs` }}
                />
            </form>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ProfileTab))
