export default theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        boxSizing: `border-box`,
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            marginBottom: '25px',
        },
    },
    containerModal: {
        height: `fit-content`,
        padding: 20,
        gap: 20,
        textAlign: `center`,
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
        flexDirection: `column`,
        '& span': {
            '&:hover': {
                cursor: `pointer`,
            },
        },
    },
    textAndField: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            gap: 16,

            '&.smVertical': {
                flexDirection: 'column',
                gap: 'unset',
            },
        },
    },
    st: {
        verticalAlign: 'super',
        fontSize: '70%',
    },
    text: {
        width: '25%',
        margin: 'auto',
        textAlign: 'right',
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            width: '100%',
        },
    },
    field: {
        width: '100%',
        margin: '8px',
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            margin: '8px 0',
        },
    },
    popuptext: {
        textAlign: 'center',
        paddingTop: '10px',
        paddingBottom: '10px',
        zIndex: '1',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
        whiteSpace: 'pre-line',
    },
    texts: {
        width: '90%',
        margin: 'auto',
        marginTop: '13vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '10px',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            width: 'calc(100% - 10px)',
        },
        backgroundColor: '#fff',
        border: '5px solid #3040a0',
        boxShadow: '0px 1px 20px 0.1px #000',
        borderRadius: 5,
    },
    all: {
        backgroundColor: 'hsla(231, 9%, 55%, 0.4)',
        zIndex: '11000001',
        width: '100%',
        height: '100%',
        marginBottom: '5px',
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#fff',
        },
    },
    changeButton: {
        width: '50%',
        margin: '20px',
    },
    firstPart: {
        display: 'flex',
        flexDirection: 'row',
        width: '50%',
        margin: '8px',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            width: '100%',
            margin: '8px 0',
        },
    },
    secondPart: {
        display: 'flex',
        flexDirection: 'row',
        width: '50%',
        margin: '8px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            margin: '8px 0',
            width: '100%',
        },
    },
    lineWithMultiField: {
        width: '100%',
        margin: 'auto',
        fontSize: '16px',
    },
    lineWithMultiTextLeft: {
        width: '50%',
        margin: 'auto',
        textAlign: 'right',
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            width: '100%',
        },
    },
    lineWithMultiTextRight: {
        width: '50%',
        margin: 'auto',
        textAlign: 'right',
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            width: '100%',
        },
    },
    lineWithMultiTextCenter: {
        width: '50%',
        marginTop: '2%',
        marginBottom: '2%',
        marginLeft: '30%',
        textAlign: 'center',
        fontSize: '20px',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            width: '100%',
        },
    },
    infos: Object.assign(
        {
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: '15px',
        },
        {
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                margin: 0,
            },
            boxSizing: `border-box`,
            maxWidth: '100%',
        },
    ),
    newBoatButton: {
        color: 'black',
        '&:hover': {
            backgroundColor: '#3040a0',
            color: 'white',
        },
    },
    title: {
        marginLeft: '10px',
        fontWeight: '500',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
        },
    },
    boat: {
        width: 'calc(100% - 10px)',
        margin: '5px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: 0,
        },
    },
    headerBoat: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 10,

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            gap: 24,
        },
    },
    titleAndButton: Object.assign(
        {
            alignItems: 'center',
            marginRight: 'auto',
            width: '100%',
        },
        theme.flex.rowAlignCenter,
    ),
    buttonAddCenter: Object.assign(
        { alignItems: 'center', margin: '10px', marginTop: '15px' },
        theme.flex.rowSpacebetween,
    ),
    buttonAdd: {
        width: 'max-content',
        padding: '5px 32px',
        float: 'right',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            float: 'center',
            fontSize: '0.8rem',
        },
        boxShadow: '0px 8px 16px 0px rgba(48, 63, 159, 0.24)',
        borderRadius: 8,
    },
    bottom: {
        width: '100%',
        padding: '0 35px',
        boxSizing: 'border-box',
        [theme.breakpoints.down('sm')]: {
            margin: 0,
        },
    },
    searchAddress: {
        margin: '8px',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            margin: '8px 0',
        },
    },
    buttonSvg: {
        fontSize: '30px!important',
    },
    delete: {
        color: theme.palette.declined.main,
        cursor: 'pointer',
    },
    root2: {
        width: `100%`,
        height: `80vh`,
        display: `flex`,
        justifyContent: `center`,
        alignItems: `center`,
        flexDirection: `column`,
    },
    backgroundImage: {
        maxWidth: 300,
        objectFit: `contain`,
    },
    noBoatContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    noBoatText: {
        maxWidth: 300,
        color: `#303F9F`,
        fontSize: 22,
        fontWeight: `bold`,
        textAlign: `center`,
        margin: 0,
        '&:hover': {
            cursor: `pointer`,
        },
    },
    content: {
        boxSizing: `border-box`,
        [theme.breakpoints.down('sm')]: {
            margin: 0,
        },
    },
    divider: {
        margin: '10px 15px',
    },

    headerFillingLevel: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 10,
    },
    fillingLevelContainer: {
        margin: 10,
        gap: 10,
        display: 'grid',
        gridTemplateAreas: `
            "stock-title stock-edit-btn"
            "stock-container stock-container"
        `,
        [theme.breakpoints.down('sm')]: {
            gridTemplateAreas: `
                "stock-title"
                "stock-container"
                "stock-edit-btn"
            `,
        },
    },
    updateButton: {
        color: theme.palette.primary.main,
        justifySelf: 'end',
        [theme.breakpoints.down('sm')]: {
            justifySelf: 'start',
        },
    },
    stockContainer: {
        gridArea: 'stock-container',
        display: 'grid',
        columnGap: 10,
        rowGap: 16,
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
        '& > .case:nth-child(5n+2)::before': {
            content: `''`,
            position: 'absolute',
            top: '10%',
            left: -5,
            height: '90%',
            borderLeft: '1px solid #EBEBEB',
        },
        '& > .case:nth-child(5n+3)::before': {
            content: `''`,
            position: 'absolute',
            top: '10%',
            left: -5,
            height: '90%',
            borderLeft: '1px solid #EBEBEB',
        },
        '& > .case:nth-child(5n+4)::before': {
            content: `''`,
            position: 'absolute',
            top: '10%',
            left: -5,
            height: '90%',
            borderLeft: '1px solid #EBEBEB',
        },
        '& > .case:nth-child(5n+5)::before': {
            content: `''`,
            position: 'absolute',
            top: '10%',
            left: -5,
            height: '90%',
            borderLeft: '1px solid #EBEBEB',
        },
        '& > .case:nth-child(5n+5)::after': {
            content: `''`,
            position: 'absolute',
            top: 'calc(100% + 8px)',
            left: 'calc(-1 * (400% + 40px))',
            width: 'calc(500% + 40px)',
            borderTop: '1px solid #EBEBEB',
        },

        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr 1fr 1fr',
            '& > .case:nth-child(5n+2)::before': {
                display: 'none',
            },
            '& > .case:nth-child(5n+3)::before': {
                display: 'none',
            },
            '& > .case:nth-child(5n+4)::before': {
                display: 'none',
            },
            '& > .case:nth-child(5n+5)::before': {
                display: 'none',
            },
            '& > .case:nth-child(5n+5)::after': {
                display: 'none',
            },

            '& > .case:nth-child(3n)::before': {
                display: 'unset',
                content: `''`,
                position: 'absolute',
                top: '10%',
                left: -5,
                height: '90%',
                borderLeft: '1px solid #EBEBEB',
            },
            '& > .case:nth-child(3n-1)::before': {
                display: 'unset',
                content: `''`,
                position: 'absolute',
                top: '10%',
                left: -5,
                height: '90%',
                borderLeft: '1px solid #EBEBEB',
            },
            '& > .case:nth-child(3n+4)::after': {
                display: 'unset',
                content: `''`,
                position: 'absolute',
                top: '-8px',
                left: 0,
                width: 'calc(300% + 20px)',
                borderTop: '1px solid #EBEBEB',
            },
        },
    },
    stockItemContainer: {
        position: 'relative',
    },
    stockItemTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 5,
        fontSize: 12,
        fontWeight: 400,
        color: '#7B7D7E',
        paddingLeft: 10,
    },
    stockIcon: {
        height: 20,
        width: 20,
    },
    stockItemLevels: {
        height: 50,
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
        padding: 10,
    },
    stockItemGraph: {
        height: 50,
        width: 50,
    },
    stockItemValues: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    stockPercent: {
        fontSize: 20,
    },
    redColor: {
        color: '#E40000',
    },
    stockCapacity: {
        fontSize: 12,
    },

    WorkingHoursContainer: {
        margin: 10,
        gap: 10,
        display: 'grid',
        gridTemplateAreas: `
            "worktime-title worktime-edit-btn"
            "worktime-container worktime-container"
        `,
        [theme.breakpoints.down('sm')]: {
            gridTemplateAreas: `
                "worktime-title"
                "worktime-container"
                "worktime-edit-btn"
            `,
        },
    },
    worktimeContainer: {
        gridArea: 'worktime-container',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        rowGap: 16,
        columnGap: 10,
        '& > .case:nth-child(4n+2)::before': {
            content: `''`,
            position: 'absolute',
            top: '10%',
            left: -5,
            height: '90%',
            borderLeft: '1px solid #EBEBEB',
        },
        '& > .case:nth-child(4n+3)::before': {
            content: `''`,
            position: 'absolute',
            top: '10%',
            left: -5,
            height: '90%',
            borderLeft: '1px solid #EBEBEB',
        },
        '& > .case:nth-child(4n+4)::before': {
            content: `''`,
            position: 'absolute',
            top: '10%',
            left: -5,
            height: '90%',
            borderLeft: '1px solid #EBEBEB',
        },
        '& > .case:nth-child(4n+4)::after': {
            content: `''`,
            position: 'absolute',
            top: 'calc(100% + 8px)',
            left: 'calc(-1 * (300% + 40px))',
            width: 'calc(400% + 40px)',
            borderTop: '1px solid #EBEBEB',
        },
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr 1fr',
            '& > .case:nth-child(2n+2)::before': {
                content: `''`,
                position: 'absolute',
                top: '10%',
                left: -5,
                height: '90%',
                borderLeft: '1px solid #EBEBEB',
            },
            '& > .case:nth-child(2n+2)::after': {
                content: `''`,
                position: 'absolute',
                top: 'calc(100% + 8px)',
                left: 'calc(-1 * (100% + 10px))',
                width: 'calc(200% + 10px)',
                borderTop: '1px solid #EBEBEB',
            },
            '& > .case:nth-child(4n+3)::before': {
                display: 'none',
            },
        },
    },
    worktimeItemContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    worktimeIcon: {
        height: 24,
        width: 24,
        margin: 10,
    },
    worktimeDataContainer: {
        flex: 1,
        paddingTop: 10,
        paddingBottom: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    worktimeItemTitle: {
        fontSize: 12,
        fontWeight: 400,
        color: '#7B7D7E',
    },
    worktimeItemValue: {
        fontSize: 20,
    },

    leftField: {
        display: 'flex',
        flexDirection: 'row',
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            gap: 16,
        },
    },
    rightField: {
        display: 'flex',
        flexDirection: 'row',
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            gap: 16,
        },
    },
    autoCompleteInput: {
        marginTop: 0,
        marginBottom: 0,
        [theme.breakpoints.down('sm')]: {
            '& > div': {
                padding: '3px !important',
            },
        },
    },
})
