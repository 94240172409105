import React from 'react'
import { connect } from 'react-redux'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto'
import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/MyDocumentListCss'
import {
    updateStateEvent,
    getImageToDisplay,
    putBOBEvents,
    postBOBEvents,
    addFileToBobEvent,
    deleteFileFromBobEvent,
} from '../../../../actions/bob.actions'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import BoatOnModalCore from '../../../common/BoatOnModalCore'
import DisplayerImgModal from '../../DisplayerImgModal'
import InputAdornment from '@material-ui/core/InputAdornment'
import { typesActions } from '../../../../actions/types.actions'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import dictionary from './Dictionary/MyDocumentListDico'
import { Tooltip } from '@material-ui/core'
import HelpIcon from '@material-ui/core/SvgIcon/SvgIcon'
import BoatOnModal from '../../../common/BoatOnModal'
import ModalAmountItCheck from '../../../common/UsefullComponents/ModalAmountItCheck'
import BoatOnDateSelector from '../../../common/UsefullComponents/BoatOnDateSelector'
import { activityActions } from '../../../../actions/activity.actions'
import { commentsActions } from '../../../../actions/bob/comments.actions'
import BoatOnNumberField from '../../../common/UsefullComponents/BoatOnNumberField'

class DocumentModal extends BoatOnModalCore {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            event: props.event || null,
            fileNoUrl: { event: [] },
            displayNewFiles: { event: [] },
            openDoc: false,
            deletedFiles: { event: [] },
            checkModalOpen: null,
            error: false,
            tabIndex: 0,
            loadingImage: false,
            tax: 20,
        }

        this.save = this.save.bind(this)
        this.clickDocument = this.clickDocument.bind(this)
        this.addDocument = this.addDocument.bind(this)
        this.deleteDocumentNoUp = this.deleteDocumentNoUp.bind(this)
        this.isConfirmedToggler = this.isConfirmedToggler.bind(this)
        this.handleCloseCheckModal = this.handleCloseCheckModal.bind(this)
    }

    componentDidMount() {
        // Lancement du chargement de l'activité lorsque la modale est montée
        if (this.state?.event?.id) {
            this.props.dispatch(
                activityActions.getBobEventActivity(this.state?.event?.id),
            )
            this.props.dispatch(
                commentsActions.getBobEventComments(this.state?.event?.id),
            )
        }

        if (this.state.event?.transaction) {
            const transaction = this.state.event.transaction

            if (transaction?.amountIt && transaction?.amountEt) {
                const diff = transaction?.amountIt - transaction?.amountEt
                const tax = (diff / transaction?.amountEt) * 100

                this.setState({ tax })
            }
        }
    }

    clickDocument() {
        this.refs.fileUploader.click()
    }

    // CheckModal
    isConfirmedToggler() {
        this.setState({ isConfirmed: true })
    }

    handleCloseCheckModal() {
        this.setState({ checkModalOpen: null })
    }

    deleteDocumentNoUp(i) {
        deleteFileFromBobEvent(this, i, {
            deletedFiles: this.state.deletedFiles.event,
            displayNewFiles: this.state.displayNewFiles.event,
            fileNoUrl: this.state.fileNoUrl.event,
            files: this.state.event.files,
        })
    }

    addDocument(event) {
        if (
            event?.target?.files &&
            [...event.target.files].find(file =>
                file.name.toLowerCase().includes('.heic'),
            )
        ) {
            // So we display a loading icon during the time of heic conversion
            this.setState({ loadingImage: true }, async () => {
                await addFileToBobEvent(this, event, {
                    fileNoUrl: this.state.fileNoUrl.event,
                    displayNewFiles: this.state.displayNewFiles.event,
                })
                this.setState({ loadingImage: false })
            })
        } else {
            addFileToBobEvent(this, event, {
                fileNoUrl: this.state.fileNoUrl.event,
                displayNewFiles: this.state.displayNewFiles.event,
            })
        }
    }

    onChangeAmount(type, value) {
        const { event, tax } = this.state

        value = value.toString().replace(',', '.')
        const transaction =
            type === 'amountIt'
                ? {
                      amountIt: value,
                      amountEt: parseFloat(value / (1 + tax / 100)).toFixed(2),
                  }
                : {
                      amountIt: parseFloat(value * (1 + tax / 100)).toFixed(2),
                      amountEt: value,
                  }

        updateStateEvent(this, {
            transaction,
            negativeTransaction: event?.eventType?.id === 1,
        })
    }

    onChangeTax(value) {
        const { event } = this.state

        this.setState(
            {
                tax: value,
            },
            () =>
                this.onChangeAmount(
                    'amountEt',
                    event?.transaction?.amountEt ?? '',
                ),
        )
    }

    _renderAmount() {
        const { classes, eventTypes } = this.props
        const { event, tax } = this.state

        const eventType = typesActions.getEventTypeById(
            eventTypes,
            this.state.event && this.state.event.eventType.id,
        )

        return (
            <div className={classes.amountContainer}>
                <BoatOnNumberField
                    isDecimal
                    id="amountEt"
                    variant="outlined"
                    label={
                        <>
                            {this.displayText('amountEt')}
                            {this.props.subscriptions ? (
                                <Tooltip
                                    title={this.displayText(
                                        'subscribesForAmount',
                                    )}
                                    classes={{
                                        tooltip: classes.tooltip,
                                    }}
                                >
                                    <HelpIcon className={classes.helpIcon} />
                                </Tooltip>
                            ) : null}
                        </>
                    }
                    margin="dense"
                    value={event?.transaction?.amountEt ?? ''}
                    onChange={e =>
                        this.onChangeAmount('amountEt', e.target.value)
                    }
                    className={classes.textField}
                    InputProps={{
                        classes: {
                            input: classes.input,
                            marginDense: classes.marginInput,
                        },
                        startAdornment: (
                            <InputAdornment position="start">
                                {eventType?.spending !== eventType?.earning ? (
                                    eventType.spending ? (
                                        <RemoveIcon fontSize="small" />
                                    ) : (
                                        <AddIcon fontSize="small" />
                                    )
                                ) : null}
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">€</InputAdornment>
                        ),
                    }}
                    InputLabelProps={{
                        classes: { root: classes.labelInput },
                    }}
                />
                <BoatOnNumberField
                    isDecimal
                    variant="outlined"
                    label={this.displayText('tax')}
                    margin="dense"
                    value={tax}
                    min={0}
                    onChange={e => this.onChangeTax(e.target.value)}
                    className={classes.taxField}
                    InputProps={{
                        classes: {
                            input: classes.input,
                            marginDense: classes.marginInput,
                        },
                        endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                        ),
                    }}
                    InputLabelProps={{
                        classes: { root: classes.labelInput },
                    }}
                />
                <BoatOnNumberField
                    isDecimal
                    id="amountIt"
                    variant="outlined"
                    label={
                        <>
                            {this.displayText('amountIt')}
                            {this.props.subscriptions ? (
                                <Tooltip
                                    title={this.displayText(
                                        'subscribesForAmount',
                                    )}
                                    classes={{
                                        tooltip: classes.tooltip,
                                    }}
                                >
                                    <HelpIcon className={classes.helpIcon} />
                                </Tooltip>
                            ) : null}
                        </>
                    }
                    margin="dense"
                    value={event?.transaction?.amountIt ?? ''}
                    onChange={e =>
                        this.onChangeAmount('amountIt', e.target.value)
                    }
                    className={classes.textField}
                    InputProps={{
                        classes: {
                            input: classes.input,
                            marginDense: classes.marginInput,
                        },
                        startAdornment: (
                            <InputAdornment position="start">
                                {eventType?.spending !== eventType?.earning ? (
                                    eventType.spending ? (
                                        <RemoveIcon fontSize="small" />
                                    ) : (
                                        <AddIcon fontSize="small" />
                                    )
                                ) : null}
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">€</InputAdornment>
                        ),
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.labelInput,
                        },
                    }}
                />
            </div>
        )
    }

    _body() {
        const { classes, eventTypes } = this.props

        const eventType = typesActions.getEventTypeById(
            eventTypes,
            this.state.event && this.state.event.eventType.id,
        )

        return (
            <>
                <TextField
                    id="type"
                    variant="outlined"
                    label={this.displayText('choiceType')}
                    margin="dense"
                    required
                    select
                    error={
                        !(
                            this.state.event &&
                            this.state.event.eventType &&
                            this.state.event.eventType.id
                        ) && this.state.error
                    }
                    helperText={
                        !(
                            this.state.event &&
                            this.state.event.eventType &&
                            this.state.event.eventType.id
                        ) && this.state.error
                            ? this.displayText('errorChoiceType')
                            : ''
                    }
                    value={
                        this.state.event &&
                        this.state.event.eventType &&
                        this.state.event.eventType.id
                            ? this.state.event.eventType.id
                            : -1
                    }
                    onChange={e => {
                        updateStateEvent(this, { eventTypeId: e.target.value })
                    }}
                    className={classes.textField}
                    InputProps={{
                        classes: {
                            input: classes.input,
                            marginDense: classes.marginInput,
                        },
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.labelInput,
                        },
                    }}
                >
                    {!(
                        this.state.event &&
                        this.state.event.eventType &&
                        this.state.event.eventType.id
                    ) && (
                        <MenuItem disabled value={-1}>
                            <em> {this.displayText(`typeEv`)} </em>
                        </MenuItem>
                    )}
                    {eventTypes.map(type => {
                        return (
                            <MenuItem key={type.id} value={type.id}>
                                {this.displayTextApi(type.translation)}
                            </MenuItem>
                        )
                    })}
                </TextField>
                <Typography variant={`body1`} style={{ margin: `10px 0px` }}>
                    {this.displayText('ifNeeded')}
                </Typography>
                <div
                    className={`${classes.twoTextField} ${classes.verticalMobile}`}
                >
                    <BoatOnDateSelector
                        id={'date'}
                        required
                        disabled={this.state.event.delimitedDate === null}
                        error={
                            this.state.error &&
                            !this.state.event?.delimitedDate?.startDate
                        }
                        helperText={
                            this.state.error &&
                            !this.state.event?.delimitedDate?.startDate
                                ? this.displayText('errorDate')
                                : ''
                        }
                        label={this.displayText('date')}
                        onChange={value => {
                            updateStateEvent(this, {
                                delimitedDate: {
                                    startDate: value,
                                },
                            })
                        }}
                        value={
                            this.state.event?.delimitedDate?.startDate
                                ? new Date(
                                      this.state.event.delimitedDate.startDate,
                                  )
                                : null
                        }
                    />
                    {(!this.state.event ||
                        !this.state.event.eventType ||
                        !this.state.event.eventType.id ||
                        (eventType &&
                            (eventType.spending || eventType.earning))) &&
                        this._renderAmount()}
                </div>
                {(!this.state?.event?.id ||
                    (this.state?.event?.id &&
                        !this.state.event?.detail &&
                        !this.state.event?.checkup)) && (
                    <TextField
                        id="title"
                        variant="outlined"
                        label={this.displayText('title')}
                        margin="dense"
                        required
                        error={
                            !(this.state.event && this.state.event.title) &&
                            this.state.error
                        }
                        helperText={
                            !(this.state.event && this.state.event.title) &&
                            this.state.error
                                ? this.displayText('errorTitle')
                                : ''
                        }
                        value={
                            this.state.event && this.state.event.title
                                ? this.state.event.title
                                : ''
                        }
                        onChange={e => {
                            updateStateEvent(this, {
                                title: e.target.value,
                            })
                        }}
                        className={classes.textField}
                        InputProps={{
                            classes: {
                                input: classes.input,
                                marginDense: classes.marginInput,
                            },
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.labelInput,
                            },
                        }}
                    />
                )}
                <TextField
                    id="details"
                    variant="outlined"
                    label={this.displayText('details')}
                    margin="dense"
                    multiline
                    rows={3}
                    value={
                        (this.state.event && this.state.event.description) || ''
                    }
                    onChange={e => {
                        updateStateEvent(this, {
                            description: e.target.value,
                        })
                    }}
                    className={classes.textField}
                    InputProps={{
                        classes: {
                            input: classes.input,
                            marginDense: classes.marginInput,
                        },
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.labelInput,
                        },
                    }}
                />
                <Typography className={classes.textAdd}>
                    {this.displayText('addDocument')}
                </Typography>
                <div
                    className={
                        !(
                            (this.state.fileNoUrl.event.length > 0 ||
                                (this.state.event &&
                                    this.state.event.files &&
                                    this.state.event.files.length > 0)) &&
                            this.state.event.files.length +
                                this.state.fileNoUrl.event.length >=
                                0
                        ) && this.state.error
                            ? classes.addDocError
                            : classes.addDoc
                    }
                    onClick={this.clickDocument}
                >
                    <AddAPhotoIcon
                        className={
                            !(
                                (this.state.fileNoUrl.event.length > 0 ||
                                    (this.state.event &&
                                        this.state.event.files &&
                                        this.state.event.files.length > 0)) &&
                                this.state.event.files.length +
                                    this.state.fileNoUrl.event.length >=
                                    0
                            ) && this.state.error
                                ? classes.docError
                                : classes.doc
                        }
                    />
                </div>
                {!(
                    (this.state.fileNoUrl.event.length > 0 ||
                        (this.state.event &&
                            this.state.event.files &&
                            this.state.event.files.length > 0)) &&
                    this.state.event.files.length +
                        this.state.fileNoUrl.event.length >=
                        0
                ) &&
                    this.state.error && (
                        <Typography className={classes.errorDocument}>
                            {this.displayText('errorDocument')}
                        </Typography>
                    )}
                <input
                    onChange={this.addDocument}
                    type="file"
                    id="file"
                    ref="fileUploader"
                    style={{ display: 'none' }}
                    multiple
                    required
                />
                <DisplayerImgModal
                    file={getImageToDisplay(
                        this.state.event,
                        this.state.displayNewFiles.event,
                    )}
                    deleteDocumentNoUp={this.deleteDocumentNoUp}
                    loadingImage={this.state.loadingImage}
                />
            </>
        )
    }

    async save() {
        const { deletedFiles, fileNoUrl, event, isConfirmed } = this.state
        const {
            delimitedDate,
            title,
            description,
            transaction,
            eventType,
        } = event
        const { boat } = this.props

        if (
            (fileNoUrl.event.length > 0 || event.files.length > 0) &&
            event.files.length + fileNoUrl.event.length >= 0 &&
            title &&
            title !== '' &&
            eventType &&
            eventType.id &&
            delimitedDate &&
            delimitedDate.startDate &&
            ((event.transaction === null
                ? true
                : parseFloat(event.transaction.amountIt) !== 0) ||
                isConfirmed)
        ) {
            if (event.id) {
                this.props.dispatch(
                    putBOBEvents(event.id, title, {
                        newFiles: fileNoUrl.event,
                        files: event.files,
                        delimitedDate: delimitedDate,
                        transaction: transaction,
                        description: description,
                        eventTypeId: eventType.id,
                        deletedFiles: deletedFiles.event,
                    }),
                )
            } else {
                this.props.dispatch(
                    postBOBEvents(boat.id, eventType.id, title, {
                        files: fileNoUrl.event,
                        duplicateFiles: event.files,
                        delimitedDate: delimitedDate,
                        transaction: transaction,
                        description: description,
                    }),
                )
            }
            this.props.handleClose()
        } else if (
            (fileNoUrl.event.length > 0 || event.files.length > 0) &&
            event.files.length + fileNoUrl.event.length >= 0 &&
            title &&
            title !== '' &&
            eventType &&
            eventType.id &&
            delimitedDate &&
            delimitedDate.startDate &&
            (parseFloat(event.transaction.amountIt) === 0 || !isConfirmed)
        ) {
            // show ModalAmountItCheck
            this.setState({ checkModalOpen: 'inventoryCheck' })
        } else {
            this.setState({ error: true })
        }
    }

    render() {
        const {
            title,
            event,
            activity,
            noCross = true,
            showFollowButton,
        } = this.props
        const { checkModalOpen, tabIndex } = this.state

        return (
            <>
                {this._renderTitle(
                    title,
                    noCross,
                    [
                        this.displayText('essentialInfos'),
                        event?.id ? this.displayText('activity') : undefined,
                    ],
                    undefined,
                    showFollowButton,
                )}
                {this._renderBody({
                    bodies: [
                        this._body(),
                        event?.id
                            ? this._renderActivity(activity, event, 'document')
                            : undefined,
                    ],
                })}
                {/* Si on est en mode édition et qu'on est sur le fil d'activité, alors pas de boutons */}
                {this._renderActions(
                    event?.id && tabIndex === 1
                        ? []
                        : [
                              {
                                  label: this.displayText('validate'),
                                  action: this.save,
                              },
                          ],
                )}
                <BoatOnModal
                    openCondition={checkModalOpen}
                    handleClose={this.handleCloseCheckModal}
                    maxWidth={{ inventoryCheck: `sm` }}
                    modalCores={{
                        inventoryCheck: (
                            <ModalAmountItCheck
                                isConfirmedToggler={this.isConfirmedToggler}
                                save={this.save}
                                handleCloseCheckModal={
                                    this.handleCloseCheckModal
                                }
                            />
                        ),
                    }}
                    titles={{
                        inventoryCheck: `Confirmer`,
                    }}
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        boat: state.bob.boat,
        subscriptions: state.authentication.subscriptions,
        activity: state.activity.activity,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(DocumentModal))
